import { OwcTextarea } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";

const DescriptionInput = ({ application, updateApplication, setDirtyInputs, dirtyInputs, validationInputs }) => {
  return (
    <>
      <OwcTextarea
        cols={18}
        rows={4}
        label="Description"
        value={application.description}
        maxLength="300"
        onReset={() => updateApplication({ value: "", key: "description" })}
        onInputChange={(event) => updateApplication({ value: event.target.value, key: "description" })}
        onBlur={() =>
          setDirtyInputs({
            ...dirtyInputs,
            description: true
          })
        }
        error={!validationInputs.description && dirtyInputs.description}
        reset
        id={generateID.UUID(Module.PAGE.ADMIN.descriptionInput, `desc`, "textarea")}
      />

      {/* <OwcInput
        data-testid="application-edit-form-description-input"
        label="Description"
        value={application.description}
        maxLength="300"
        style={{ width: "100%" }}
        onInputChange={(event) =>
          updateApplication({ value: event.target.value, key: "description" })
        }
        onBlur={() =>
          setDirtyInputs({
            ...dirtyInputs,
            description: true
          })
        }
        error={!validationInputs.description && dirtyInputs.description}
      >
        <span slot="error-text" id={generateID.UUID(Module.PAGE.ADMIN.descriptionInput, `desc`, "span_error")}>
          {!validationInputs.entryPoint_url && dirtyInputs.entryPoint_url}
          {!validationInputs.description &&
            dirtyInputs.description &&
            "Application description can only contain alphanumeric characters, space, new line, characters like _ - : and max 6 line"}
        </span>
        {application?.description?.length > 0 && (
          <OwcIcon
            type="filled"
            slot="suffix"
            name="circle_clear"
            data-testid="application-edit-form-clear-button-description-button"
            onClick={() => updateApplication({ value: "", key: "description" })}
            style={{ color: "var(--one-interaction-default-neutral-3)" }}
            id={generateID.UUID(Module.PAGE.ADMIN.descriptionInput, `desc`, "icon")}
          />
        )}
      </OwcInput> */}
    </>
  );
};
export default DescriptionInput;
