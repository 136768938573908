import { Input, InputLabel, MenuItem, Select } from "@mui/material";
import { OwcChip } from "@one/react";
import React, { useRef } from "react";

const CustomGroupModalDialogContentFormControlForEntryPoints = ({
  application,
  getAppWithEntryPoints,
  updateAllEntryPointsSelection,
  deleteEntryPointSelection
}) => {
  const chipElement = useRef(null);
  // const index = e.detail[0];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <InputLabel data-testid="custom-group-modal-inputLabel-entrypoints">{application.name} launch steps</InputLabel>
      <Select
        data-testid="custom-group-modal-select-entrypoints"
        multiple
        value={getAppWithEntryPoints(application).entryPoints}
        onChange={(event) => {
          updateAllEntryPointsSelection(application, event.target.value);
        }}
        input={<Input variant="filled" />}
        renderValue={(selected) => (
          <div style={{ display: "flex" }}>
            {selected.map((entryPointId) => (
              <OwcChip
                ref={chipElement}
                data-testid="custom-group-modal-select-entrypoints-chip"
                key={entryPointId}
                variant="outlined"
                onRemove={() => {
                  deleteEntryPointSelection(application, entryPointId);
                }}
                removable
                round
              >
                {application.entryPoints.find((entryPoint) => entryPoint.id === entryPointId)?.name}
              </OwcChip>
            ))}
          </div>
        )}
      >
        {application.entryPoints.map((entryPoint) => (
          <MenuItem
            data-testid={"custom-group-modal-select-menu-item-" + entryPoint.id}
            key={entryPoint.id}
            value={entryPoint.id}
          >
            {entryPoint.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
export default CustomGroupModalDialogContentFormControlForEntryPoints;
