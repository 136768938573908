import ReactDOM from "react-dom";
import { isEqual, format } from "date-fns";
import { useEffect, useMemo, useState, useCallback, useRef } from "react";
import { withApollo } from "react-apollo";
import styled from "styled-components";
import {
  listNotificationFieldDateFilter,
  listNotificationFieldSetFilter,
  listNotificationFieldTextFilter,
  Module,
  NOTIFICATION_DATA_MODEL
} from "../../../constants";
import { NotificationContext } from "./NotificationContext";
import DLabGrid from "../../../components/DLabGrid/DLabGrid";
import { deleteNotification } from "../deleteNotification";
import {
  OwcDatepicker,
  OwcGrid,
  OwcIcon,
  OwcIconButton,
  OwcInput,
  OwcTypography,
  OwcListItem,
  OwcMenu
} from "@one/react";
import "./FullViewOfNotificationPage.scss";
import { changeDateFormat } from "../../../utils/helpers/text";
import Tooltip from "@mui/material/Tooltip";
import { FullScreenCentered } from "../../landingPageUser/LandingPageUser";
import { CircularProgress } from "@mui/material";

import { generateID } from "@digitallab/grid-common-components";

const { notificationName } = Module.PAGE.NOTIFICATION;
const FullNotifyBox = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
`;
export const MoreThan1000Info = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--one-color-gray-800);
`;

const NotificationAction = ({ params, client, gridRef }) => {
  const [menuVisibility, setMenuVisibility] = useState(false);
  return (
    <div style={{ justifyContent: "end", display: "flex" }}>
      <OwcIconButton
        style={{ marginRight: "15px" }}
        id={params?.data?.id}
        icon="more_vertical"
        type="legacy"
        flat
        onClick={(event) => {
          setMenuVisibility(true);
        }}
      />

      {ReactDOM.createPortal(
        <>
          <OwcMenu
            onClickOutside={(e) => {
              setMenuVisibility(false);
            }}
            anchor={params?.data?.id}
            visible={menuVisibility}
            alignment="left"
            disablePortal
            id={generateID.UUID(notificationName, `notificationaction`, "menu")}
          >
            <OwcListItem
              type="legacy"
              icon-type="outlined"
              icon="delete"
              data-testid="simple-menu-filter"
              onClick={async () => {
                try {
                  await deleteNotification({
                    id: params?.data?.id,
                    client
                  });
                  const selectedData = gridRef.current.api.getSelectedRows();
                  gridRef.current.api.applyTransaction({
                    remove: selectedData
                  });
                  setMenuVisibility(false);
                } catch (e) {}
              }}
              id={generateID.UUID(notificationName, `notificationaction`, "list_item_delete")}
              noBorder
            >
              Delete
            </OwcListItem>

            <OwcListItem
              type="legacy"
              icon="launch"
              data-testid="simple-menu-filter"
              onClick={async () => {
                window.open(params?.data?.link);
              }}
              disabled={!params?.data?.link}
              noBorder
              id={generateID.UUID(notificationName, `notificationaction`, "list_item_goto")}
            >
              Go to URL
            </OwcListItem>
          </OwcMenu>
        </>,
        document.body
      )}
    </div>
  );
};

const FullViewOfNotificationPage = ({ client, id, notifications, loading, unsorted }) => {
  // AG-Grid functions
  const [gridApi, setGridApi] = useState(null);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(notifications?.length);
  const [isFFilterVisible, setIsFFilterVisible] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [value, setValue] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [actionValue, setActionValue] = useState("Delete notification");
  const gridRef = useRef();
  useEffect(() => {
    if (gridApi) {
      onGridReady(gridApi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowPerPage]);

  const onGridReady = (params) => {
    setGridApi(params);
  };
  const onFirstDataRendered = (params) => {
    setIsFFilterVisible(false);
  };
  const filterParams = {
    inRangeInclusive: true,
    filterOptions: ["inRange"],
    inRangeFloatingFilterDateFormat: "MM-DD-YYYY",
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      let cellDate = changeDateFormat(cellValue, "ISOToDateDefaultTime");
      if (isEqual(cellDate, filterLocalDateAtMidnight)) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    }
  };

  const actionRender = (params) => {
    return (
      <>
        <NotificationAction params={params} client={client} gridRef={gridRef} />
      </>
    );
  };
  const dLabColumnDef = useMemo(() => {
    let columnFields = [
      {
        headerCheckboxSelection: true,
        headerCheckboxSelectionCurrentPageOnly: true,
        checkboxSelection: true,
        maxWidth: 50
      }
    ];
    let columnData = NOTIFICATION_DATA_MODEL;
    for (const key in columnData) {
      let defaultObj = {
        field: columnData[key].key,
        headerName: columnData[key].value,
        sortable: true
      };

      if (listNotificationFieldTextFilter.includes(columnData[key].key)) {
        defaultObj = {
          ...defaultObj,
          filter: "agTextColumnFilter"
        };
      }
      if (listNotificationFieldSetFilter.includes(columnData[key].key)) {
        defaultObj = {
          ...defaultObj,
          filter: "agSetColumnFilter",
          cellRenderer: (params) => {
            return <span className={`severity ${params.value}`}>{params.value}</span>;
          }
        };
      }
      if (listNotificationFieldDateFilter.includes(columnData[key].key)) {
        defaultObj = {
          ...defaultObj,
          filter: "agDateColumnFilter",
          filterParams,
          valueFormatter: (params) => {
            return format(new Date(params.value), "MM/dd/yyyy hh:mm a");
          }
        };
      }
      if (columnData[key].key === "details" || columnData[key].key === "message" || columnData[key].key === "source") {
        defaultObj = {
          ...defaultObj,
          cellRenderer: (params) => {
            const renderTooltip = () => {
              if (params.value.includes("\n")) {
                const newLine = params.value.split("\n");

                return newLine.map((line, index) => (
                  <div key={index}>
                    {line}
                    <br />
                  </div>
                ));
              }

              return params.value;
            };
            return (
              <Tooltip title={renderTooltip()} id={generateID.UUID(notificationName, `action_render`, "tooltip_title")}>
                <span id={generateID.UUID(notificationName, `action_render`, "tooltip_span")}>{params.value}</span>
              </Tooltip>
            );
          }
        };
      }
      columnFields.push(defaultObj);
    }

    const actions = {
      field: "actions",
      headerName: "Actions",
      maxWidth: 100,
      filter: false,
      cellClass: "action-render",
      cellRenderer: actionRender
    };

    columnFields.push(actions);

    return columnFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      floatingFilter: isFFilterVisible,
      suppressMenu: true
    };
  }, [isFFilterVisible]);

  const onFilterChanged = (params) => {
    setTotalRows(params?.api?.paginationGetRowCount());
  };

  const onRowDataUpdated = (params) => {
    setTotalRows(params?.api?.paginationGetRowCount());
  };

  const onRowSelected = (params) => {
    const rowLength = params?.api?.getSelectedRows();
    setActionValue(() => {
      if (rowLength && rowLength?.length > 1) {
        return `Delete notifications (${rowLength?.length})`;
      } else if (rowLength && rowLength?.length === 1) {
        return `Delete notification (${rowLength?.length})`;
      }
      return `Delete notification`;
    });
    setSelectedRows(() => rowLength);
  };

  let attr = {
    height: 503,
    rowQuickSearch: false,
    floatingFilter: false,
    suppressPaginationPanel: true,
    paginationPageSize: rowPerPage,
    columnDefs: dLabColumnDef,
    pagination: true,
    animateRows: true,
    rowSelection: "multiple",
    rowExport: false,
    suppressContextMenu: true,
    defaultToolPanel: "filters",
    hiddenByDefault: true,
    rowData: notifications,
    onRowSelected,
    onFilterChanged,
    onRowDataUpdated,
    onGridReady,
    setRowPerPage,
    rowPerPage,
    totalRows
  };
  // getSelectedRows
  const handleDelete = useCallback(() => {
    const deleteSelectedRows = async () => {
      try {
        const result = await Promise.all(
          selectedRows.map(
            async (selectedRow) =>
              await deleteNotification({
                client,
                id: selectedRow.id,
                all: true
              })
          )
        );
        if (result) {
          gridRef.current.api.applyTransaction({
            remove: selectedRows
          });
        }
      } catch (e) {
        console.log(e);
      }
    };
    deleteSelectedRows();
  }, [client, selectedRows]);

  const agGridOptions = {
    actions: [
      {
        key: "delete_notification",
        value: actionValue,
        disabled: selectedRows?.length === 0,
        onClick: () => {
          handleDelete();
        }
      }
    ]
  };

  const onFilterTextBoxChanged = useCallback(() => {
    console.log(value);
    setValue(document.getElementById("filter-text-box").value);
    gridRef.current.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }, [value]);

  const getDateString = (dateObj) => {
    const covertedDate = changeDateFormat(dateObj, "ISO");
    const date = covertedDate?.split("T");
    return date[0];
  };

  const handleFromDateChange = useCallback(
    (evDetail) => {
      const currentFilterModel = gridRef?.current?.api?.getFilterModel();
      if (evDetail) {
        const date = getDateString(evDetail);

        currentFilterModel.time = {
          dateFrom: `${date} 00:00:00`,
          dateTo: !toDate ? `${date} 00:00:00` : `${getDateString(toDate)} 00:00:00`,
          filterType: "date",
          type: "inRange"
        };

        setFromDate(() => evDetail);
        if (!toDate) {
          setToDate(() => evDetail);
        }
        gridRef?.current?.api?.setFilterModel(currentFilterModel);
      } else {
        if (currentFilterModel?.time) {
          delete currentFilterModel?.time;
        }
        gridRef?.current?.api?.setFilterModel(currentFilterModel);
        setFromDate(() => evDetail);
      }
    },
    [toDate]
  );
  const handleToDateChange = useCallback(
    (evDetail) => {
      const currentFilterModel = gridRef?.current?.api?.getFilterModel();
      if (evDetail) {
        const date = getDateString(evDetail);

        currentFilterModel.time = {
          dateFrom: !fromDate ? `${date} 00:00:00` : `${getDateString(fromDate)} 00:00:00`,
          dateTo: `${date} 00:00:00`,
          filterType: "date",
          type: "inRange"
        };

        setToDate(() => evDetail);
        if (!fromDate) {
          setFromDate(() => evDetail);
        }
        gridRef?.current?.api?.setFilterModel(currentFilterModel);
      } else {
        if (currentFilterModel?.time) {
          delete currentFilterModel?.time;
        }
        gridRef?.current?.api?.setFilterModel(currentFilterModel);
        setToDate(() => evDetail);
      }
    },
    [fromDate]
  );

  if (loading) {
    return (
      <FullScreenCentered data-testid="loader">
        <CircularProgress size={80} />
      </FullScreenCentered>
    );
  }

  return (
    <FullNotifyBox data-testid="full-notification-box" id={generateID.UUID(notificationName, `fullnotify`, "box")}>
      <OwcGrid
        style={{ paddingLeft: "16px" }}
        container
        columns="2"
        id={generateID.UUID(notificationName, `fullnotify`, "grid_style")}
      >
        <OwcGrid item xs={1} id={generateID.UUID(notificationName, `fullnotify`, "grid")}>
          <OwcTypography variant="button" id={generateID.UUID(notificationName, `fullnotify`, "type_notification")}>
            Notifications
          </OwcTypography>
        </OwcGrid>
        <OwcGrid
          style={{ justifyContent: "end" }}
          item
          xs={1}
          id={generateID.UUID(notificationName, `fullnotify`, "grid_1")}
        >
          <OwcIconButton
            flat
            type="legacy"
            icon="more_vertical"
            id={generateID.UUID(notificationName, `fullnotify`, "icon_button")}
          ></OwcIconButton>
        </OwcGrid>
      </OwcGrid>
      <NotificationContext.Provider value={{ notifications }}>
        <OwcGrid
          container
          columns="7"
          v-gutter="3"
          h-gutter="5"
          style={{ paddingLeft: "16px", marginBottom: "10px" }}
          id={generateID.UUID(notificationName, `fullnotify`, "grid_container")}
        >
          <OwcGrid item xs={1} s={1} id={generateID.UUID(notificationName, `fullnotify`, "grid_item")}>
            <OwcInput
              autoFocus
              style={{ width: "100%" }}
              placeholder="Search"
              id="filter-text-box"
              type="text"
              value={value}
              variant="filled"
              onInputChange={(ev) => onFilterTextBoxChanged(ev.detail)}
            >
              <div slot="prefix" id={generateID.UUID(notificationName, `fullnotify`, "div_prefix")}>
                <OwcIcon name="search" id={generateID.UUID(notificationName, `fullnotify`, "icon_search")} />
              </div>
              <div
                slot="suffix"
                style={{
                  color: "var(--one-color-interaction-default-neutral-3)"
                }}
                id={generateID.UUID(notificationName, `fullnotify`, "div_suffix")}
              >
                {value && (
                  <OwcIcon
                    name="circle_clear_filled"
                    onClick={() => setValue("")}
                    style={{ cursor: "pointer" }}
                    id={generateID.UUID(notificationName, `fullnotify`, "icon_circle_clear")}
                  />
                )}
              </div>
            </OwcInput>
          </OwcGrid>
          {isFFilterVisible && (
            <>
              <OwcGrid item xs={1} s={1} id={generateID.UUID(notificationName, `fullnotify`, "grid_2")}>
                <OwcDatepicker
                  autoClose
                  style={{ width: "100%" }}
                  format="dd/MM/yyyy"
                  hasClearIcon
                  label="From"
                  placeholder="From"
                  value={fromDate}
                  type="single"
                  variant="filled"
                  weekStartsOn="1"
                  onValueChange={(ev, param) => {
                    handleFromDateChange(ev?.target?.value ? ev?.target?.value[0] : null);
                  }}
                  id={generateID.UUID(notificationName, `fullnotify`, "datepicker")}
                ></OwcDatepicker>
              </OwcGrid>
              <OwcGrid item xs={1} s={1} id={generateID.UUID(notificationName, `fullnotify`, "grid_3")}>
                <OwcDatepicker
                  autoClose
                  style={{ width: "100%" }}
                  format="dd/MM/yyyy"
                  hasClearIcon
                  label="To"
                  placeholder="To"
                  value={toDate}
                  type="single"
                  variant="filled"
                  weekStartsOn="1"
                  onValueChange={(ev) => {
                    handleToDateChange(ev?.target?.value ? ev?.target?.value[0] : null);
                  }}
                  id={generateID.UUID(notificationName, `fullnotify`, "datepicker_2")}
                ></OwcDatepicker>
              </OwcGrid>
            </>
          )}
          <OwcGrid item xs={1} s={1} id={generateID.UUID(notificationName, `fullnotify`, "grid_4")}>
            <OwcIconButton
              className={isFFilterVisible ? "filterSelected" : ""}
              type="outlined"
              onClick={() => setIsFFilterVisible(!isFFilterVisible)}
              flat
              icon="filter"
              id={generateID.UUID(notificationName, `fullnotify`, "icon_button")}
            />
          </OwcGrid>
        </OwcGrid>

        <DLabGrid
          {...attr}
          defaultColDef={defaultColDef}
          gridRef={gridRef}
          onFirstDataRendered={onFirstDataRendered}
          options={agGridOptions}
        />
      </NotificationContext.Provider>
    </FullNotifyBox>
  );
};

export default withApollo(FullViewOfNotificationPage);
