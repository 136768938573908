import React from "react";
import CustomGroupModalDialogContentInputsRow from "./CustomGroupModalDialogContentInputsRow";
import CustomGroupModalDialogContentAppListToChoose from "./CustomGroupModalDialogContentAppListToChoose";
import { ApplicationList, AppRow } from "./customGroupModalDialogContentStyleds";

const CustomGroupModalDialogContent = ({
  isMobile,
  customGroupForm,
  dirty,
  hasError,
  updateCustomGroupNameAndSetDirty,
  isNameUnique,
  isNameValid,
  searchText,
  searchApps,
  filterdApp,
  updateAppSelection,
  isAppSelected,
  classes,
  getAppWithEntryPoints,
  updateAllEntryPointsSelection,
  deleteEntryPointSelection
}) => {
  return (
    <>
      <ApplicationList>
        <CustomGroupModalDialogContentInputsRow
          isMobile={isMobile}
          customGroupForm={customGroupForm}
          dirty={dirty}
          hasError={hasError}
          updateCustomGroupNameAndSetDirty={updateCustomGroupNameAndSetDirty}
          isNameUnique={isNameUnique}
          isNameValid={isNameValid}
          searchText={searchText}
          searchApps={searchApps}
        />
        {filterdApp.length > 0 ? (
          <CustomGroupModalDialogContentAppListToChoose
            filterdApp={filterdApp}
            updateAppSelection={updateAppSelection}
            isAppSelected={isAppSelected}
            classes={classes}
            getAppWithEntryPoints={getAppWithEntryPoints}
            updateAllEntryPointsSelection={updateAllEntryPointsSelection}
            deleteEntryPointSelection={deleteEntryPointSelection}
          />
        ) : (
          <AppRow>
            <p data-testid="custom-group-modal-no-applications-found">No applications found</p>
          </AppRow>
        )}
      </ApplicationList>
    </>
  );
};
export default CustomGroupModalDialogContent;
