export const getCustomGroups = (groups) => {
  try {
    if (typeof groups !== "string") {
      return [];
    }
    const array = JSON.parse(groups ?? []);
    if (Array.isArray(array)) {
      return array?.reduce((acc, x) => (typeof x === "string" ? [...acc, x] : acc), []);
    }
    return typeof array === "string" ? [array] : [];
  } catch (err) {
    return getCustomGroups2(groups);
  }
};

export const getCustomGroups2 = (groups) => {
  if (typeof groups !== "string") {
    return [];
  }
  let ret = [];
  try {
    ret = groups
      .trim()
      .replace(/^\[|\]$/g, "")
      .split(",");
    ret.forEach(function (part, index, arr) {
      arr[index] = part.trim().replace(/^["']|["']$/g, "");
    });
    return ret;
  } catch (err) {
    return [];
  }
};
