import { checkValidInput } from "../../../utils/helpers/text";

export function updateNameValidFactory(setIsNameValid) {
  return (groupName) => {
    if (checkValidInput(groupName)) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
  };
}
