import { OwcIcon } from "@one/react";
import React from "react";
import styled from "styled-components";

const WidgetErrorStyled = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  box-sizing: border-box;
  text-align: center;
  max-height: 100%;
  overflow-y: auto;
  align-items: center;
  text-transform: uppercase;
  word-break: break-word;
  padding: 12px;
  overflow: auto;
  & > svg {
    font-size: 3.5rem;
    color: var(--one-color-cobas-red-400);
  }
  & > p {
    line-height: 1.8;
    font-weight: 400;
    font-size: 16px;
    & span {
      font-weight: 500;
    }
  }
`;
const WidgetError = ({ appName = "appName" }) => {
  return (
    <WidgetErrorStyled data-testid="widget-error">
      <OwcIcon name="circle_warning" type="outlined" data-testid="widget-error-icon" />
      <p data-testid="widget-error-title">
        Widget cannot start. <br />
        Please check the settings for the <br />
        <span data-testid="widget-error-appname">{appName}</span>.
      </p>
    </WidgetErrorStyled>
  );
};

export default WidgetError;
