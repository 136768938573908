import React, { useState } from "react";
import ApplicationList from "./ApplicationList";
import { connect } from "react-redux";
import { selectAppsInGroup, selectFavourites, selectCustomGroup } from "../../selectors";
import AllApplicationMainView from "../../views/AllApplicationMainView";
import ApplicationSlimCard from "./ApplicationSlimCard";
import styles from "./ApplicationGroup.module.scss";
import { OwcIconButton, OwcTypography } from "@one/react";
import { Menu, MenuItem } from "@mui/material";
import AllApplicationMainViewMenu from "../../views/AllApplicationMainViewMenu";
import ApplicationAllAppSlimCard from "./ApplicationAllAppSlimCard";
import { OwcIcon } from "@one/react/Components/OwcIcon";
import { APPLICATION_FAVOURITE_EMPTY_MESSAGE, APPLICATION_HEADING } from "../../constants";
import SearchAllTools from "../searchTools/SearchAllTools";
import { generateID } from "@digitallab/grid-common-components";
import { snakeCase } from "lodash";
import { Module } from "../../constants";

const ApplicationGroup = ({
  applications = [],
  id = "",
  name = "",
  isEditable = false,
  Component = ApplicationSlimCard,
  groupedType,
  setTitle,
  setCustomGroupToEdit,
  setCustomGroupToDelete,
  openDialog,
  handleOpen,
  currentGroup,
  type,
  noRecordMessage = "No applications found"
}) => {
  const [activeFilter, setActiveFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AllApplicationMainView
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        id={generateID.UUID(Module.PAGE.APPLICATIONS.allApplication, `application_${snakeCase(name)}`, "main")}
      />
      <div
        className={styles.backgroundStyle}
        id={generateID.UUID(Module.PAGE.APPLICATIONS.allApplication, `application_${snakeCase(name)}_style`, "div")}
      >
        <div
          className={styles.applicationGroupStyle}
          data-testid={"app-group-" + name}
          id={generateID.UUID(Module.PAGE.APPLICATIONS.allApplication, `application_${snakeCase(name)}`, "div")}
        >
          <div className={styles.nameIconStyle}>
            {name && (
              <OwcTypography
                variant="button"
                data-testid="section-name"
                id={generateID.UUID(
                  Module.PAGE.APPLICATIONS.allApplication,
                  `application_${snakeCase(name)}`,
                  "section"
                )}
              >
                {name}
              </OwcTypography>
            )}
            {isEditable && (
              <>
                <OwcIconButton
                  data-testid="custom-group-card-more-button"
                  flat
                  type="outlined"
                  icon="more_vertical"
                  onClick={handleClick}
                  id={generateID.UUID(
                    Module.PAGE.APPLICATIONS.allApplication,
                    `application_${snakeCase(name)}`,
                    "icon_button"
                  )}
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    style={{
                      display: "flex",
                      width: 120,
                      padding: 10,
                      justifyContent: "left"
                    }}
                    onClick={() => {
                      setTitle("Edit group");
                      setCustomGroupToEdit(currentGroup);
                      handleOpen();
                      handleClose();
                    }}
                    id={generateID.UUID(
                      Module.PAGE.APPLICATIONS.allApplication,
                      `application_${snakeCase(name)}`,
                      "menu_item"
                    )}
                  >
                    <OwcIconButton
                      data-testid={`custom-group-card-menu-item-edit-icon`}
                      flat
                      icon="edit"
                      type="outlined"
                      id={generateID.UUID(
                        Module.PAGE.APPLICATIONS.allApplication,
                        `application_${snakeCase(name)}_edit`,
                        "button"
                      )}
                    />
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setCustomGroupToDelete(currentGroup);
                      openDialog();
                      handleClose();
                    }}
                    style={{
                      display: "flex",
                      width: 120,
                      padding: 10,
                      justifyContent: "left"
                    }}
                    id={generateID.UUID(
                      Module.PAGE.APPLICATIONS.allApplication,
                      `application_${snakeCase(name)}_delete`,
                      "menu"
                    )}
                  >
                    <OwcIconButton
                      data-testid={`custom-group-card-menu-item-delete-icon`}
                      flat
                      icon="delete"
                      type="outlined"
                      id={generateID.UUID(
                        Module.PAGE.APPLICATIONS.allApplication,
                        `application_${snakeCase(name)}_delete_icon`,
                        "menu"
                      )}
                    />
                    Delete
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>

          <ApplicationList
            key={id}
            applications={applications}
            Component={Component}
            noRecordMessage={noRecordMessage}
            type={type}
          />
        </div>
      </div>
    </>
  );
};

export const ApplicationAllGroup = ({
  applications = [],
  favStoreApplication = [],
  applicationSets = [],
  searchFlag = false,
  id = "",
  name = "",
  isEditable = false,
  Component = ApplicationAllAppSlimCard,
  groupedType,
  setTitle,
  setCustomGroupToEdit,
  setCustomGroupToDelete,
  openDialog,
  handleOpen,
  currentGroup,
  noRecordMessage = "No applications found"
}) => {
  const [activeFilter, setActiveFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const favName = "Favorites";
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const favApplication = searchFlag ? selectFavourites(applications, applicationSets) : favStoreApplication;
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={styles.backgroundStyleAll}>
        <div className={styles.applicationGroupStyle} data-testid={"app-group-" + name}>
          <div className={styles.nameIconStyle}>
            <OwcTypography variant="button" data-testid="section-name">
              {APPLICATION_HEADING}
            </OwcTypography>
            <div className={styles.fullscreen_more_vertical}>
              <OwcIcon
                className="one-icons-outlined"
                onClick={(e) => {}}
                type="one-icons-legacy"
                name="more_vertical"
                style={{
                  fontSize: 20
                }}
                id={"fullscreen_more_vertical"}
              />
            </div>
          </div>
          <div className={styles.allApplicationSearch}>
            <SearchAllTools />
          </div>
        </div>
        <div className={styles.applicationGroupStyle} data-testid={"app-group-" + name}>
          <div className={styles.nameIconStyle}>
            {favName && (
              <OwcTypography variant="button" data-testid="section-name">
                {favName}
              </OwcTypography>
            )}
          </div>

          <ApplicationList
            key={id}
            applications={favApplication}
            Component={Component}
            noRecordMessage={APPLICATION_FAVOURITE_EMPTY_MESSAGE}
          />
        </div>
        <div className={styles.applicationGroupStyle} data-testid={"app-group-" + name}>
          <div className={styles.all_application_menu}>
            <AllApplicationMainViewMenu
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              displayName={"All apps"}
            />
          </div>
          <div className={styles.nameIconStyle}>
            {isEditable && (
              <>
                <OwcIconButton
                  data-testid="custom-group-card-more-button"
                  flat
                  type="outlined"
                  icon="more_vertical"
                  onClick={handleClick}
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    style={{
                      display: "flex",
                      width: 120,
                      padding: 10,
                      justifyContent: "left"
                    }}
                    onClick={() => {
                      setTitle("Edit group");
                      setCustomGroupToEdit(currentGroup);
                      handleOpen();
                      handleClose();
                    }}
                  >
                    <OwcIconButton
                      data-testid={`custom-group-card-menu-item-edit-icon`}
                      flat
                      icon="edit"
                      type="outlined"
                    />
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setCustomGroupToDelete(currentGroup);
                      openDialog();
                      handleClose();
                    }}
                    style={{
                      display: "flex",
                      width: 120,
                      padding: 10,
                      justifyContent: "left"
                    }}
                  >
                    <OwcIconButton
                      data-testid={`custom-group-card-menu-item-delete-icon`}
                      flat
                      icon="delete"
                      type="outlined"
                    />
                    Delete
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>

          <ApplicationList
            key={id}
            applications={applications}
            Component={Component}
            noRecordMessage={noRecordMessage}
          />
        </div>
      </div>
    </>
  );
};

export default ApplicationGroup;

const mapStateToProps = (store) => ({
  applications: selectAppsInGroup(store.user.allApps, store.user.selected?.id),
  favStoreApplication: selectFavourites(store.user.allApps, store.user.applicationSets)
});

export const ApplicationGroupSelected = connect(mapStateToProps)(ApplicationAllGroup);

const mapStateToPropsFavs = (store) => ({
  applications: selectFavourites(store.user.allApps, store.user.applicationSets)
});
const mapStateToPropsCustomGroup = (store) => ({
  applications: selectCustomGroup(store.user.allApps, store.user.applicationSets, store.user.selected.id)
});
export const ApplicationCustomGroup = connect(mapStateToPropsCustomGroup)(ApplicationGroup);

const mapStateToPropsCustomCategory = (store) => ({
  applications: selectAppsInGroup(store.user.allApps, store.user.selectedCategory.id)
});
export const ApplicationCustomCategory = connect(mapStateToPropsCustomCategory)(ApplicationGroup);

export const ApplicationFavouritesSelected = connect(mapStateToPropsFavs)(ApplicationGroup);

const mapStateToPropsAllApps = (store) => ({
  applications: store.user.allApps.sort((a, b) => {
    if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
      return 1;
    } else {
      if (b.name?.toLowerCase() > a.name?.toLowerCase()) {
        return -1;
      } else {
        return 0;
      }
    }
  }),
  favStoreApplication: selectFavourites(store.user.allApps, store.user.applicationSets)
});
export const AllApplicationsSelected = connect(mapStateToPropsAllApps)(ApplicationAllGroup);
