import * as yup from "yup";
import { checkValidURL } from "../../../utils/helpers/text";

export const notifyValidationSchemaFactory = () => {
  return yup.object({
    severity: yup.string("Enter severity").typeError("Enter severity").required("Enter severity"),
    short: yup
      .string("Enter title")
      .trim("You must add at least one character that will not be a space")
      .min(1, "You must add at least one character that will not be a space")
      .typeError("Enter title")
      .required("Enter title"),
    details: yup.string("Enter description").typeError("Enter description").nullable(),
    link: yup
      .string("Invalid link, should be a valid URL, e.g. https://roche.com")
      .typeError("Invalid link, should be a valid URL, e.g. https://roche.com")
      .test({
        message: "Invalid link, should be a valid URL, e.g. https://roche.com",
        test: (value) => {
          return (value ?? "").length === 0 || checkValidURL(value);
        }
      })
      .nullable()
  });
};
