import React, { useEffect } from "react";
import { useMobile } from "../../utils/hooks/useMobile";
import CustomGroupModalDialogContent from "./CustomGroupModalDialogContent";
import CustomGroupModalDialogActions from "./CustomGroupModalDialogActions";
import { updateAppSelectionFactory } from "./helpers/updateAppSelectionFactory";
import { updateCustomGroupNameFactory } from "./helpers/updateCustomGroupNameFactory";
import { deleteEntryPointSelectionFactory } from "./helpers/deleteEntryPointSelectionFactory";
import { updateAllEntryPointSelectionFactory } from "./helpers/updateAllEntryPointSelectionFactory";
import { getAppWithEntryPointsFactory } from "./helpers/getAppWithEntryPointsFactory";
import { updateCustomGroupNameAndSetDirtyFactory } from "./helpers/updateCustomGroupNameAndSetDirtyFactory";
import { isAppSelectedFactory } from "./helpers/isAppSelectedFactory";
import { onCancelFactory } from "./helpers/onCancelFactory";
import { searchAppsFactory } from "./helpers/searchAppsFactory";
import { updateNameUniqueFactory } from "./helpers/updateNameUniqueFactory";
import { updateNameValidFactory } from "./helpers/updateNameValidFactory";
import { OwcModalDialog } from "@one/react";

export const EmptyCustomGroup = {
  id: null,
  name: "",
  appsWithEntryPoints: []
};

const emptyArray = [];

const useCustomGroupModal = ({ cancel, applications, customGroup, customGroups }) => {
  const [customGroupForm, setCustomGroupForm] = React.useState({
    ...customGroup
  });
  const [filterdApp, setFilteredApp] = React.useState([...applications]);
  const [searchText, setSearchText] = React.useState("");
  const [isNameUnique, setIsNameUnique] = React.useState(true);
  const [isNameValid, setIsNameValid] = React.useState(true);
  const [dirty, setDirty] = React.useState(false);

  const hasError = !isNameUnique || !isNameValid;

  const searchApps = searchAppsFactory(setSearchText, setFilteredApp, applications);
  const updateNameUnique = updateNameUniqueFactory(customGroups, customGroup, setIsNameUnique);
  const updateNameValid = updateNameValidFactory(setIsNameValid);
  useEffect(() => {
    setCustomGroupForm({
      ...customGroup
    });
    updateNameUnique(customGroup.name);
    updateNameValid(customGroup.name);
    setDirty(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customGroup]);
  useEffect(() => {
    searchApps(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applications]);
  const updateAppSelection = updateAppSelectionFactory(setCustomGroupForm, customGroupForm);
  const updateCustomGroupName = updateCustomGroupNameFactory(
    updateNameUnique,
    updateNameValid,
    setCustomGroupForm,
    customGroupForm
  );
  const onCancel = onCancelFactory(setCustomGroupForm, customGroup, cancel);
  const isAppSelected = isAppSelectedFactory(customGroupForm);
  const deleteEntryPointSelection = deleteEntryPointSelectionFactory(customGroupForm, setCustomGroupForm);
  const updateAllEntryPointsSelection = updateAllEntryPointSelectionFactory(customGroupForm, setCustomGroupForm);
  const getAppWithEntryPoints = getAppWithEntryPointsFactory(customGroupForm);
  const updateCustomGroupNameAndSetDirty = updateCustomGroupNameAndSetDirtyFactory(updateCustomGroupName, setDirty);

  return {
    onCancel,
    customGroupForm,
    dirty,
    hasError,
    updateCustomGroupNameAndSetDirty,
    isNameUnique,
    isNameValid,
    searchText,
    searchApps,
    filterdApp,
    updateAppSelection,
    isAppSelected,
    getAppWithEntryPoints,
    updateAllEntryPointsSelection,
    deleteEntryPointSelection
  };
};

const CustomGroupModal = ({
  open,
  cancel,
  save,
  applications = emptyArray,
  title,
  customGroup = EmptyCustomGroup,
  customGroups = []
}) => {
  const isMobile = useMobile();
  const {
    onCancel,
    customGroupForm,
    dirty,
    hasError,
    updateCustomGroupNameAndSetDirty,
    isNameUnique,
    isNameValid,
    searchText,
    searchApps,
    filterdApp,
    updateAppSelection,
    isAppSelected,
    getAppWithEntryPoints,
    updateAllEntryPointsSelection,
    deleteEntryPointSelection
  } = useCustomGroupModal({
    open,
    cancel,
    save,
    applications,
    title,
    customGroup,
    customGroups
  });

  return (
    <div>
      <OwcModalDialog visible={open} size="lg" className="owcmodalZIndex">
        <div
          slot="header"
          style={{
            borderBottom: "thin solid var(--one-color-interaction-default-neutral-2)"
          }}
        >
          {title}
        </div>
        <div style={{ height: 400, maxHeight: 400, minHeight: 400, padding: 15 }}>
          <CustomGroupModalDialogContent
            isMobile={isMobile}
            customGroupForm={customGroupForm}
            dirty={dirty}
            hasError={hasError}
            updateCustomGroupNameAndSetDirty={updateCustomGroupNameAndSetDirty}
            isNameUnique={isNameUnique}
            isNameValid={isNameValid}
            searchText={searchText}
            searchApps={searchApps}
            filterdApp={filterdApp}
            updateAppSelection={updateAppSelection}
            isAppSelected={isAppSelected}
            getAppWithEntryPoints={getAppWithEntryPoints}
            updateAllEntryPointsSelection={updateAllEntryPointsSelection}
            deleteEntryPointSelection={deleteEntryPointSelection}
          />
        </div>
        <div
          slot="actions"
          style={{
            borderTop: "thin solid var(--one-color-interaction-default-neutral-2)"
          }}
        >
          <CustomGroupModalDialogActions
            onCancel={onCancel}
            save={save}
            customGroupForm={customGroupForm}
            hasError={hasError}
          />
        </div>
      </OwcModalDialog>
    </div>
  );
};

export default CustomGroupModal;
