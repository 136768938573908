import MyLabIcon from "./icons/MyLabIcon";
import EQUIP_DATA_MODEL_TABLE from "./utils/constants/equipmentDataModelTable";

export const LANDING_PAGE_ADMIN = "LandingPageAdmin";
export const LANDING_PAGE_USER = "LandingPageUser";
export const START_PAGE_USERS = "StartPageUsers";
export const STATUS_UPDATE_READERS = "StatusUpdateReaders";
export const SHOW_HIERARCHY = "Show hierarchy";

export const GROUPS_TOKEN_PATH = "signInUserSession.accessToken.payload.cognito:groups";
export const SITE_TOKEN_PATH = "signInUserSession.idToken.payload.custom:site";

export const ACCESS_TOKEN_PATH = "signInUserSession.idToken.payload";
export const SYNC_PROFILE_FIELDS = {
  givenName: "custom:firstName",
  familyName: "custom:lastName",
  name: "preferred_username",
  phone: "custom:phoneNumber",
  site: "custom:site"
};

export const emptyFilter = {
  site: "",
  group: "",
  manufacturer: "",
  category: "",
  responsiblePerson: ""
};
export const DEFAULT_SITE = "Rotkreuz";

export const NOTIFICATIONS_LIMIT = 300;
export const SHORT_NOTIFICATIONS_LIMIT = 3;

export const SETTINGS_LIST_TITLE = {
  addApplication: "Add application",
  addCategory: "Add category",
  addWidget: "Add widget",
  addWidgetDashboard: "Add widget",
  editWidget: "Edit widget",
  addGroup: "Add new group",
  selectEquipment: "Select equipment"
};

export const SETTINGS_LIST_NAME = {
  addApplication: "addApplication",
  addCategory: "addCategory",
  addAdminWidget: "addAdminWidget",
  addDashboardWidget: "addDashboardWidget",
  editDashboardWidget: "editDashboardWidget",
  addGroup: "addGroup",
  selectEquipment: "selectEquipment"
};

export const CLOUD_RESOURCE_ICONS = `/public/resources/deviceIcons/`;
export const BOOKING_ADMIN = "BookingAdmin";
export const BOOKING_USER = "BookingUsers";
export const CHECK_BOX_LIMIT = 500;
export const EQUIPMENT_LIST_LIMIT = 5;
export const VIEW_TYPE = "Grid";
export const LIST_OF_EQUIPMENT = "List of equipment";

export const APPLICATION_LIST_LIMIT = 5;

export const DEFAULT_FILTER = {
  filterName: "Predefined filters",
  filterDefinition: {
    equipmentNickName: null,
    belongingToGroup: null,
    equipmentModel: null,
    location: null
  }
};

export const POSITION_IN_CLUSTER = "position_in_cluster";
export const SERIAL_NUMBER = "serial_number";

export const updateEmailURL = "/admin/general/updateUserEmail";

export const DEFAULT_MYLAB_FILTER = {
  filterName: "My lab",
  filterDefinition: {
    equipmentNickName: null,
    belongingToGroup: null,
    equipmentModel: null,
    location: null,
    status: [],
    inventoryIds: []
  }
};
export const entryType = {
  cluster: "CLUSTER",
  standaloneEquipment: "STANDALONE-EQUIPMENT",
  clusterSubequipment: "CLUSTER-SUBEQUIPMENT"
};
export const MyLabMenuArray = [
  {
    key: "bookEquipment",
    value: "Book equipment",
    availableFor: [entryType.standaloneEquipment]
  },
  {
    key: "bookCluster",
    value: "Book cluster",
    availableFor: [entryType.cluster]
  },
  {
    key: "viewLogbook",
    value: "View Log book",
    availableFor: [entryType.standaloneEquipment, entryType.cluster]
  },
  {
    key: "viewRepo",
    value: "Open in Repository",
    availableFor: [entryType.standaloneEquipment, entryType.cluster]
  },
  {
    key: "viewMonitoring",
    value: "Open in Monitoring",
    availableFor: [entryType.standaloneEquipment, entryType.cluster]
  },
  {
    key: "remove",
    value: "Remove from My lab",
    availableFor: [entryType.standaloneEquipment, entryType.cluster]
  }
];

export const favEquipOptions = [
  { key: "bookEquipment", value: "Book equipment" },
  { key: "viewLogbook", value: "View Log book" },
  { key: "remove", value: "Remove from My lab" }
];

export const favClusterEquipOptions = [
  { key: "bookCluster", value: "Book cluster" },
  { key: "remove", value: "Remove from My lab" }
];
export const DEBOUNCE_DELAY = 1000;
export const DEFAULT_RECORDS_FETCHED = 900;

export const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const TOPBANNER_LABEL = {
  displayTitle: "Display title *",
  url: "URL",
  diplayText: "Display text *",
  selectColorCategory: "Select color category *",
  enableBanner: "Enable banner"
};
export const TOPBANNER_SHORTNAME = {
  heading: "heading",
  inforURL: "infoURl",
  displayText: "displayText"
};
export const EMPTY_FAVORITE = "emptyFavorite";
export const NOT_FAVOURITE = "not_Favourite";

export const DEFAULT_DESCRIPTION_CONSTANT = {
  moduleVersion: "MODULE VERSION",
  moduleName: "APP NAME",
  ciJobId: "CI ID JOB",
  commitHash: "COMMIT HASH",
  updatedAt: "UPDATED AT"
};
export const DEFAULT_IMAGE = "DefaultInstrument.png";
export const LOCALHOST = "localhost";

export const NOTIFICATIONS_BANNER = {
  Orange: "Planned application maintenance",
  Violet: "Planned application update",
  Red: "Unforseen incident",
  Blue: "Information on the application",
  Green: "Positive information to the user"
};

export const BANNER_COLORS = {
  Blue: {
    Heading: "var(--one-color-cobas-blue-600)",
    TextToDisplay: "var(--one-color-legacy-blue-200)",
    Icon: "circle_info",
    IconType: "outlined"
  },
  Green: {
    Heading: "var(--one-color-olive-900)",
    TextToDisplay: "var(--one-color-accent-olive-200)",
    Icon: "circle_confirm",
    IconType: "outlined"
  },
  Orange: {
    Heading: "var(--one-color-cobas-accent-orange-600)",
    TextToDisplay: "var(--one-color-orange-100)",
    Icon: "circle_warning",
    IconType: "outlined"
  },
  Red: {
    Heading: "var(--one-color-red-a-300)",
    TextToDisplay: "var(--one-color-cobas-red-200)",
    Icon: "circle_clear",
    IconType: "outlined"
  },
  Violet: {
    Heading: "var(--one-color-cobas-accent-purple-800)",
    TextToDisplay: "var(--one-color-cobas-accent-purple-200)",
    Icon: "repeat_warning",
    IconType: "outlined"
  }
};

export const NOTIFICATION_BANNER_INITIALS = {
  id: "",
  color: "",
  heading: "",
  displayText: "",
  infoURL: "",
  enabled: false
};

export const PREDEFINED_FILTER_NAME = "Predefined filter";
export const SELECT_PREDEFINED_FILTER_NAME = "Select predefined filter";
export const SELECT_EXISTING_CONFIGURATION = "Select existing  configuration";
export const NEW_CONFIGURATION_NAME = "New configuration name";

export const defaultTableOptions = {
  showTitleNav: true,
  showTopBar: true,
  showAddClusterEquipBtn: false,
  showPreDefinedFilter: true,
  showFilterSettings: true,
  showTopBarActions: true,
  settings: {
    showSelectColumn: true,
    showFilter: true,
    showGroup: true,
    showReason: true,
    showMasterData: true
  },
  actions: {
    showDetailedView: true,
    showEdit: true,
    showAudit: true,
    showDelete: true
  }
};

export const CLUSTER_EQUIPMENT_POPOVER_DETAILS = {
  siteName: {
    key: "Site"
  },
  location: {
    key: "Location"
  },
  equipmentCategory: {
    key: "Category"
  },
  equipmentModel: {
    key: "Model"
  },
  variant: {
    key: "Variant"
  },
  equipmentNickName: {
    key: "Nickname"
  },
  responsiblePerson: {
    key: "Responsible person"
  },
  qualificationStatus: {
    key: "Qualification status"
  }
};

export const CLUSTER_SUB_EQUIPMENT_OBJ = {
  index: "",
  equipmentModel: "Model",
  variant: "Variant",
  equipmentNickName: "Nickname",
  qualificationStatus: "Qualification status",
  softwareVersion: "Software version"
};
export const ADMIN_SUB_MENU = [
  {
    icon: "settings",
    text: "Admin panel",
    url: "/admin",
    isActive: false,
    isDisabled: false,
    children: [
      {
        icon: "",
        text: "Applications & Categories",
        url: "/admin/general/application-categories",
        isActive: false,
        isDisabled: false,
        children: []
      },
      {
        icon: "",
        text: "Widgets",
        url: "/admin/general/widgets",
        isActive: false,
        isDisabled: false,
        children: []
      },
      {
        icon: "",
        text: "Top banner message",
        url: "/admin/general/banner-msg",
        isActive: false,
        isDisabled: false,
        children: []
      },
      {
        icon: "",
        text: "Group notification",
        url: "/admin/general/send-notification",
        isActive: false,
        isDisabled: false,
        children: []
      }
    ]
  }
];

export const HOME_SUB_MENU = [
  {
    icon: "launch",
    text: "Quick access",
    url: "/home/quick-access",
    isActive: false,
    isDisabled: false,
    children: []
  },
  {
    icon: "app_launcher",
    text: "Applications",
    url: "/home/application",
    isActive: false,
    isDisabled: false,
    children: []
  }
];

export const MY_LAB_MENU_ITEM = {
  icon: <MyLabIcon />,
  text: "My lab",
  url: "/home/my-lab",
  isActive: false,
  isDisabled: false,
  children: []
};

export const ALL_TOOLS = "ALL_TOOLS";
export const APPLICATION_HEADING = "Applications";
export const APPLICATION_FAVOURITE_EMPTY_MESSAGE =
  "No favourite applications setup.Please click on the star icon to add.";
export const ASSETS_BASE_URL = process.env.REACT_APP_GRAPHQL_ENDPOINT_CLOUDFRONT;

export const listOfFiltersRequired = [
  EQUIP_DATA_MODEL_TABLE?.equipmentNickName?.key,
  EQUIP_DATA_MODEL_TABLE?.belongingToGroup?.key,
  EQUIP_DATA_MODEL_TABLE?.equipmentModel?.key,
  EQUIP_DATA_MODEL_TABLE?.location?.key,
  EQUIP_DATA_MODEL_TABLE?.status?.key
];

export const equipmentStatusFilterList = ["Active", "Pending"];
export const status = {
  ACTIVE: "Active",
  PENDING: "Pending"
};

export const listOfFieldsNotSortable = [];

export const listOfFieldsIgnoredInTable = [EQUIP_DATA_MODEL_TABLE?.trackPositionInCluster?.key];

export const NOTIFICATION_DATA_MODEL = {
  source: {
    key: "source",
    value: "App or domain name"
  },
  message: {
    key: "message",
    value: "Title"
  },
  severity: {
    key: "severity",
    value: "Severity"
  },
  time: {
    key: "time",
    value: "Date and time"
  },
  details: {
    key: "details",
    value: "Description"
  }
};

export const listNotificationFieldTextFilter = [
  NOTIFICATION_DATA_MODEL?.source?.key,
  NOTIFICATION_DATA_MODEL?.message?.key
];

export const listNotificationFieldSetFilter = [NOTIFICATION_DATA_MODEL?.severity?.key];

export const listNotificationFieldDateFilter = [NOTIFICATION_DATA_MODEL?.time?.key];

export const APPLICATION_DATA_MODEL = {
  name: {
    key: "name",
    value: "Application name"
  },
  updatedAt: {
    key: "updatedAt",
    value: "Last update"
  }
};

export const EMPTY_FILTER_LIST = {
  instrumentNameList: [],
  instrumentTypeList: [],
  belongingToGroupList: [],
  locationList: []
};

export const APPLICATION_NAME = "gridApplication";

export const Module = {
  HEADERS: {
    name: "headerMenu",
    gridIcon: "gridIcon",
    gridIconText: "gridIconText",
    verticalLine: "verticalLine",
    userInfo: "userInfo",
    displayName: "displayName",
    circleInfo: "circleInfo",
    rocheLogo: "rocheLogo",
    site: "site"
  },
  SUBMENU: {
    name: "tabMenu"
  },
  SIDEPANEL: {
    name: "side_panel"
  },
  COMMON: {
    TABLE: {
      name: "common_table",
      tHead: "thead",
      tHeadTr: "thead_tr",
      tHeadTd: "thead_td",
      tBody: "tbody",
      tBodyTr: "tbody_tr",
      tBodyTd: "tbody_td",
      tblButton: "tblbutton",
      tFoot: "tfoot",
      pagination: "pagination",
      rowsPerPage: "rows_per_page",
      firstPage: "first_page",
      prevButton: "prev_button",
      nextButton: "next_button"
    },
    APPBAR: {
      appBarName: "appBar_desktop"
    }
  },
  PAGE: {
    LOGIN: {},
    INFO: {
      name: "infoPage",
      goBack: "goBack",
      goHome: "goHome",
      description: "description",
      backendInfo: "backendInfo",
      infoTitle: "infoTitle",
      infoAppName: "infoAppName",
      infoModuleName: "infoModuleName",
      infoSystemVersion: "infoSystemVersion",
      infoModuleVersion: "infoModuleVersion",
      infoCiJobId: "infoCiJobId",
      infoCiJobIdText: "infoCiJobIdText",
      infoCommitHash: "infoCommitHash",
      infoCommitHashText: "infoCommitHashText",
      infoUpdateAt: "infoUpdateAt",
      infoUpdateAtText: "infoUpdateAtText"
    },
    DASHBOARD: {
      name: "dashboard",
      action: "dashboard_action",
      equipmentGrid: "equipment_grid"
    },
    MYLAB: {
      title: "mylab",
      addEquipmentBtn: "addEquipmentBtn",
      equipmentTable: "equipmentTable",
      cardList: "card_list",
      confirmDiaglogActionCancel: "confirm_diaglog_action_cancel",
      confirmDiaglogActionApprove: "confirm_diaglog_action_approve",
      fieldConfirmation: "field_confirmation",
      shortIdentifier: "short_identifier",
      equipmentMoreButton: "equipment-more-button",
      cardDescription: "card_description",
      instrumentPicture: "instrument_picture",
      mainContent: "main_content",
      equipmentMenu: "equipment_menu",
      cardStyle: "card_style",
      noData: "no_data",
      equipmentsCardsView: "equipments_cards_view",
      listSubTitle: "list_sub_title",
      listGridContainer: "list_grid_container",
      filterModal: "filter_modal",
      filterModalNotes: "filter_modal_notes",
      filterAction: "filter_action",
      filterForm: "filter_form",
      filterSaved: "filter_saved",
      filterSavedModal: "filter_saved_modal",
      filterDialogModal: "filter_dialog_modal",
      preDefinedFilter: "pre_defined_filter",
      equipmentSearch: "equipment_search",
      equipmentSearchFilter: "equipment_search_filter",
      equipmentSearchbox: "equipment_search_box",
      equipmentListview: "equipment_list_view",
      equipmentListSaveButton: "equipment_list_save_button"
    },
    APPLICATIONS: {
      name: "application",
      allApplication: "allApplication",
      applicationList: "application_list",
      myEquipment: "my_equipment",
      equipmentGroup: "equipment_group",
      customGroup: "custom_group",
      favourites: "favourites",
      allCustomGroups: "all_custom_groups",
      applicationSearch: "application_search"
    },
    NOTIFICATION: {
      notificationName: "notification_page"
    },
    ADMIN: {
      appAndCategories: "appAndCategories",
      appAndCategoriesTable: "appAndCategoriesTable",
      confirmDialog: "confirm_dialog",
      applicationModal: "application_modal",
      fileuploader: "file_uploader",
      applicationModalCategorySelect: "modal_category_select",
      addVisibilityForUserRolesInput: "visibility_user_roles",
      runModeInput: "run_mode",
      descriptionInput: "description_input",
      launchStepName: "launch_step_name",
      launchStepLink: "launch_step_link",
      launchStepList: "launch_step_list",
      applicationTable: "application_table",
      applicationAdmin: "application_admin",
      adminWidget: "admin_widget",
      adminTopBannerMessage: "admin_top_banner_message",
      adminSendGroupNotify: "send_group_notify"
    }
  },
  FOOTER: {}
};
