import React, { useState } from "react";
import { SettingsContext } from "../../contexts";

export const EmptyCustomGroup = {
  id: null,
  name: "",
  appsWithEntryPoints: []
};

const SettingsContextWrapper = ({ children, initialState }) => {
  const [application, setApplication] = useState(null);
  const [apps, setApps] = useState([]);
  const [open, setOpen] = useState(false);
  const [widget, setWidget] = useState(null);
  const [dashboardStatus, setDashboardStatus] = useState(false);
  const [equipmentStatus, setEquipmentStatus] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SettingsContext.Provider
      value={{
        application,
        setApplication,
        apps,
        setApps,
        open,
        handleClose,
        setOpen,
        widget,
        setWidget,
        dashboardStatus,
        setDashboardStatus,
        equipmentStatus,
        setEquipmentStatus
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextWrapper;
