const BUTTON_MODEL_LABEL = {
  addApplication: "Add application",
  editApplication: "Edit application",
  addCategory: "Add category",
  editCategory: "Edit category",
  addWidget: "Add widget",
  editWidget: "Edit widget",
  send: "Send",
  save: "Save",
  add: "Add",
  addEquipment: "Add equipment",
  cancel: "Cancel",
  continue: "Continue",
  clearFilters: "Clear filters",
  saveSelection: "Save selection to my lab",
  confirmChanges: "Confirm changes",
  createGroup: "New group"
};

export default BUTTON_MODEL_LABEL;
