import { emptyEntryPoint } from "./emptyEntryPoint";

export function updateEntryPointFactory({
  setDirtyInputs,
  dirtyInputs,
  checkValidEntryPoint,
  application,
  entryPoint,
  updateApplication,
  setEntryPoint
}) {
  return () => {
    setDirtyInputs({
      ...dirtyInputs,
      entryPoint_name: true,
      entryPoint_url: true
    });
    if (checkValidEntryPoint()) {
      const entryPointIndex = application.entryPoints.findIndex(
        (thisEntryPoint) => thisEntryPoint.id === entryPoint.id
      );
      application.entryPoints[entryPointIndex] = {
        ...entryPoint
      };
      updateApplication({ value: application.entryPoints, key: "entryPoints" });
      setEntryPoint({ ...emptyEntryPoint });
      setDirtyInputs({
        ...dirtyInputs,
        entryPoint_name: false,
        entryPoint_url: false
      });
    }
  };
}
