import React from "react";
import TopBannerMessageNotifyForm from "./TopBannerMessageNotifyForm";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import styles from "./TopBannerMessageNotifyForm.module.scss";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";

const { adminTopBannerMessage } = Module.PAGE.ADMIN;

const TopBannerMessageMainPage = () => {
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView({
      documentTitle: "Admin/SendGroupNotify",
      href: `${window.location?.origin}/Admin/SendGroupNotify`
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div
      className={styles.MainPageBox}
      data-testid="main-page-for-send-group-notify"
      id={generateID.UUID(adminTopBannerMessage, `topbanner`, "div_send_notify")}
    >
      <TopBannerMessageNotifyForm />
    </div>
  );
};

export default TopBannerMessageMainPage;
