import { useEffect } from "react";
import { askForPermissions } from "./helpers";

export const isNotificationSupported = () => {
  return "Notification" in window;
};

export const checkPermissions = async () => {
  if (!isNotificationSupported()) {
    console.info("This browser does not support desktop notification");
    return;
  }
  if (!["denied", "granted"].includes(Notification.permission)) {
    try {
      await askForPermissions(`Notifications permission has been granted.`);
    } catch (err) {
      console.error("@@@checkPermissions: ", err);
    }
  }
};

export const usePermissions = () => {
  useEffect(() => {
    checkPermissions();
  }, []);
};
