// import IconPreview from "../../../uploadIcon/IconPreview";
// import FileUploader, {
//   DefaultFileClearButton
// } from "../../../uploadIcon/FileUploader";
import { OwcIcon, OwcInput } from "@one/react";
import FileUploader, { DefaultFileClearButton } from "../../../uploadIcon/FileUploader";
import IconPreview from "../../../uploadIcon/IconPreview";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";

const getHelperText = (validationInputs, dirtyInputs, uniqueNameApp, application) => {
  if (!validationInputs.name && dirtyInputs.name) {
    return `Application name must have at least 1 character and can only contain alphanumeric characters, space, and _ - :`;
  } else if (!uniqueNameApp) {
    return `Application named '${application.name}' already exists`;
  } else {
    return undefined;
  }
};
const FileUpload = ({ setFile, onFailedRequirements, file, application }) => {
  return (
    <FileUploader
      onFileSelect={setFile}
      onFailedRequirements={onFailedRequirements}
      uploadProps={{
        style: {
          display: "flex",
          flexDirection: "row",
          height: 48
        }
      }}
      renderIcon={() => {
        return (
          <IconPreview
            file={file}
            icon={application?.icon}
            iconPath={application?.iconPath}
            style={{
              width: 48,
              height: 48,
              marginRight: 12,
              textAlign: "center",
              verticalAlign: "baseline",
              lineHeight: "48px"
            }}
            id={generateID.UUID(Module.PAGE.ADMIN.applicationModal, `appAndCategories_icon_preview`, "dialog")}
          />
        );
      }}
      renderClear={({ clear }) => {
        if (file) {
          return (
            <DefaultFileClearButton
              data-testid="clear-file-button"
              onClick={() => {
                clear(null);
                setFile(null);
              }}
              id={generateID.UUID(Module.PAGE.ADMIN.applicationModal, `appAndCategories`, "file")}
            >
              <i
                className="one-icons-filled"
                id={generateID.UUID(Module.PAGE.ADMIN.applicationModal, `appAndCategories`, "i_circle_clear")}
              >
                circle_clear
              </i>
            </DefaultFileClearButton>
          );
        }
        return null;
      }}
    />
  );
};

const NameAndIconInput = ({
  application,
  setFile,
  onFailedRequirements,
  file,
  validationInputs,
  dirtyInputs,
  uniqueNameApp,
  updateApplication,
  setDirtyInputs
}) => {
  return (
    <div
      id={generateID.UUID(Module.PAGE.ADMIN.applicationModal, `appAndCategories`, "name_div")}
      style={{ display: "flex" }}
    >
      {application.id && (
        <FileUpload
          setFile={setFile}
          onFailedRequirements={onFailedRequirements}
          file={file}
          application={application}
          id={generateID.UUID(Module.PAGE.ADMIN.fileuploader, `appAndCategories`, "dialog")}
        />
      )}
      <OwcInput
        data-testid="application-edit-form-name-input"
        id="standard-secondary"
        label="Application name *"
        error={(!validationInputs.name && dirtyInputs.name) || !uniqueNameApp}
        color="primary"
        variant="filled"
        required
        maxLength="60"
        size="small"
        style={{ width: "245px", marginRight: 22 }}
        value={application.name}
        onInputChange={(event) => updateApplication({ value: event.target.value, key: "name" })}
        onBlur={() =>
          setDirtyInputs({
            ...dirtyInputs,
            name: true
          })
        }
        FormHelperTextProps={{
          "data-testid": "application-edit-form-name-helper-text"
        }}
      >
        {application?.name?.length > 0 && (
          <OwcIcon
            slot="suffix"
            type="filled"
            name="circle_clear"
            onClick={() => updateApplication({ value: "", key: "name" })}
            id={generateID.UUID(Module.PAGE.ADMIN.applicationModal, `appAndCategories_icon_circle_clear`, "dialog")}
            className="pointer"
          />
        )}
        <label slot="error-text">{getHelperText(validationInputs, dirtyInputs, uniqueNameApp, application)}</label>
      </OwcInput>
    </div>
  );
};
export default NameAndIconInput;
