import React from "react";
import { Query } from "react-apollo";
import { LIST_APPLICATION_GROUPS } from "../../gql/landingapi/queries";
import styled from "styled-components";
import { AddOrEditContextWapper, QueryContextWapper, DeleteContextWapper } from "./contexts";
import CategoriesList from "./CategoriesList";
import DialogAddAndUpdate from "./DialogAddAndUpdate";
import DialogDelete from "./DialogDelete";
import UpdateReduxDataStore from "./UpdateReduxStore";
import { CircularProgress } from "@mui/material";
import { OwcButton, OwcTypography } from "@one/react";
import TopbarForCategory from "./TopbarForCategory";
import { Module } from "../../constants";
import { generateID } from "@digitallab/grid-common-components";

const { appAndCategories } = Module.PAGE.ADMIN;

const CategoriesStyled = styled.div`
  display: flex;
  flex-direction: column;
  & .info {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
`;

const Categories = ({ id }) => {
  return (
    <CategoriesStyled data-testid={`categories-${id}`} id={id}>
      <Query
        id={generateID.UUID(appAndCategories, `category`, "query")}
        query={LIST_APPLICATION_GROUPS}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, refetch }) => {
          if (loading) {
            return (
              <div className="info" data-testid="loading" id={generateID.UUID(appAndCategories, `category`, "loading")}>
                <CircularProgress size={40} />
              </div>
            );
          }
          if (error) {
            return (
              <div
                className="info"
                data-testid="error"
                id={generateID.UUID(appAndCategories, `category`, "info_error")}
              >
                <OwcTypography
                  variant="subtitle1"
                  gutterBottom
                  id={generateID.UUID(appAndCategories, `category`, "info_type")}
                >
                  {error?.message}
                </OwcTypography>
                <OwcButton
                  variant="contained"
                  onClick={refetch}
                  color="secondary"
                  data-testid="try-again-button"
                  id={generateID.UUID(appAndCategories, `category`, "button_try")}
                >
                  Try again
                </OwcButton>
              </div>
            );
          }
          return (
            <AddOrEditContextWapper>
              <DeleteContextWapper>
                <QueryContextWapper>
                  <TopbarForCategory />
                  <CategoriesList />
                  <DialogAddAndUpdate />
                  <DialogDelete />
                  <UpdateReduxDataStore />
                </QueryContextWapper>
              </DeleteContextWapper>
            </AddOrEditContextWapper>
          );
        }}
      </Query>
    </CategoriesStyled>
  );
};
export default Categories;
