import React from "react";
import AuthenticatedWidget from "./../dashboard/qfront/AuthenticatedWidget";
import { LoadImportMap } from "./../dashboard/qfront/LoadImportMap";
import { APPLICATION_NAME } from "../../constants";
import GetWidgets from "./../dashboard/GetWidgets";
import AllWidgetsWrapper from "./../adminPanel/widgets/context/AllWidgetsWrapper";
export const NotificationLoader = () => {
  window.addEventListener("goBack", function () {
    window.location.href = window.location.origin + "/home/dashboard";
  });
  return (
    <div>
      <AllWidgetsWrapper>
        <GetWidgets />
        <LoadImportMap>
          <AuthenticatedWidget
            appId="notificationApp"
            params={{ theme: "cobas_light", customAppID: APPLICATION_NAME }}
          />
        </LoadImportMap>
      </AllWidgetsWrapper>
    </div>
  );
};
