import styled, { css } from "styled-components";
import DetailsCells from "../../../../components/shared/details-cells/DetailsCells";
import { DetailsCellStyled } from "../../../../components/shared/details-cells/DetailsCellStyled";
import ActionsForWidgetsListBar from "../ActionsForWidgetsListBar";
import { changeStringToDate } from "../../../../utils/helpers/text";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
`;
const customDetailsCellExpand = css`
  &&& {
    width: auto;
  }

  & > :last-child {
    text-overflow: unset;
    overflow: unset;
    white-space: unset;
    word-break: unset;
  }
`;
export const CustomDetailsCellStyled = styled(DetailsCellStyled)`
  ${(props) => props.expand && customDetailsCellExpand}
`;

export const RunLogWrapper = styled.div`
  white-space: normal;
  max-width: 100%;
  word-break: break-all;
  ${DetailsCellStyled} {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
    width: auto;
    &:last-child {
      width: 100%;
      margin-right: 0;

      & > :last-child {
        white-space: unset;
      }
    }
  }
`;

export const WidgetListForBarMeta = {
  expandable: true,
  ExpandedComponent: ({ item, infoMeta }) => (
    <Flex>
      <DetailsCells Wrapper={RunLogWrapper} item={item} infoMeta={infoMeta} CellWrapper={CustomDetailsCellStyled} />
    </Flex>
  ),
  expandedComponentProps: {
    infoMeta: {
      fields: [
        {
          label: "Version",
          field: ["version"]
        },
        {
          label: "Release date",
          component: ({ item }) => changeStringToDate(item?.releaseDate)
        },
        {
          label: "Description",
          component: ({ item }) =>
            item?.description?.split("\n").map((line) => (
              <>
                {line}
                <br />
              </>
            ))
        }
      ]
    }
  },

  fields: {
    name: {
      sortable: true,
      text: "Widget name",
      headProps: {
        style: {
          flex: "0 1 50%"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 50%"
        }
      }
    },
    fromApp: {
      sortable: true,
      text: "From app",
      component: ({ item }) => item.fromAppName || item.fromAppSource,
      headProps: {
        style: {
          flex: "0 1 50%"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 50%"
        }
      }
    },
    actions: {
      component: ActionsForWidgetsListBar,
      text: "Action",
      headProps: {
        style: {
          flex: "0 0 107px",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "12px"
        }
      },
      cellProps: {
        style: {
          flex: "0 0 107px",
          display: "flex",
          justifyContent: "flex-end"
        }
      }
    }
  }
};

export default WidgetListForBarMeta;
