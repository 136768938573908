import React from "react";
import CustomList from "../../../../components/shared/List";

const WidgetTableForBar = ({ widgets, meta, isReverseOrder, setIsReverseOrder, filteredData, setOrderBy, orderBy }) => {
  return (
    <CustomList
      orderBy={orderBy[0]}
      isReverseOrder={isReverseOrder}
      onRequestSort={(key, isReverse) => {
        setIsReverseOrder(isReverse);
        setOrderBy([key]);
      }}
      data={filteredData}
      meta={meta}
      loading={widgets === null}
    />
  );
};

export default WidgetTableForBar;
