import React from "react";
import { Route, Switch } from "react-router";
import PaginationWrapper from "../../../components/shared/pagination/PaginationWrapper";
import AppAndCategoriesTab from "../appAndCategories/AppAndCategoriesTab";
import SendGroupNotifyMainPage from "../SendGroupNotify/SendGroupNotifyMainPage";
import TopBannerMessageMainPage from "../TopBannerMessage/TopBannerMessageMainPage";
import AllWidgetsWrapper from "../widgets/context/AllWidgetsWrapper";
import WidgetsPage from "../widgets/WidgetsPage";
import UpdateUserEmail from "../../user/updateUser/UpdateUserEmail";
import { updateEmailURL } from "../../../constants";

const GeneralMainPage = () => {
  return (
    <Switch>
      <Route exact path="/admin/general/application-categories">
        <AppAndCategoriesTab />
      </Route>
      <Route exact path="/admin/general/widgets">
        <AllWidgetsWrapper>
          <PaginationWrapper>
            <WidgetsPage />
          </PaginationWrapper>
        </AllWidgetsWrapper>
      </Route>
      <Route exact path="/admin/general/banner-msg">
        <TopBannerMessageMainPage />
      </Route>
      <Route exact path="/admin/general/send-notification">
        <SendGroupNotifyMainPage />
      </Route>
      <Route exact path={updateEmailURL}>
        <UpdateUserEmail />
      </Route>
    </Switch>
  );
};

export default GeneralMainPage;
