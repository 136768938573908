import React, { useEffect } from "react";
import { useLoadState } from "../../utils/hooks/useLoadState";
import AppLoadState from "./AppLoadState";

const CheckAppRoles = ({ children, checkRolesMethod, logout }) => {
  const { loading, error, dispatchAction } = useLoadState();
  useEffect(() => {
    dispatchAction({
      type: "loading",
      payload: true
    });
    const checkRoles = async () => {
      try {
        await checkRolesMethod();
        dispatchAction({
          type: "loading",
          payload: false
        });
      } catch (err) {
        console.warn(err);
        dispatchAction({
          type: "error",
          payload: err
        });
      }
    };
    checkRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AppLoadState loading={loading} error={error} onLogout={logout}>
      {children}
    </AppLoadState>
  );
};

export default CheckAppRoles;
