import { useContext } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { GridContext } from "./grid/context/context";
import { WidgetsBarContext } from "./widgetsBar/context/context";
import { withApollo } from "react-apollo";
import { UPDATE_DASHBOARD } from "../../gql/landingapi/mutations";
import Snackbar from "../snackbar/Snackbar";
import { OwcButton } from "@one/react";
import { CircularProgress } from "@mui/material";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../constants";

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

const updateDashboard = async (dashboard, client) => {
  try {
    await client.mutate({
      mutation: UPDATE_DASHBOARD,
      variables: {
        id: dashboard.id,
        widgets: dashboard.widgets
      }
    });
    Snackbar({
      appName: "",
      icon: "circle_confirm",
      type: "success",
      text: `Successfully updated dashboard`
    });
  } catch (e) {
    Snackbar({
      appName: "",
      icon: "no",
      type: "warning",
      text: `Failed updating dashboard`
    });
    return;
  }
};

const DashboardActionButtons = ({ client }) => {
  const { WidgetsBarToggle } = useContext(WidgetsBarContext);
  const { setEditable, isGridEditable, setPendingSaving, pendingSaving } = useContext(GridContext);

  const dashboard = useSelector((state) => state.user?.dashboard);
  return (
    <>
      {
        <ButtonBox id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard_box`, "button")}>
          {!isGridEditable ? (
            <>
              {dashboard?.widgets?.length > 0 && (
                <OwcButton
                  variant="secondary"
                  data-testid="edit-dashboard-widgets-button"
                  onClick={() => setEditable(true)}
                  id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard_edit`, "button")}
                >
                  Edit widgets
                </OwcButton>
              )}
              <OwcButton
                data-testid="add-widgets-to-dashboard-button"
                onClick={WidgetsBarToggle}
                id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard_add`, "button")}
                variant="primary"
              >
                Add widget
              </OwcButton>
            </>
          ) : (
            <>
              <OwcButton
                variant="secondary"
                data-testid="cancel-editing-dashboard-button"
                disabled={pendingSaving}
                onClick={() => setEditable(false, true)}
                id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard_cancel`, "button")}
              >
                Cancel
              </OwcButton>
              <OwcButton
                variant="secondary"
                data-testid="confirm-editing-dashboard-button"
                color="primary"
                disabled={pendingSaving}
                onClick={async () => {
                  setPendingSaving(true);
                  await updateDashboard(dashboard, client);
                  setPendingSaving(false);
                  setEditable(false);
                }}
                id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard_pending`, "button")}
              >
                {pendingSaving && (
                  <CircularProgress
                    data-testid="circular-progress-on-confirm-editing-dashboard-button"
                    color="primary"
                    size={20}
                    style={{ marginRight: 6 }}
                    id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard_circular`, "button")}
                  />
                )}
                Confirm changes
              </OwcButton>
            </>
          )}
        </ButtonBox>
      }
    </>
  );
};

export default withApollo(DashboardActionButtons);
