import { useState } from "react";
import { withApollo } from "react-apollo";
import { omit } from "underscore";
import { v4 } from "uuid";
import { CREATE_WIDGET, UPDATE_WIDGET } from "../../../../gql/landingapi/mutations";
import Snackbar from "../../../snackbar/Snackbar";
import WidgetEditDialog from "./WidgetEditDialog";
import WidgetMetadataUrlDialog from "./WidgetMetadataUrlDialog";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";

const { adminWidget } = Module.PAGE.ADMIN;

const ChangeWidgetWorkflow = ({ client, widget, onApprove, onCancel, startFrom = 1 }) => {
  const [step, setStep] = useState(startFrom);
  const [updatedWidget, setUpdatedWidget] = useState(widget);
  const [metadata, setMetadata] = useState(null);
  const [loading, setLoading] = useState(false);

  const onFirstStepApprove = async (metadataUrl, meta) => {
    setMetadata(meta);
    setStep(2);
    setUpdatedWidget({
      ...updatedWidget,
      metadataURL: metadataUrl,
      releaseDate: meta.releaseDate,
      version: meta.version,
      widgetURL: meta.widgetURL,
      fromAppName: meta.fromAppName ?? "",
      fromAppSource: meta.fromAppSource,
      supportedSizes: meta.supportedSizes,
      name: meta.name,
      description: meta.description
    });
  };

  const onSecondStepApprove = async () => {
    setLoading(true);
    let text = "";
    if (updatedWidget.id && metadata) {
      text = "updated widget!";
    }
    if (updatedWidget.id && !metadata) {
      text = "edited widget!";
    }

    if (!updatedWidget.id) {
      text = "created widget!";
    }
    try {
      await client.mutate({
        fetchPolicy: "no-cache",
        mutation: updatedWidget.id ? UPDATE_WIDGET : CREATE_WIDGET,
        variables: {
          ...omit(updatedWidget, "__typename"),
          supportedSizes: updatedWidget.supportedSizes?.map((size) => ({
            ...omit(size, "__typename")
          })),
          id: updatedWidget.id ?? v4()
        }
      });

      Snackbar({
        appName: "",
        icon: "circle_confirm",
        type: "success",
        text: `Successfully ${text}`
      });
      setLoading(false);
    } catch (e) {
      Snackbar({
        appName: "",
        icon: "no",
        type: "warning",
        text: `Failed ${text}`
      });
      setLoading(false);

      return;
    }

    onApprove();

    onCancel();
  };

  return (
    <>
      <WidgetMetadataUrlDialog
        open={step === 1}
        onCancel={onCancel}
        onApprove={onFirstStepApprove}
        widget={updatedWidget}
        loading={loading}
        setLoading={setLoading}
        id={generateID.UUID(adminWidget, `changeWidget`, "widgetMeta")}
      />
      <WidgetEditDialog
        open={step === 2}
        onCancel={onCancel}
        metadata={metadata}
        widget={updatedWidget}
        onWidgetChange={setUpdatedWidget}
        onApprove={onSecondStepApprove}
        loading={loading}
        id={generateID.UUID(adminWidget, `changeWidget`, "widget_Eidt")}
      />
    </>
  );
};

export default withApollo(ChangeWidgetWorkflow);
