import { v4 } from "uuid";
import { SEND_GROUP_NOTIFICATION } from "../../../gql/landingapi/mutations";
import { ON_UPDATE_LANDING_PAGE_NOTIFICATION_COMMAND } from "../../../gql/landingapi/subscriptions";
import { waitUntilSubscribeCondition } from "../../../utils/helpers/fetching";
import Snackbar from "../../snackbar/Snackbar";

export const checkSubscribeCondition = (id) => {
  return (data) =>
    data.onUpdateLandingPageNotificationCommand.id === id &&
    data.onUpdateLandingPageNotificationCommand.status !== null;
};

export const sendGroupNotify = async (client, notify) => {
  try {
    const id = v4();
    const promise = waitUntilSubscribeCondition(
      client,
      ON_UPDATE_LANDING_PAGE_NOTIFICATION_COMMAND,
      checkSubscribeCondition(id)
    );

    await new Promise((res) => setTimeout(res, 2000));

    await client.mutate({
      fetchPolicy: "no-cache",
      mutation: SEND_GROUP_NOTIFICATION,
      variables: {
        id,
        short: notify.short,
        details: notify.details,
        link: notify.link,
        severity: notify.severity
      }
    });
    await promise;

    Snackbar({
      appName: "",
      icon: "circle_confirm",
      type: "success",
      text: `Group notification successfully sent`
    });

    return true;
  } catch (e) {
    Snackbar({
      appName: "",
      icon: "no",
      type: "warning",
      text: `Failed sent notification!`
    });

    return false;
  }
};
