import React from "react";
import ItemOfShortViewNotify from "./ItemOfShortViewNotify";
import { NoData } from "../mobileViewOfNotifications/MobileViewOfNotificationsPage";
import { OwcList } from "@one/react";

const BodyPopoverNotify = ({ notifications, isData }) => {
  return (
    <>
      {isData && (
        <OwcList data-testid="short-notification-list-body-section-list-of-notification">
          {notifications.map((notification) => (
            <ItemOfShortViewNotify key={notification.id} notification={notification} />
          ))}
        </OwcList>
      )}
      {!isData && <NoData data-testid="short-notification-list-no-data">No data</NoData>}
    </>
  );
};

export default BodyPopoverNotify;
