import * as types from "./actionTypes";

export const loadUserInfo = (userInfo) => ({
  type: types.LOAD_USER_INFO,
  payload: userInfo
});

export const selectGroupUserInfo = (group) => ({
  type: types.SELECT_GROUP_USER_INFO,
  payload: group
});

export const selectCategoryUserInfo = (group) => ({
  type: types.SELECT_CATEGORY_USER_INFO,
  payload: group
});

export const selectNotificationUserInfo = (notificationId) => ({
  type: types.SELECT_NOTIFICATION_USER_INFO,
  payload: notificationId
});

export const updateGroup = (group) => ({
  type: types.UPDATE_GROUP_APP,
  payload: group
});

export const addWidgetToDashboard = (widget) => ({
  type: types.ADD_WIDGET_TO_DASHBOARD,
  payload: widget
});

export const updateDashboard = (dashboard) => ({
  type: types.UPDATE_DASHBOARD,
  payload: dashboard
});

export const updateDashboardWidgets = (widgets) => ({
  type: types.UPDATE_DASHBOARD_WIDGETS,
  payload: widgets
});

export const loadSources = (sources) => ({
  type: types.LOAD_SOURCES,
  payload: sources
});

export const updateAccordionState = (accordionState) => ({
  type: types.UPDATE_ACCORDION_STATE,
  payload: accordionState
});

export const updateFavouriteEquipments = (favouriteEquipments) => ({
  type: types.UPDATE_FAVOURITE_EQUIPMENT_STATE,
  payload: favouriteEquipments
});

export const updateFavouriteEquipmentViewTypeState = (viewType) => ({
  type: types.UPDATE_FAVOURITE_EQUIPMENT_VIEW_TYPE_STATE,
  payload: viewType
});

export const updateUserFilters = (UserFilter) => ({
  type: types.UPDATE_USER_FILTERS,
  payload: UserFilter
});

export const updateLastFavFilter = (lastFavFilter) => ({
  type: types.UPDATE_LAST_FAV_FILTER,
  payload: lastFavFilter
});

export const updateNotificationBanner = (NotificationBanner) => ({
  type: types.UPDATE_NOTIFICATION_BANNER,
  payload: NotificationBanner
});

export const updateTopBannerFlag = (topBannerFlag) => ({
  type: types.UPDATE_TOP_BANNER_FLAG,
  payload: topBannerFlag
});
