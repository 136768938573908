import React from "react";
import styled from "styled-components";

const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--one-color-gray-800);
  padding: 16px 12px;
`;

const HeadPopoverNotify = () => {
  return <HeaderTitle data-testid="short-notification-list-header-section">Recent notifications</HeaderTitle>;
};

export default HeadPopoverNotify;
