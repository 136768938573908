import React, { useContext } from "react";
import { changeSiteAttribute } from "./helpers";
import SiteControl from "./SiteControl";
import styled from "styled-components";
import { SiteContext } from "./context";
import { LinearProgress } from "@mui/material";
import { OwcListItem } from "@one/react";

const SwitchSiteStyled = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  min-height: 36px;
  align-items: center;
`;

const RefreshIcon = styled.i`
  color: var(--one-color-gray-600);
  margin-right: 17px;
`;

export const initialState = {
  refresh: 0,
  items: [],
  error: null
};

export const SwitchSite = ({ onSiteDropdownChange }) => {
  const { loading, items, error, dispatchAction } = useContext(SiteContext);
  if (error) {
    return (
      <OwcListItem
        data-testid="switcherSite-error"
        onClick={() =>
          dispatchAction({
            type: "refresh"
          })
        }
        key="switcherSite_error"
      >
        <RefreshIcon className="one-icons-outlined">refresh</RefreshIcon> Refetch sites
      </OwcListItem>
    );
  }
  if (loading) {
    return (
      <SwitchSiteStyled>
        <LinearProgress style={{ width: "100%" }} data-testid="switcherSite-loading" />
      </SwitchSiteStyled>
    );
  }
  return (
    <div data-testid="switcherSite">
      <SiteControl onSiteDropdownChange={onSiteDropdownChange} sites={items} onChange={changeSiteAttribute} />
    </div>
  );
};

export default SwitchSite;
