import React, { useContext } from "react";
import { FullScreenCentered } from "../landingPageUser/LandingPageUser";
import { SiteContext } from "./context";
import { UNKNOWN } from "./SiteWrapper";
import SwitchSite from "./SwitchSite";
import styled from "styled-components";
import { CircularProgress, Paper } from "@mui/material";
import { OwcTypography } from "@one/react";

const SiteInitSelectorStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  & > div {
    display: flex;
    flex-direction: column;
  }
`;

const SiteInitSelector = ({ children }) => {
  const { loading, selected, currentSite } = useContext(SiteContext);
  if (loading) {
    return (
      <FullScreenCentered data-testid="siteInitSelection-loading">
        <CircularProgress size={80} />
      </FullScreenCentered>
    );
  }
  if ([currentSite, selected].every((x) => x?.siteName === UNKNOWN)) {
    return (
      <SiteInitSelectorStyled data-testid="siteInitSelection-select">
        <Paper elevation={3} style={{ padding: "1.5rem 1.5rem 2rem 1.5rem" }}>
          <OwcTypography variant="subtitle1" gutterBottom>
            Select your preferred location:
          </OwcTypography>
          <SwitchSite />
        </Paper>
      </SiteInitSelectorStyled>
    );
  }
  return children;
};

export default SiteInitSelector;
