import { useContext, useEffect } from "react";
import { omit } from "underscore";
import { AllWidgetsContext } from "../adminPanel/widgets/context/context";
import { fetchWidgets } from "../adminPanel/widgets/fetch-widget";

export const useWidgets = (client) => {
  const { setWidgets } = useContext(AllWidgetsContext);
  useEffect(() => {
    const fetchData = async () => {
      const { items } = await fetchWidgets({
        client,
        limit: 200,
        drillData: true
      });
      setWidgets(
        items.map((w) =>
          omit(
            {
              ...w,
              supportedSizes: w.supportedSizes.map((ss) => omit(ss, "__typename"))
            },
            "__typename"
          )
        )
      );
    };
    fetchData();
  }, [client, setWidgets]);
};
