import React from "react";
import { connect } from "react-redux";
import ApplicationGroup from "../../features/applications/ApplicationGroup";
import { filterAppsByQuerySelector, getSearchQuery } from "../../selectors";
import styled from "styled-components";
import ApplicationSlimCard from "../applications/ApplicationSlimCard";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../constants";

const SearchViewStyled = styled.div`
  & h6 {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const LoadingStyled = styled.div`
  margin-right: 15px;
  display: flex;
  align-items: center;
`;

export const SearchView = ({ selectedApps, query }) => {
  return (
    <SearchViewStyled id={generateID.UUID(Module.PAGE.APPLICATIONS.applicationSearch, `application`, "search")}>
      <ApplicationGroup
        name={`Search results for ${query}`}
        Component={ApplicationSlimCard}
        applications={selectedApps}
      />
    </SearchViewStyled>
  );
};

const mapStateToProps = (store) => ({
  selectedApps: filterAppsByQuerySelector(store),
  query: getSearchQuery(store)
});

export default connect(mapStateToProps)(SearchView);
