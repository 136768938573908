class SSOLinkStrategyManager {
  constructor() {
    this._strategies = [];

    this.addStrategy(
      new SSoLinkStrategy("internal", (link) => {
        return (
          `https://${process.env.REACT_APP_OAUTH_DOMAIN}/` +
          `authorize?client_id=${process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID}` +
          `&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${link}`
        );
      })
    );
  }

  addStrategy(strategy) {
    this._strategies = [...this._strategies, strategy];
  }

  getStrategy(type) {
    return this._strategies.find((strategy) => strategy._type === type);
  }
}

class SSoLinkStrategy {
  constructor(type, handler) {
    this._type = type;
    this._handler = handler;
  }

  getLink(link) {
    return this._handler(link);
  }
}

const manager = new SSOLinkStrategyManager();

export const resolveSSOLink = (type, link) => {
  if (!link || link === "#") {
    return link;
  }
  return manager.getStrategy(type)?.getLink(encodeURIComponent(link)) || link;
};
