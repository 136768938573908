import React, { useCallback, useReducer, useState } from "react";

export const AddOrEditContext = React.createContext({});
export const DeleteContext = React.createContext({});
export const QueryContext = React.createContext({});

const ACTION_TYPES = {
  SET_ITEM: "SET_ITEM",
  OPEN_DIALOG: "OPEN_DIALOG",
  CLOSE_DIALOG: "CLOSE_DIALOG"
};

const initialState = {
  item: null,
  isOpen: false
};

export function reducer(state, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.SET_ITEM:
      return {
        ...state,
        item: payload
      };
    case ACTION_TYPES.OPEN_DIALOG:
      return {
        ...state,
        item: payload,
        isOpen: true
      };
    case ACTION_TYPES.CLOSE_DIALOG:
      return {
        ...state,
        item: null,
        isOpen: false
      };
    default:
      return state;
  }
}

export const QueryContextWapper = ({ children }) => {
  const [query, setQuery] = useState("");
  const [orderBy, setOrderBy] = useState(["updatedAt"]);
  const [isReverseOrder, setIsReverseOrder] = useState(false);
  const onRequestSort = (key, isReverse) => {
    setIsReverseOrder(isReverse);
    setOrderBy([key]);
  };
  const filterQuery = useCallback((searchQuery = "") => {
    setQuery(searchQuery);
  }, []);
  return (
    <QueryContext.Provider
      value={{
        query,
        filterQuery,
        onRequestSort,
        orderBy,
        isReverseOrder
      }}
    >
      {children}
    </QueryContext.Provider>
  );
};

export const createDataContext =
  (Context) =>
  ({ children }) => {
    const [internalData, dispatch] = useReducer(reducer, initialState);
    const setItem = useCallback((item) => {
      dispatch({ type: ACTION_TYPES.SET_ITEM, payload: item });
    }, []);
    const close = useCallback(() => {
      dispatch({ type: ACTION_TYPES.CLOSE_DIALOG });
    }, []);
    const open = useCallback((payload) => {
      dispatch({ type: ACTION_TYPES.OPEN_DIALOG, payload });
    }, []);
    return (
      <Context.Provider
        value={{
          setItem,
          close,
          open,
          ...internalData
        }}
      >
        {children}
      </Context.Provider>
    );
  };

export const AddOrEditContextWapper = createDataContext(AddOrEditContext);
export const DeleteContextWapper = createDataContext(DeleteContext);
