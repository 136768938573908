import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { IconMenu } from "./CustomGroupedAppsList";

const CustomGroupedApps = ({
  customGroup,
  theId,
  Component,
  handleClickMenu,
  selectGroupUserInfo,
  selectCategoryUserInfo,
  anchorEl,
  menuFor,
  handleCloseMenu,
  setTitle,
  setCustomGroupToEdit,
  handleOpen,
  setCustomGroupDelete,
  openDialog,
  groupedType,
  selected
}) => {
  return (
    <React.Fragment key={`${customGroup.id}-${theId}`}>
      <Component
        key={`${customGroup.id}-${theId}`}
        data-testid="custom-group-card"
        customGroup={customGroup}
        onMoreMenu={(event) => {
          event.stopPropagation();
          handleClickMenu(event, customGroup);
        }}
        onClick={() => {
          const selectedObj = selected;
          selectedObj.id = customGroup.id;
          selectedObj.name = customGroup.name;
          selectedObj.type = "customGroup";

          switch (groupedType) {
            case "category-group":
              selectedObj.isCategoryOpen = true;
              selectCategoryUserInfo(selectedObj);
              break;
            case "main-menu-group":
              selectedObj.isMenuGroupOpen = true;
              break;
            case "quick-link-group":
              selectedObj.isQuickGroupOpen = true;
              break;
            default:
              selectedObj.isCategoryOpen = false;
              selectedObj.isMenuGroupOpen = false;
              selectedObj.isQuickGroupOpen = false;
              break;
          }

          selectGroupUserInfo(selectedObj);
        }}
      />
      <Menu
        data-testid="custom-group-card-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted={false}
        open={Boolean(anchorEl) && customGroup === menuFor}
        onClose={handleCloseMenu}
      >
        <MenuItem
          data-testid={`custom-group-card-menu-item-edit-${customGroup.id}`}
          onClick={() => {
            setTitle("Edit group");
            setCustomGroupToEdit(customGroup);
            handleOpen();
            handleCloseMenu();
          }}
        >
          <IconMenu
            className="one-icons-outlined"
            data-testid={`custom-group-card-menu-item-edit-icon-${customGroup.id}`}
          >
            {"edit"}
          </IconMenu>{" "}
          Edit
        </MenuItem>
        <MenuItem
          data-testid={`custom-group-card-menu-item-delete-${customGroup.id}`}
          onClick={() => {
            setCustomGroupDelete(customGroup);
            openDialog();
            handleCloseMenu();
          }}
        >
          <IconMenu
            deleteIcon
            className="one-icons-outlined"
            data-testid={`custom-group-card-menu-item-delete-icon-${customGroup.id}`}
          >
            {"delete"}
          </IconMenu>
          Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default CustomGroupedApps;
