import { Component } from "react";
import { Auth } from "aws-amplify";
import { CircularProgress } from "@mui/material";
import MainPageNotAuthenticated from "../views/MainPageNotAuthenticated";
import { FullScreenCentered } from "../features/landingPageUser/LandingPageUser";

// It's just for the sonar to fix major issue
const TheChildren = ({ children }) => <>{children}</>;

class Authentication extends Component {
  constructor(props) {
    super(props);
    this.getUser = this.getUser.bind(this);
  }
  state = {
    user: null,
    loading: true
  };

  getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => this.setState({ user: userData }))
      .catch(() => console.log("Not signed in"))
      .finally(() => this.setState({ loading: false }));
  }
  componentDidMount() {
    this.getUser();
  }
  render() {
    if (this.state.loading) {
      return (
        <FullScreenCentered data-testid="loader">
          <CircularProgress size={80} />
        </FullScreenCentered>
      );
    }
    if (this.state.user === null) {
      return <MainPageNotAuthenticated />;
    }
    return <TheChildren>{this.props.children}</TheChildren>;
  }
}

export default Authentication;
