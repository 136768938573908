export const emptyWidget = {
  id: null,
  description: null,
  metadataURL: null,
  name: null,
  releaseDate: null,
  version: null,
  widgetURL: "",
  fromAppName: null,
  fromAppSource: null,
  supportedSizes: [
    {
      height: null,
      mockupURL: null,
      width: null
    }
  ]
};
