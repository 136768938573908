import { Amplify } from "aws-amplify";
//TODO: remove unwant props after testing
export const configureAwsAppSync = async ({
  aws_appsync_graphqlEndpoint,
  aws_appsync_region,
  aws_appsync_authenticationType,
  Auth
}) => {
  try {
    const myAppConfig = {
      aws_appsync_graphqlEndpoint
      //   aws_appsync_region,
      //   aws_appsync_authenticationType,
      //   Auth
    };
    Amplify.configure(myAppConfig);
  } catch (error) {
    console.log("error signing out: ", error);
  }
};
