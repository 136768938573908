import React from "react";
import { Auth } from "aws-amplify";
import { useMobile } from "../utils/hooks/useMobile";
import { getEnv } from "../utils/helpers/text";
import { OwcIcon } from "@one/react/Components/OwcIcon";
import { OwcDoubleGlobalArea } from "@one/react/Components/OwcDoubleGlobalArea";
import { OwcIconButton } from "@one/react/Components/OwcIconButton";
import { OwcTypography } from "@one/react/Components/OwcTypography";
import styles from "./AppBarNotAuth.module.scss";
import { OwcButton } from "@one/react/Components/OwcButton";
import { Link } from "@mui/material";
// import ContainedButton from "../components/shared/ContainedButton";
import GRIDIcon from "../icons/GRIDIcon.svg";

const currentEnv = getEnv();
const CustomLink = ({ children, disabled, ...props }) => (
  <Link
    onClick={() => {
      if (disabled !== true) {
        if (process.env.REACT_APP_COGNITO_LOGIN_ENABLED?.trim()?.toUpperCase() !== "TRUE") {
          Auth.federatedSignIn({
            provider: process.env.REACT_APP_COGNITO_PROVIDER_ID
          });
        } else {
          Auth.federatedSignIn();
        }
      }
    }}
    style={{
      textDecoration: "none"
    }}
    {...props}
  >
    {children}
  </Link>
);

const AppBarNotAuth = ({ disabled = false }) => {
  const isMobile = useMobile();
  const infoInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return !isMobile ? (
    <OwcDoubleGlobalArea
      className={styles.appBarStyled}
      data-testid="app-bar-not-auth-main-page"
      style={currentEnv ? { top: "42px" } : {}}
    >
      <div slot="primary" style={{ justifyContent: "space-between" }}>
        <div
          style={{
            paddingLeft: "16px",
            display: "flex",
            alignItems: "center"
          }}
        >
          <img src={GRIDIcon} alt="grid-logo" style={{ width: 24, height: 24, marginRight: 15 }} />
          <OwcTypography variant="subtitle1">GRID</OwcTypography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CustomLink disabled={disabled} data-testid="link-to-sign-in-on-button" style={{ padding: "0 8px" }}>
            <OwcButton
              data-testid="sign-in-button"
              disabled={disabled}
              variant="primary"
              style={{ minWidth: "120px", marginRight: "26px" }}
            >
              Sign in
            </OwcButton>
          </CustomLink>
          <OwcIconButton
            flat
            data-testid="info-button"
            onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
            icon="circle_info"
            style={{ marginRight: "26px" }}
          />
          <OwcIcon
            type="legacy"
            style={{
              margin: "0 8px",
              color: "var(--one-color-interaction-default-brand-1)",
              width: "48px"
            }}
            name="roche_logo"
          />
        </div>
      </div>
    </OwcDoubleGlobalArea>
  ) : (
    <div data-testid="app-bar-not-auth-main-page-mobile">
      <OwcDoubleGlobalArea
        className={styles.appBarStyled}
        data-testid="app-bar-not-auth-main-page"
        style={currentEnv ? { top: "42px" } : {}}
      >
        <div slot="primary" style={{ justifyContent: "space-between" }}>
          <img src={GRIDIcon} alt="grid-logo" style={{ width: 24, height: 24, marginRight: 5 }} />
          <div style={{ display: "flex" }}>
            <CustomLink disabled={disabled} data-testid="link-to-sign-in-on-button-mobile">
              <OwcButton
                disabled={disabled}
                data-testid="sign-in-button-mobile"
                style={{ minWidth: "120px", marginRight: "26px" }}
              >
                Sign in
              </OwcButton>
            </CustomLink>
            <OwcIconButton
              flat
              data-testid="info-button"
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              icon="circle_info"
            />
          </div>
        </div>
      </OwcDoubleGlobalArea>
    </div>
  );
};

export default AppBarNotAuth;
