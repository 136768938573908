import React, { useEffect, useState, useContext, useMemo } from "react";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import ConfirmDialog from "../../../components/shared/ConfirmDialog";
import ApplicationCategoryList from "../../../components/shared/ApplicationCategoryList";
import { selectAllowedRolesFromApps } from "../../../selectors/applications";
import useDialog from "../../../utils/hooks/useDialog";
import { filterByQuery } from "../../categories/helpers";
import { loadUserInfo as loadUserInfoActions } from "../../landingPageUser/redux/actions";
import ApplicationModal from "./ApplicationModal";
import { deleteApplicationFactory } from "./helpers/deleteApplicationFactory";
import { saveApplicationFactory } from "./helpers/saveApplicationFactory";
import { metaData } from "./metaData";
import TopBarForApplication from "./TopbarForApplication";
import { SettingsContext } from "../../../contexts";
import BUTTON_MODEL_LABEL from "../../../utils/constants/buttonModelLabel";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { APPLICATION_DATA_MODEL, APPLICATION_LIST_LIMIT } from "../../../constants";
import DLabGrid from "../../../components/DLabGrid/DLabGrid";
import ApplicationAction from "./ApplicationAction";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";

const { applicationTable } = Module.PAGE.ADMIN;

const ApplicationTable = ({ data, refetch, categories, allowedRoles, client, loadUserInfo, editApplication }) => {
  const { trackEvent } = useMatomo();

  // const [application, setApplication] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [showPerPage, setShowPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [applicationToDelete, setApplicationToDelete] = useState(null);
  const { openDialog, ...dialogProps } = useDialog();
  const [filteredData, setFilteredData] = useState(data);
  const [query, setQuery] = useState("");
  const [orderBy, setOrderBy] = useState(["updatedAt"]);
  const [isReverseOrder, setIsReverseOrder] = useState(false);

  const { application, setApplication } = useContext(SettingsContext);
  // const filterData = (q) => {
  //   setFilteredData(filterByQuery(data, q, ["name"], orderBy, isReverseOrder));
  // };
  const filterData = (q) => {
    const allData = filterByQuery(data, q, ["name"], orderBy, isReverseOrder);
    setTotalCount(allData.length);
    const lowIndex = pageNo === 1 ? 0 : (pageNo - 1) * showPerPage;
    const highIndex = pageNo === 1 ? showPerPage : lowIndex + showPerPage;
    const paginationData = allData.slice(lowIndex, highIndex);
    setFilteredData(paginationData);
  };

  useEffect(() => {
    filterData(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, orderBy, isReverseOrder, pageNo, showPerPage]);

  const saveApplication = saveApplicationFactory({
    setApplication,
    client,
    loadUserInfo,
    data,
    refetch
  });

  const deleteApplication = deleteApplicationFactory({
    loadUserInfoFn: loadUserInfo,
    client,
    data,
    setApplicationToDelete,
    refetch
  });

  const handlerOnSetApp = (app) => {
    // Track click on button
    trackEvent({ category: "Add Application", action: "click-event" });
    setApplication(app);
  };

  // AG-Grid functions
  const [gridApi, setGridApi] = useState(null);
  const [rowPerPage, setRowPerPage] = useState(APPLICATION_LIST_LIMIT);
  useEffect(() => {
    if (gridApi) {
      onGridReady(gridApi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowPerPage]);

  const onGridReady = (params) => {
    setGridApi(params);
  };

  const dLabColumnDef = useMemo(() => {
    let columnFields = [];
    let columnData = APPLICATION_DATA_MODEL;
    for (const key in columnData) {
      let defaultObj = {
        field: columnData[key].key,
        headerName: columnData[key].value,
        filter: "agSetColumnFilter",
        sortable: true,
        valueFormatter: (params) => (params.value === "null" || !params.value ? "-" : params.value)
      };
      columnFields.push(defaultObj);
    }
    const actionRender = (params) => {
      return (
        <ApplicationAction editApplication={editApplication} deleteApplication={deleteApplication} params={params} />
      );
    };

    columnFields = [
      ...columnFields,
      {
        field: "actions",
        headerName: "Actions",
        maxWidth: 50,
        filter: false,
        pinned: "right",
        lockVisible: true,
        cellClass: "action-render",
        cellRenderer: actionRender
      }
    ];

    return columnFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true
    };
  }, []);

  let attr = {
    tableHeight: 400,
    rowQuickSearch: false,
    floatingFilter: false,
    suppressPaginationPanel: true,
    paginationPageSize: rowPerPage,
    columnDefs: dLabColumnDef,
    pagination: true,
    animateRows: true,
    rowSelection: "multiple",
    rowExport: false,
    suppressContextMenu: true,
    defaultToolPanel: "filters",
    hiddenByDefault: true,
    rowData: filteredData,
    onGridReady,
    setRowPerPage,
    rowPerPage,
    totalRows: filteredData?.length,
    defaultColDef
  };

  return (
    <>
      <ConfirmDialog
        {...dialogProps}
        approveText="Delete application"
        approveColor="error"
        approveVariant="contained"
        cancelText="Cancel"
        cancelVariant="outlined"
        cancelColor="primary"
        onApprove={() => deleteApplication(applicationToDelete)}
        onCancel={() => setApplicationToDelete(null)}
        title="Delete application"
        content={`Are you sure you want to remove application '${applicationToDelete?.name}'?`}
      />
      <ApplicationModal
        id={generateID.UUID(applicationTable, `applicationtable`, "table")}
        applications={data}
        open={application !== null}
        categories={categories}
        title={application?.id ? BUTTON_MODEL_LABEL.editApplication : BUTTON_MODEL_LABEL.addApplication}
        allowedRoles={allowedRoles}
        application={application}
        cancel={() => setApplication(null)}
        save={saveApplication}
      />

      <TopBarForApplication
        setQuery={setQuery}
        filterData={filterData}
        setApplication={handlerOnSetApp}
        id={generateID.UUID(applicationTable, `topbar`, "div")}
      />

      <ApplicationCategoryList
        isReverseOrder={isReverseOrder}
        orderBy={orderBy[0]}
        pageNo={pageNo}
        setPageNo={setPageNo}
        showPerPage={showPerPage}
        setShowPerPage={setShowPerPage}
        totalCount={totalCount}
        setTotalCount={setTotalCount}
        onRequestSort={(key, isReverse) => {
          setIsReverseOrder(isReverse);
          setOrderBy([key]);
        }}
        data={filteredData}
        meta={metaData({ setApplication, setApplicationToDelete, openDialog })}
        setApplication={handlerOnSetApp}
      />
      {false && (
        <div className="ag-theme-material ag-theme-DLab" style={{ height: 500, width: "100%" }}>
          <DLabGrid {...attr} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  categories: state.user.allGroups,
  allowedRoles: selectAllowedRolesFromApps(ownProps.data)
});

export default compose(connect(mapStateToProps, { loadUserInfo: loadUserInfoActions }), withApollo)(ApplicationTable);
