import React, { useContext } from "react";
import { WidgetsBarContext } from "./context/context";
import WidgetsBarHeader from "./WidgetsBarHeader";
import ListOfWidgets from "./ListOfWidgets";

const WidgetsBarMainView = () => {
  const { widgetsBarClose } = useContext(WidgetsBarContext);
  return (
    <>
      <WidgetsBarHeader widgetsBarClose={widgetsBarClose}></WidgetsBarHeader>
      <ListOfWidgets />
    </>
  );
};

export default WidgetsBarMainView;
