import React, { useContext, memo } from "react";
import Widget from "./Widget";
import { Auth } from "@aws-amplify/auth";
import { useSelector } from "react-redux";
import { getUserEmail } from "../../../selectors";
import { AllWidgetsContext } from "../../adminPanel/widgets/context/context";

export const getToken = async () => (await Auth.currentSession()).getAccessToken().getJwtToken();

const getWigetName = (widgets, id) => widgets?.find((w) => w?.id === id)?.name || "-";

const AuthenticatedWidget = memo(({ appId, params }) => {
  const email = useSelector((store) => getUserEmail(store));
  const { widgets } = useContext(AllWidgetsContext);
  return (
    <Widget appId={appId} getToken={getToken} widgetName={getWigetName(widgets, appId)} email={email} {...params} />
  );
});

export default AuthenticatedWidget;
