export function updateAllEntryPointSelectionFactory(customGroupForm, setCustomGroupForm) {
  return (application, newEntryPointIds) => {
    customGroupForm.appsWithEntryPoints = customGroupForm.appsWithEntryPoints.map((theAppWithEntryPoints) => {
      if (theAppWithEntryPoints.application === application) {
        theAppWithEntryPoints.entryPoints = newEntryPointIds;
        return { ...theAppWithEntryPoints };
      } else {
        return { ...theAppWithEntryPoints };
      }
    });
    setCustomGroupForm({ ...customGroupForm });
  };
}
