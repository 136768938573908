import { combineReducers } from "redux";
import userLandingPageReducer from "../features/landingPageUser/redux/reducer";
import searchReducer from "../features/searchTools/redux/reducer";
import equipmentReducer from "../features/equipments/redux/reducer";

const rootReducer = combineReducers({
  user: userLandingPageReducer,
  searchTools: searchReducer,
  equipments: equipmentReducer
});

export default rootReducer;
