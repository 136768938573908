import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

export const getUser = async (callback) => {
  try {
    const { username: id, ...rest } = await Auth.currentAuthenticatedUser();
    if (typeof callback === "function") {
      callback({
        id,
        ...rest
      });
    }
    return {
      id,
      ...rest
    };
  } catch (err) {
    console.error(err);
  }
};

const useAuthenticatedUser = () => {
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    getUser(setUser);
  }, []);
  return user;
};
export default useAuthenticatedUser;
