import { DEFAULT_FILTER } from "../../../constants";
const initialState = {
  id: "",
  user: "",
  email: "",
  viewType: "Grid",
  lastFavFilter: DEFAULT_FILTER,
  applicationSets: [],
  favouriteEquipments: [],
  userFilters: [],
  recentApplicationUsage: [],
  allGroups: [],
  allApps: [],
  allAdminApps: [],
  selected: {
    id: "",
    name: "",
    type: "",
    isCategoryOpen: false,
    isMenuGroupOpen: false,
    isQuickGroupOpen: false,
    accordionState: {
      adminpanel: false,
      quickAccess: false,
      dashboard: false,
      myequipments: false,
      alltools: false,
      groupcategories: false
    }
  },
  selectedCategory: [],
  selectedNotificationId: "",
  dashboard: {
    id: "",
    widgets: []
  },
  sources: [],
  NotificationBanner: {
    id: "",
    color: "",
    heading: "",
    displayText: "",
    infoURL: "",
    enabled: false
  },
  topBannerFlag: true
};

export default initialState;
