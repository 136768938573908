import React from "react";
import AppBar from "./AppBarNotAuth";
import WelcomeScreen from "../components/WelcomeScreen";
import NoConnectionScreen from "../components/NoConnectionScreen";
import { useOnlineEndpointStatus } from "../utils/hooks";

const MainPageNotAuthenticated = () => {
  const status = useOnlineEndpointStatus(process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT, 5000);
  return (
    <>
      <AppBar disabled={status ? false : true} />
      {status ? (
        <WelcomeScreen nameOfApplication={"Digital Lab"} />
      ) : (
        <NoConnectionScreen
          text={"We are having difficulty connecting to the server. Check your connection or try again later."}
          title={"No connection"}
          onCallback={() => {
            window.location.reload();
          }}
        />
      )}
    </>
  );
};
export default MainPageNotAuthenticated;
