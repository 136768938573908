import { OwcTypography } from "@one/react";
import React from "react";
import { Module } from "../../constants";
import { generateID } from "@digitallab/grid-common-components";

const { notificationName } = Module.PAGE.NOTIFICATION;

const ShortDescriptionOfNotify = ({ description, source }) => {
  return (
    <>
      <div style={{ flex: 1 }} id={generateID.UUID(notificationName, `itemofshort`, "description")}>
        <OwcTypography
          style={{ flex: 1, whiteSpace: "pre-wrap" }}
          variant="subtitle1"
          data-testid="short-derscription-notify"
          id={generateID.UUID(notificationName, `itemofshort`, "desc_subtitle")}
        >
          {description}
        </OwcTypography>
        <OwcTypography
          style={{ flex: 1, whiteSpace: "pre-wrap" }}
          variant="subtitle1"
          data-testid="short-derscription-notify"
          id={generateID.UUID(notificationName, `itemofshort`, "desc_prewrap")}
        >
          {source}
        </OwcTypography>
      </div>
    </>
  );
};

export default ShortDescriptionOfNotify;
