import { get } from "underscore";
import { DetailsCellStyled } from "./DetailsCellStyled";
import { DetailsCellsWrapperStyled } from "./DetailsCellsWrapperStyled";

const DetailsCell = ({ label, children, Wrapper, ...props }) => (
  <Wrapper {...props}>
    <div>{label}</div>
    <div>
      <strong>{children}</strong>
    </div>
  </Wrapper>
);

const DetailsCells = ({ item, infoMeta, Wrapper = DetailsCellsWrapperStyled, CellWrapper = DetailsCellStyled }) => {
  return (
    <Wrapper data-testid="details-cells-wrapper">
      {infoMeta.fields.map((field) => {
        let body = get(item, field.field) || "-";
        if (field.component) {
          const Component = field.component;

          body = <Component item={item} />;
        }

        return (
          <DetailsCell Wrapper={CellWrapper} label={field.label} {...(field.props ?? {})}>
            {body}
          </DetailsCell>
        );
      })}
    </Wrapper>
  );
};

export default DetailsCells;
