import React from "react";
import { AppItem, ApplicationIcon, AppRow } from "./customGroupModalDialogContentStyleds";
import CustomGroupModalDialogContentFormControlForEntryPoints from "./CustomGroupModalDialogContentFormControlForEntryPoints";
import { OwcCheckbox, OwcIcon, OwcTypography } from "@one/react";
import { useRef } from "react";

const CustomGroupModalDialogContentAppListToChoose = ({
  filterdApp,
  updateAppSelection,
  isAppSelected,
  classes,
  getAppWithEntryPoints,
  updateAllEntryPointsSelection,
  deleteEntryPointSelection
}) => {
  const checkboxElement = useRef(null);
  return filterdApp.map((application) => (
    <>
      <AppRow data-testid="custom-group-modal-app-row">
        <AppItem
          data-testid="custom-group-modal-app-item"
          key={application.id}
          onClick={() => {
            updateAppSelection(!isAppSelected(application), application);
          }}
          isSelected={isAppSelected(application)}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <ApplicationIcon data-testid="custom-group-modal-app-item-icon">
              <OwcIcon
                style={{
                  color: " var(--one-color-blue-900)",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {application?.icon}
              </OwcIcon>
            </ApplicationIcon>
            <OwcTypography variant="button" data-testid="custom-group-modal-app-item-label">
              {application?.name}
            </OwcTypography>
          </div>
          <OwcCheckbox
            ref={checkboxElement}
            data-testid="custom-group-modal-app-item-checkbox"
            checked={isAppSelected(application)}
            onInputKeyDown={(event) => {
              updateAppSelection(event.target.checked, application);
            }}
          />
        </AppItem>
        {isAppSelected(application) && (
          <CustomGroupModalDialogContentFormControlForEntryPoints
            classes={classes}
            application={application}
            getAppWithEntryPoints={getAppWithEntryPoints}
            updateAllEntryPointsSelection={updateAllEntryPointsSelection}
            deleteEntryPointSelection={deleteEntryPointSelection}
          />
        )}
      </AppRow>
    </>
  ));
};

export default CustomGroupModalDialogContentAppListToChoose;
