import PubSub from "pubsub-js";
import { RESOLVE_LINK } from "./EventTypes";
import { resolveLinkHandler } from "./resolveLinkHandler";

function initializeSubscriptions() {
  this.addEvent(RESOLVE_LINK, resolveLinkHandler);
}

class EventsResolver {
  constructor() {
    this._tokens = [];
    initializeSubscriptions.bind(this)();
  }
  addEvent(eventType, eventHandler) {
    const token = PubSub.subscribe(eventType, eventHandler);
    this._tokens = [...this._tokens, token];
    return this;
  }
  clearAllSubscriptions() {
    PubSub.clearAllSubscriptions();
  }
}

export const eventManager = new EventsResolver();
