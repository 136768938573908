import React from "react";
import { OwcButton } from "@one/react";

const CustomGroupModalDialogActions = ({ onCancel, save, customGroupForm, hasError }) => {
  return (
    <div style={{ display: "flex", marginLeft: "auto" }}>
      <OwcButton
        data-testid="custom-group-modal-action-button-cancel"
        variant="secondary"
        onClick={onCancel}
        style={{ marginRight: 15 }}
      >
        Cancel
      </OwcButton>
      <OwcButton
        data-testid="custom-group-modal-action-button-confirm"
        autoFocus
        onClick={() => {
          save(customGroupForm);
        }}
        variant="primary"
        disabled={hasError}
      >
        {customGroupForm.id ? "Save group" : "Create new group"}
      </OwcButton>
    </div>
  );
};
export default CustomGroupModalDialogActions;
