import React from "react";
import styled, { css } from "styled-components";
import { useMobile } from "../../utils/hooks";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { generateID } from "@digitallab/grid-common-components";
import { EMPTY_FAVORITE, Module } from "../../constants";
import { OwcIcon, OwcTypography } from "@one/react";
import EmptyFavorite from "../../icons/EmptyFavorite";
import { NoFavoriteContainer } from "./ApplicationListStyle";
import { useHistory } from "react-router-dom";

const listCardChildrenMobile = css`
  flex: 0 1 159px;
  margin-right: 6px;
`;
const ListCardStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  & > * {
    margin-bottom: 20px;
    margin-right: 20px;
    flex: 0 1 184px;
    ${(props) => props.isMobile && listCardChildrenMobile}
  }
  @media only screen and (max-width: 415px) {
    justify-content: space-between;
  }
`;
export const NotFoundParagraphStyled = styled.p`
  margin: 1rem;
  display: flex;
  justify-content: center;
`;

const ApplicationList = ({ applications = [], Component, noRecordMessage = "No applications found", type = null }) => {
  const isMobile = useMobile();
  const history = useHistory();

  // Track page view
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView({
      documentTitle: "Application List",
      href: `${window.location?.origin}/Application`
    });
    // eslint-disable-next-line
  }, []);

  if (Array.isArray(applications) && !applications.length) {
    return (
      <NotFoundParagraphStyled
        data-testid="not-found-apps"
        id={generateID.UUID(Module.PAGE.APPLICATIONS.applicationList, `application_empty`, "div")}
      >
        {type === EMPTY_FAVORITE ? (
          <NoFavoriteContainer
            onClick={() => {
              history.replace("/home/application");
            }}
          >
            <OwcIcon className="emptyIconMyLab">
              <EmptyFavorite />
            </OwcIcon>
            <OwcTypography variant="title6" id={generateID.UUID(Module.PAGE.MYLAB.title, `noFavorite`, "type_title")}>
              No favorites defined
            </OwcTypography>
            <OwcTypography
              variant="body2"
              id={generateID.UUID(Module.PAGE.MYLAB.title, `noFavorite`, "type_Description")}
            >
              Please define your favorite apps under
              <span
                style={{
                  cursor: "pointer",
                  color: "var(--one-color-interaction-active-brand-1)",
                  marginLeft: "5px"
                }}
                id={generateID.UUID(Module.PAGE.MYLAB.title, `noFavorite`, "type_Description")}
              >
                Applications
              </span>
            </OwcTypography>
          </NoFavoriteContainer>
        ) : (
          <OwcTypography
            variant="title6"
            id={generateID.UUID(Module.PAGE.MYLAB.title, `noRecentlyAccesed`, "type_title")}
          >
            {noRecordMessage}
          </OwcTypography>
        )}
      </NotFoundParagraphStyled>
    );
  }
  return (
    <ListCardStyled
      isMobile={isMobile}
      id={generateID.UUID(Module.PAGE.APPLICATIONS.applicationList, `application`, "list")}
    >
      {applications.map((app, id) => (
        <Component
          key={`${app.id}-${id}`}
          application={app}
          id={generateID.UUID(Module.PAGE.APPLICATIONS.name, `application_${app.id}`, "group")}
        />
      ))}
    </ListCardStyled>
  );
};

export default ApplicationList;
