import React, { useState, useCallback } from "react";
import { TogglerContext } from "../../contexts";

const TogglerContextWrapper = ({ children, initialState }) => {
  const [value, setToggle] = useState(initialState);
  const toggle = useCallback(() => {
    setToggle((val) => !val);
  }, []);
  const [mobileOpen, setMobileOpen] = useState(false);
  const mobileToggle = useCallback(() => {
    setMobileOpen((val) => !val);
  }, []);
  return (
    <TogglerContext.Provider
      value={{
        value,
        toggle,
        mobileOpen,
        mobileToggle
      }}
    >
      {children}
    </TogglerContext.Provider>
  );
};

export default TogglerContextWrapper;
