export const getMetadata = async (url) => {
  try {
    const response = await fetch(url);
    const text = await response.text();
    return {
      metadata: JSON.parse(text)
    };
  } catch (error) {
    return {
      error
    };
  }
};
