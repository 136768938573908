import React, { useState } from "react";
import { SearchToolContext } from "./context";
const SearchToolWrapper = ({ children }) => {
  const [searchAppQuery, setSearchAppQuery] = useState("");

  return (
    <SearchToolContext.Provider
      value={{
        searchAppQuery,
        setSearchAppQuery
      }}
    >
      {children}
    </SearchToolContext.Provider>
  );
};

export default SearchToolWrapper;
