import gql from "graphql-tag";

export const ON_CREATE_DIGITAL_LAB_LANDING_PAGE_NOTIFICATION = gql`
  subscription onCreateDigitalLabLandingPageNotification($user: String) {
    onCreateDigitalLabLandingPageNotification(user: $user) {
      createdAt
      details
      dueDate
      expiresAt
      id
      message
      link
      time
      payload
      sender
      severity
      source
      type
      updatedAt
      user
    }
  }
`;

export const ON_UPDATE_DIGITAL_LAB_LANDING_PAGE_NOTIFICATION = gql`
  subscription onUpdateDigitalLabLandingPageNotification($user: String) {
    onUpdateDigitalLabLandingPageNotification(user: $user) {
      createdAt
      details
      dueDate
      expiresAt
      id
      message
      link
      time
      payload
      sender
      severity
      source
      type
      updatedAt
      user
    }
  }
`;

export const ON_DELETE_DIGITAL_LAB_LANDING_PAGE_NOTIFICATION = gql`
  subscription onDeleteDigitalLabLandingPageNotification($user: String) {
    onDeleteDigitalLabLandingPageNotification(user: $user) {
      id
      type
      severity
      time
      source
      message
      details
      payload
      link
      expiresAt
      dueDate
      updatedAt
      user
      createdAt
      sender
    }
  }
`;

export const ON_UPDATE_LANDING_PAGE_NOTIFICATION_COMMAND = gql`
  subscription onUpdateLandingPageNotificationCommand {
    onUpdateLandingPageNotificationCommand {
      status
      id
    }
  }
`;
export const ON_CREATE_NOTIFICATION_BANNER_COMMAND = gql`
  subscription onCreateNotificationBanner {
    onCreateNotificationBanner {
      color
      displayText
      enabled
      heading
      id
      infoURl
    }
  }
`;
export const ON_UPDATE_NOTIFICATION_BANNER_COMMAND = gql`
  subscription onUpdateNotificationBanner {
    onUpdateNotificationBanner {
      color
      displayText
      enabled
      heading
      id
      infoURl
    }
  }
`;
