import * as types from "./actionTypes";
import initialState from "./initialState";

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.SET_SEARCH_QUERY:
      return { ...state, searchQuery: payload };
    case types.RESET_SEARCH_QUERY:
      return { ...state, searchQuery: "", reset: state.reset + 1 };
    default:
      return state;
  }
};

export default reducer;
