import React, { useState, useReducer } from "react";
import { FiltersContext } from "./context";

const initialState = {
  fetching: false,
  groupBySeverity: true,
  severity: "",
  application: "",
  startDate: null,
  endDate: null,
  orderBy: "time",
  isReverseOrder: false,
  nextToken: null,
  nextData: 0
};

const handleNextToken = (state, action) => {
  return {
    ...state,
    fetching: false,
    nextToken: action.payload
  };
};

const handleNextData = (state) => {
  return {
    ...state,
    nextData: state.nextData + 1
  };
};

const handleOrderAndReverse = (state, action) => {
  return {
    ...state,
    nextToken: null,
    nextData: 1,
    orderBy: action.payload?.order,
    isReverseOrder: action.payload?.reverse
  };
};

const handleKeyChange = (state, action) => {
  return {
    ...state,
    nextToken: null,
    nextData: 1,
    [action.payload.key]: action.payload.value
  };
};

const handleGroupBySeverity = (state) => {
  return {
    ...state,
    nextToken: null,
    nextData: 1,
    groupBySeverity: !state.groupBySeverity
  };
};

const handleFetching = (state, action) => {
  return {
    ...state,
    fetching: action.payload
  };
};

const handleClearAll = {
  ...initialState,
  nextData: 1
};
function reducer(state = initialState, action = { type: "", payload: "" }) {
  switch (action.type) {
    case "fetching":
      return handleFetching(state, action);
    case "groupBySeverity":
      return handleGroupBySeverity(state);
    case "keyChange":
      return handleKeyChange(state, action);
    case "orderAndReverse":
      return handleOrderAndReverse(state, action);
    case "nextData":
      return handleNextData(state);
    case "nextToken":
      return handleNextToken(state, action);
    case "clearAll":
      return handleClearAll;
    default:
      return state;
  }
}

const FiltersWrapper = ({ children }) => {
  const [state, dispatchFiltersAction] = useReducer(reducer, initialState);
  const [selected, setSelected] = useState([]);

  const isSelected = (item) => selected.some((theItem) => theItem.id === item.id);
  return (
    <FiltersContext.Provider
      value={{
        ...state,
        selected,
        setSelected,
        isSelected,
        dispatchFiltersAction
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersWrapper;
