const initialState = {
  allEquipments: [],
  selectedEquipments: [],
  pagination: {
    limit: 5,
    nextToken: null,
    tokenList: []
  }
};

export default initialState;
