import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { OwcTypography } from "@one/react";
import { Module } from "../../constants";
import { generateID } from "@digitallab/grid-common-components";

const { notificationName } = Module.PAGE.NOTIFICATION;
const FullDateBox = styled.div`
  display: flex;
`;
const DateBox = styled.div``;
const TimeBox = styled.div``;

const DateOfNotify = ({ date }) => {
  const theDate = new Date(date);
  return (
    <FullDateBox id={generateID.UUID(notificationName, `full_date_box`, "box")} data-testid="full-date-of-notify">
      <DateBox
        id={generateID.UUID(notificationName, `full_date_box`, "datebox")}
        data-testid="date-of-notify"
        style={{ marginRight: "10px" }}
      >
        <OwcTypography variant="subtitle1" id={generateID.UUID(notificationName, `full_date_box`, "type_subtitle")}>
          {format(theDate, "dd-MMM-yyyy")}{" "}
        </OwcTypography>
      </DateBox>
      <TimeBox data-testid="time-of-notify" id={generateID.UUID(notificationName, `full_date_box`, "timebox")}>
        <OwcTypography variant="subtitle1" id={generateID.UUID(notificationName, `full_date_box`, "timebox_subtitle")}>
          {" "}
          {format(theDate, "h:mm aaa")}
        </OwcTypography>
      </TimeBox>
    </FullDateBox>
  );
};

export default DateOfNotify;
