import { updateItem } from "../../../utils/store/crud";
import * as types from "./actionTypes";
import initialState from "./initialState";

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.LOAD_EQUIPMENT:
      return {
        ...state,
        allEquipments: [...payload]
      };
    case types.UPDATE_EQUIPMENT_APP:
      return {
        ...state,
        allEquipments: updateItem(state.allEquipments, payload)
      };
    case types.UPDATE_SELECTED_EQUIPMENT:
      return {
        ...state,
        selectedEquipments: [{ ...payload }]
      };
    case types.UPDATE_PAGINATION:
      return {
        ...state,
        ...payload
      };

    default:
      return state;
  }
};

export default reducer;
