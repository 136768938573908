const DATA_MODEL_TABLE = {
  applicationName: {
    key: "name",
    value: "Application name"
  },
  updatedAt: {
    key: "updatedAt",
    value: "Last update"
  },
  categoryName: {
    key: "name",
    value: "Category"
  },
  widgetName: {
    key: "name",
    value: "Name"
  },
  fromApp: {
    key: "fromApp",
    value: "From App"
  },
  widgetVersion: {
    key: "version",
    value: "Version"
  },
  releaseDate: {
    key: "releaseDate",
    value: "Release date"
  },
  widgetDescription: {
    key: "description",
    value: "Description"
  },
  actions: {
    key: "actions",
    value: "Actions"
  },
  equipmentModel: {
    key: "equipmentModel",
    value: "Model"
  },
  equipmentNickName: {
    key: "equipmentNickName",
    value: "Nickname"
  },
  serialNumber: {
    key: "serialNumber",
    value: "Serial number"
  },
  equipmentId: {
    key: "equipmentId",
    value: "Equipment ID"
  },
  location: {
    key: "location",
    value: "Location"
  },
  softwareVersion: {
    key: "softwareVersion",
    value: "Software version"
  },
  belongingToGroup: {
    key: "belongingToGroup",
    value: "Group"
  }
};

export default DATA_MODEL_TABLE;
