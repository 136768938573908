import styled from "styled-components";

export const LaunchStepNameRow = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--one-color-gray-800);
  margin: 2px 0;
`;
export const LaunchStepLinkRow = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--one-color-blue-900);
  margin: 2px 0;
`;
export const LaunchStepInfoRow = styled.div`
  padding: 0.75rem 0;
  padding-left: 16px;
`;
export const LaunchStepActionRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const LaunchStepRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LaunchStepListStyled = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

export const InputModal = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0px;
  color: var(--one-color-gray-600);
  opacity: 1;
  display: flex;
  margin-bottom: 1.25rem;

  @media only screen and (max-width: 960px) {
    &:last-child {
      margin-top: 1rem;
    }
  }
`;

// export const useStylesForApplicationForm = makeStyles((theme) => ({
//   root: {
//     "& > *": {
//       margin: theme.spacing(1),
//       width: "25ch"
//     }
//   },
//   chips: {
//     display: "flex",
//     flexWrap: "wrap",
//     marginLeft: -2,
//     minHeight: 36
//   },
//   chip: {
//     margin: 2,
//     fontSize: 14,
//     lineHeight: 16,
//     borderColor: "var(--one-color-gray-400)",
//     color: "var(--one-color-gray-800)",
//     fontWeight: 400
//   },
//   chipDeleteIcon: {
//     width: 14,
//     height: 14,
//     color: "var(--one-color-gray-600)"
//   },
//   multiSelectUnderline: {
//     "&:before": {
//       borderBottomWidth: 1,
//       borderBottomColor: "#757575"
//     },
//     "&&:hover:before": {
//       borderBottomWidth: 1
//     }
//   },
//   multiSelectInput: {
//     "label + &": {
//       marginTop: 0
//     },
//     '&[class*="Mui-focused"] $multiSelectSelect': {
//       background: "rgba(0, 0, 0, 0.09)"
//     }
//   },
//   multiSelectSelect: {
//     paddingTop: theme.spacing(2) + 6,
//     paddingLeft: theme.spacing(2),
//     background: "#f5f5f5"
//   },
//   autocompleteEndAdornment: {
//     right: 7
//   },
//   autocompleteInput: {
//     paddingTop: theme.spacing(2) + 6,
//     paddingLeft: theme.spacing(2),
//     '&[class*="MuiAutocomplete-inputRoot"]': {
//       paddingBottom: 7
//     },
//     background: "#f5f5f5",
//     "label + &": {
//       marginTop: 0
//     },
//     '&[class*="Mui-focused"]': {
//       background: "rgba(0, 0, 0, 0.09)"
//     }
//   },
//   autocompleteInputInput: {
//     minHeight: 26
//   },
//   multiSelectLabelShrink: {
//     "&$multiSelectLabelFilled": {
//       transform: "translate(16px, 6.8px) scale(0.75)"
//     }
//   },
//   multiSelectLabelFilled: {},
//   onlyIconButtonOutlined: {
//     padding: 5,
//     minWidth: 48,
//     marginLeft: theme.spacing(1.5)
//   },
//   onlyIconButtonOutlinedDefault: {
//     padding: 5,
//     minWidth: 48,
//     marginLeft: theme.spacing(1.5),
//     color: "var(--one-color-gray-600)"
//   }
// }));
