import React, { useContext } from "react";
import { AddOrEditContext } from "./contexts";
import BUTTON_MODEL_LABEL from "../../utils/constants/buttonModelLabel";
import { OwcButton, OwcIconButton } from "@one/react";
import { Tooltip } from "@mui/material";
import { Module } from "../../constants";
import { generateID } from "@digitallab/grid-common-components";

const { appAndCategories } = Module.PAGE.ADMIN;

const AddOrEdit = ({ item }) => {
  const { open: openDialog } = useContext(AddOrEditContext);
  return (
    <>
      {item ? (
        <Tooltip
          data-testid="category-edit-button-tooltip"
          title={`Edit category`}
          placement="left"
          arrow
          style={{ color: " var(--one-color-cobas-white)" }}
          id={generateID.UUID(appAndCategories, `category`, "tooltip")}
        >
          <OwcIconButton
            flat
            type="outlined"
            icon="edit"
            aria-label={"Edit"}
            onClick={() => openDialog(item)}
            data-testid={`category-remove-button-${item?.id}`}
            id={generateID.UUID(appAndCategories, `category_${item?.id}`, "icon_button")}
          />
        </Tooltip>
      ) : (
        <OwcButton
          aria-label={"Add"}
          onClick={() => openDialog(null)}
          data-testid="add-category-dialog"
          id={generateID.UUID(appAndCategories, `category`, "add_dialog_button")}
        >
          {BUTTON_MODEL_LABEL.addCategory}
        </OwcButton>
      )}
    </>
  );
};

export default AddOrEdit;
