import { OwcIcon } from "@one/react";
import React from "react";
import styled, { css } from "styled-components";
import { Module } from "../../constants";
import { ModalInput } from "../applications/customGroupModalDialogContentStyleds";
import { generateID } from "@digitallab/grid-common-components";

const inputMobile = css`
  min-width: 360px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  .rowName {
    display: flex;
    padding: 16px 0;
    align-items: flex-start;
  }
  & .categoryIcon {
    background-color: #e8e8e8;
    margin-right: 16px;
    line-height: 1;
    font-size: 48px;
    padding: 10px;
  }
  & .nameCategory {
    ${(props) => !props.isMobile && inputMobile}
  }
`;

const { appAndCategories } = Module.PAGE.ADMIN;

const CategoryNameAndIconInputsForDialog = ({
  isMobile,
  name,
  errors,
  dirtyInputs,
  setNameAndSetDirtyInputs,
  setName
}) => {
  return (
    // <StyledContent isMobile={isMobile}>
    <div id={generateID.UUID(appAndCategories, `categoryNameIcon`, "div")} style={{ margin: "48px 0 66px 0" }}>
      <ModalInput
        maxLength="30"
        id={generateID.UUID(appAndCategories, `categoryNameIcon`, "modal_input")}
        data-testid="name-category"
        label="Category name *"
        isMobile={isMobile}
        color="primary"
        variant="filled"
        size="medium"
        className="nameCategory"
        value={name}
        onInputChange={setNameAndSetDirtyInputs}
        required
        error={!!errors?.name && dirtyInputs.name}
        FormHelperTextProps={{
          "data-testid": "name-category-helper-text"
        }}
      >
        {name && (
          <OwcIcon
            slot="suffix"
            type="filled"
            name="circle_clear"
            onClick={() => setName("")}
            className="pointer"
            id={generateID.UUID(appAndCategories, `categoryNameIcon`, "icon_circle")}
          />
        )}
        <label slot="error-text">{dirtyInputs.name && errors?.name}</label>
      </ModalInput>
    </div>
    // </StyledContent>
  );
};
export default CategoryNameAndIconInputsForDialog;
