import React, { useState } from "react";
import { OwcIconButton, OwcListItem } from "@one/react";
import { Menu } from "@mui/material";

const EquipmentAction = ({ params, editApplication, deleteApplication }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSettingClose = () => {
    setAnchorEl(null);
  };
  const handleSettingClick = (event, param) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <OwcIconButton
        icon="more_vertical"
        type="legacy"
        flat
        onClick={(event) => {
          handleSettingClick(event, params);
        }}
      />
      <Menu
        data-testid="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleSettingClose}
      >
        <OwcListItem
          icon="edit"
          type="legacy"
          data-testid="simple-menu-filter"
          onClick={() => {
            setAnchorEl(null);
            editApplication({
              id: params?.data?.id[0]
            });
          }}
          noBorder
        >
          Edit
        </OwcListItem>
        <OwcListItem
          icon="delete"
          type="legacy"
          data-testid="simple-menu-filter"
          onClick={() => {
            setAnchorEl(null);
            deleteApplication({
              id: params?.data?.id[0]
            });
          }}
          noBorder
        >
          Delete
        </OwcListItem>
      </Menu>
    </>
  );
};

export default EquipmentAction;
