import React from "react";
import { Query } from "react-apollo";
import { get } from "lodash";

export const withData =
  ({ queryGQL, dataPath }) =>
  (Component) =>
  (props) => {
    return (
      <Query query={queryGQL} fetchPolicy="cache-and-network">
        {({ data }) => {
          const _data = get(data, dataPath);
          return <Component {...props} data={_data} />;
        }}
      </Query>
    );
  };
