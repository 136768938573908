import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { selectNotificationUserInfo as selectNotificationUserInfoActions } from "../../landingPageUser/redux/actions";
import { OwcButton } from "@one/react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FooterBox = styled.div`
  padding: 10px 12px 12px;
  display: flex;
  justify-content: flex-end;
`;

const FooterPopoverNotify = ({ selectNotificationUserInfo, isData, handleCloseNotify }) => {
  //const { handleCloseNotify } = useContext(ShortViewOfNotifyContext);
  const history = useHistory();

  return (
    <FooterBox style={{ display: "flex" }} data-testid="short-notification-list-footer-section">
      {isData && (
        <OwcButton
          variant="secondary"
          data-testid="short-notification-list-footer-section-button-see-all"
          onClick={() => {
            handleCloseNotify();
            history.replace("/notifications");
          }}
        >
          View all
        </OwcButton>
      )}
    </FooterBox>
  );
};
export default connect(null, {
  selectNotificationUserInfo: selectNotificationUserInfoActions
})(FooterPopoverNotify);
