import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import searchMiddleware from "../features/searchTools/redux/middlewares";

const middlewares = [thunk, searchMiddleware];

const configureStore = (initialState) => {
  return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));
};

export default configureStore;
