import React, { useState } from "react";
import { ShortViewOfNotifyContext } from "./context";

const ShortViewOfNotifyWrapper = ({ children }) => {
  const [shortViewListOpen, setShortViewListOpen] = useState(false);
  const [anchorElNotify, setAnchorElNotify] = React.useState(null);
  const handleClickNotify = (event) => {
    setAnchorElNotify(event.currentTarget);
    setShortViewListOpen(event.currentTarget);
  };

  const handleCloseNotify = () => {
    setAnchorElNotify(null);
    setShortViewListOpen(false);
  };

  return (
    <ShortViewOfNotifyContext.Provider
      value={{
        shortViewListOpen,
        anchorElNotify,
        handleClickNotify,
        handleCloseNotify
      }}
    >
      {children}
    </ShortViewOfNotifyContext.Provider>
  );
};

export default ShortViewOfNotifyWrapper;
