import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as actionsUser from "../../landingPageUser/redux/actions";
import * as actionsUserTypes from "../../landingPageUser/redux/actionTypes";

const middlewares = (store) => (next) => (action) => {
  if (
    action.type === actionTypes.SET_SEARCH_QUERY &&
    store.getState().searchTools.searchQuery === "" &&
    action.payload !== ""
  ) {
    store.dispatch(
      actionsUser.selectGroupUserInfo({
        id: "",
        name: "All tools",
        type: "allApplications"
      })
    );
  }
  if (action.type === actionsUserTypes.SELECT_GROUP_USER_INFO && store.getState().searchTools.searchQuery !== "") {
    store.dispatch(actions.resetSearchQuery());
  }
  next(action);
};
export default middlewares;
