import { OwcIconButton, OwcTypography } from "@one/react";
import React from "react";
import styles from "./EquipmentTitle.module.scss";
const EquipmentTitleBar = () => {
  return (
    <div className={styles.equipmentpageTitleIcon}>
      <OwcTypography variant="button">My lab</OwcTypography>
      <OwcIconButton flat type="outlined" icon="more_vertical" />
    </div>
  );
};

export default EquipmentTitleBar;
