import { CircularProgress } from "@mui/material";
import React from "react";
import { FullScreenCentered } from "../../features/landingPageUser/LandingPageUser";
import { OwcTypography } from "@one/react/Components/OwcTypography";
import { OwcButton } from "@one/react";

const AppLoadState = ({ children, loading, error, onRetry, onLogout }) => {
  if (loading) {
    return (
      <FullScreenCentered>
        <CircularProgress data-testid="app-loading" size={80} />
      </FullScreenCentered>
    );
  }
  if (error) {
    return (
      <FullScreenCentered>
        <OwcTypography variant="subtitle1" data-testid="app-error" gutterBottom>
          {error?.message ? error?.message : "Unexpected error has occurred"}
        </OwcTypography>
        {onRetry && (
          <OwcButton variant="primary" onClick={() => onRetry()} color="secondary" data-testid="try-again-button">
            Try again
          </OwcButton>
        )}
        <OwcButton
          data-testid="signout-button"
          variant="primary"
          style={{ marginTop: ".7rem" }}
          onClick={() => onLogout()}
        >
          Sign out
        </OwcButton>
      </FullScreenCentered>
    );
  }
  return children;
};

export default AppLoadState;
