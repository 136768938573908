import { SEVERITY_NOTIFICATIONS } from "../../SeverityNotifications";
import * as R from "ramda";
import { SEVERITY_MAP } from "../systemNotification/SystemNotificationsWrapper";
const initialSeverityMap = {
  [SEVERITY_NOTIFICATIONS.HIGH]: 0,
  [SEVERITY_NOTIFICATIONS.MEDIUM]: 0,
  [SEVERITY_NOTIFICATIONS.LOW]: 0
};

export const groupByKey = (
  data,
  byKey = "severity",
  groupKeys = [SEVERITY_NOTIFICATIONS.HIGH, SEVERITY_NOTIFICATIONS.MEDIUM],
  initialMap = initialSeverityMap,
  defaultKey = SEVERITY_NOTIFICATIONS.LOW
) => {
  if (!Array.isArray(data)) {
    return initialMap;
  }
  if (!Array.isArray(groupKeys)) {
    return initialMap;
  }
  return data.reduce(
    (acc, curr) => {
      const value = curr?.[byKey]?.toLowerCase();
      return {
        ...acc,
        ...(groupKeys?.includes(value)
          ? { [value]: ++acc[value] }
          : { [SEVERITY_NOTIFICATIONS.LOW]: ++acc[defaultKey] })
      };
    },
    { ...initialMap }
  );
};

export const getSeverityCount = (severityMap) =>
  severityMap?.[SEVERITY_NOTIFICATIONS.HIGH] ||
  severityMap?.[SEVERITY_NOTIFICATIONS.MEDIUM] ||
  severityMap?.[SEVERITY_NOTIFICATIONS.LOW];

export const getLatestNotification = R.compose(
  R.take(1),
  R.sortWith([R.descend(R.prop("severityNum")), R.descend(R.prop("createdAt"))]),
  R.map((x) => ({
    ...x,
    severityNum: SEVERITY_MAP?.[x?.severity?.trim().toUpperCase()]?.num || 0
  }))
);
