import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Slide } from "@mui/material";
import { OwcButton } from "@one/react";
import React from "react";
import styled from "styled-components";
import { Module } from "../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const { adminWidget } = Module.PAGE.ADMIN;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} id={generateID.UUID(adminWidget, `transistion`, "slide")} />;
});

// const CustomDialogTitle = withStyles({
//   root: {
//     display: "flex",
//     padding: "21px 16px",
//     justifyContent: "space-between",
//     alignItems: "center",
//     width: "100%"
//   }
// })(DialogTitle);
// const CustomDialogContent = withStyles({
//   root: {
//     padding: 16,
//     overflow: "overlay"
//   }
// })(DialogContent);

// const CustomDialogActions = withStyles({
//   root: {
//     padding: 16,
//     display: "flex",
//     justifyContent: "space-between"
//   }
// })(DialogActions);

const BasisTitle = styled.span`
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
`;
const Title = styled(BasisTitle)`
  font-weight: 500;
`;

const WidgetDialogForm = ({
  mainTitle,
  countTitle,
  approveButtonTitle,
  onCancel,
  onApprove,
  disabled,
  open,
  dataTestId,
  loading,
  children
}) => {
  return (
    <Dialog
      data-testid={`widget-dialog-form-${dataTestId}`}
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      id={generateID.UUID(adminWidget, `widget_dialog_form`, "dialog")}
      style={{ zIndex: 10001 }}
    >
      <DialogTitle
        disableTypography
        id={generateID.UUID(adminWidget, `widget_dialog_form`, "title")}
        data-testid={`widget-dialog-form-${dataTestId}-title`}
      >
        <Title
          id={generateID.UUID(adminWidget, `widget_dialog_form`, "title_value")}
          data-testid={`widget-dialog-form-${dataTestId}-title-value`}
        >
          {mainTitle}
        </Title>
        <BasisTitle
          id={generateID.UUID(adminWidget, `widget_dialog_form`, "basis_title")}
          data-testid={`widget-dialog-form-${dataTestId}-title-value`}
        >
          {countTitle}
        </BasisTitle>
      </DialogTitle>
      <Divider />
      <DialogContent
        id={generateID.UUID(adminWidget, `widget_dialog_form`, "dialog_content")}
        data-testid={`widget-dialog-form-${dataTestId}-content`}
      >
        {children}
      </DialogContent>
      <DialogActions
        id={generateID.UUID(adminWidget, `widget_dialog_form`, "dialog_action")}
        data-testid={`widget-dialog-form-${dataTestId}-actions`}
      >
        <OwcButton
          variant="secondary"
          id={generateID.UUID(adminWidget, `widget_dialog_form`, "dialog_cancel_button")}
          data-testid={`widget-dialog-form-${dataTestId}-actions-cancel-button`}
          onClick={onCancel}
        >
          Cancel
        </OwcButton>
        <OwcButton
          onClick={onApprove}
          disabled={disabled || loading}
          id={generateID.UUID(adminWidget, `widget_dialog_form`, "dialog_approve_button")}
          data-testid={`widget-dialog-form-${dataTestId}-actions-approve-button`}
        >
          {loading && (
            <CircularProgress
              size={20}
              color="primary"
              id={generateID.UUID(adminWidget, `widget_dialog_form`, "dialog_circlular")}
              data-testid={`widget-dialog-form-${dataTestId}-actions-approve-button-circular-progress`}
            />
          )}
          {approveButtonTitle}
        </OwcButton>
      </DialogActions>
    </Dialog>
  );
};

export default WidgetDialogForm;
