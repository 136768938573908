import React from "react";
import { useHistory } from "react-router-dom";

const IndexPage = () => {
  const history = useHistory();
  history.replace("/home/quick-access");
  return <></>;
};

export default IndexPage;
