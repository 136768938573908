import React, { useState } from "react";
import Categories from "../../categories/Categories";
import ApplicationsAdmin from "./ApplicationsAdmin";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { OwcTab, OwcTabs } from "@one/react";
import styles from "./AppAndCategoriesTab.module.scss";

const AppAndCategoriesTab = () => {
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView({
      documentTitle: "Admin/AppAndCategories",
      href: `${window.location?.origin}/Admin/AppAndCategories`
    });
    // eslint-disable-next-line
  }, []);
  const [value, setValue] = useState("applications");
  const handleChange = (e) => {
    setValue(e.detail);
  };
  return (
    <div className={styles.appandcategoriestabContainer}>
      <OwcTabs value={value} onTabChange={handleChange}>
        <OwcTab value="applications" label="Applications">
          Applications
        </OwcTab>
        <OwcTab value="categories" label="Categories">
          Categories
        </OwcTab>
      </OwcTabs>
      {value === "applications" && <ApplicationsAdmin />}
      {value === "categories" && <Categories id={"categories"} />}
    </div>
  );
};

export default AppAndCategoriesTab;
