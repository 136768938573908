import * as types from "./actionTypes";

export const setSearchQuery = (payload) => ({
  type: types.SET_SEARCH_QUERY,
  payload
});

export const resetSearchQuery = () => ({
  type: types.RESET_SEARCH_QUERY
});
