import React from "react";
import styled from "styled-components";
import FileExplorer from "../../icons/FileExplorer";
import { Tooltip } from "@mui/material";
import { OwcIcon, OwcIconButton } from "@one/react";

export const FolderShapeInner = styled.div`
  display: flex;
  height: auto;
  background: white;
  position: relative;
  overflow: hidden;
  width: 160px;
  height: 80px;
  background: var(--one-color-gray-50);
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  & > .more-button-menu {
  }
  &:hover {
    border: 1px solid rgb(0, 102, 204);
    background-color: var(--one-color-cobas-white);
    // box-shadow: 0px 24px 24px 0px rgba(57, 69, 80, 0.2);
  }
`;

export const FolderIcon = styled.i`
  font-size: 32px;
  color: #323232;
  font-weight: 400;
`;

export const FolderShapeOuter = styled.div`
  cursor: pointer;
`;
export const FolderLabel = styled.div`
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: var(--one-color-gray-800);
  overflow: hidden;
  white-space: nowrap;
`;
export const FolderFooter = styled.div`
  display: block;
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0px;
  color: #737373;
`;

export const FolderSpace = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 160px;

  & > .start {
    display: flex;
    margin-left: auto;
    margin-right: 10px;
    & > .blankSpace {
      min-height: 26px;
    }
  }

  & > .middle > .content {
    display: flex;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: var(--one-color-gray-800);
    padding-left: 10px;
  }
  & > .middle > .content > .end {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;

    & > div:first-child {
      word-wrap: break-word;
      max-width: 114px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > div:nth-child(2) {
      padding-top: 5px;
    }
  }
`;

export const AddNewFolderOuter = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 160px;
  max-height: 80px;
  background: var(--one-color-gray-50);
  border: 1px solid #d3d3d3;
  box-sizing: border-box;

  &:hover {
    border: 1px solid rgb(0, 102, 204);
    background-color: var(--one-color-cobas-white);
    // box-shadow: 0px 24px 24px 0px rgba(57, 69, 80, 0.2);
  }
`;
export const FolderIconNew = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 400;
  min-height: 6px;
`;

export const AddNewFolder = ({ children, ...props }) => <AddNewFolderOuter {...props}>{children}</AddNewFolderOuter>;

export const FolderShape = ({ children, ...props }) => (
  <FolderShapeOuter {...props}>
    <FolderShapeInner>{children}</FolderShapeInner>
  </FolderShapeOuter>
);
export const FolderShape1 = ({ children, ...props }) => <FolderIconNew {...props}>{children}</FolderIconNew>;

export const CustomGroupedAppsCardGeneric = ({
  icon,
  label,
  isEditable = true,
  footer,
  onMoreMenu,
  component: Component = FolderShape,
  color = "var(--one-color-gray-800)",
  ...props
}) => (
  <Component {...props}>
    <FolderSpace>
      <div className="start">
        {icon ? <OwcIcon name="sign_plus" type="filled" /> : ""}

        {isEditable ? (
          onMoreMenu && (
            <OwcIconButton
              data-testid="custom-group-card-more-button"
              flat
              icon="more_vertical"
              type="filled"
              onClick={onMoreMenu}
            />
          )
        ) : (
          <div className="blankSpace"></div>
        )}
      </div>
      <div className="middle">
        <div className="content">
          <div className="forIcon">
            <FileExplorer />
          </div>
          <div className="end">
            <Tooltip title={label} placement="bottom-start">
              <FolderLabel data-testid="custom-group-card-label" style={{ color: `${color}` }}>
                {label}
              </FolderLabel>
            </Tooltip>
            <FolderFooter data-testid="custom-group-card-footer" style={{ color: `${color}` }}>
              {footer}
            </FolderFooter>
          </div>
        </div>
      </div>
    </FolderSpace>
  </Component>
);
export const CustomGroupedAppsCardGenericnew = ({ label, component: Component = FolderShape1, ...props }) => (
  <Component {...props}>
    <p> {label} </p>
  </Component>
);

export const CustomGroupedAppsCard = ({ customGroup, ...props }) => {
  return (
    <CustomGroupedAppsCardGeneric
      icon={customGroup?.icon}
      label={customGroup?.name}
      isEditable={customGroup?.isEditable}
      footer={
        customGroup?.appsWithEntryPoints?.map((app) => app.entryPoints.length)?.reduce((a, b) => a + b, 0) + " items"
      }
      {...props}
    />
  );
};

export const NewCustomGroupedAppsCard = (props) => {
  return (
    <>
      <CustomGroupedAppsCardGeneric
        icon={true}
        label={"Create new group"}
        footer={"Add new item to it"}
        component={AddNewFolder}
        color={"var(--one-color-blue-900)"}
        {...props}
      />
    </>
  );
};
export const NewCustomGroupedAppsCardnew = (props) => {
  return (
    <>
      <CustomGroupedAppsCardGenericnew label={"Create new group1"} {...props} />
    </>
  );
};
export default CustomGroupedAppsCard;
