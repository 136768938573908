import { v4 } from "uuid";
import { emptyEntryPoint } from "./emptyEntryPoint";

export function addEntryPointFactory({
  setDirtyInputs,
  dirtyInputs,
  checkValidEntryPoint,
  application,
  entryPoint,
  updateApplication,
  setEntryPoint
}) {
  return () => {
    setDirtyInputs({
      ...dirtyInputs,
      entryPoint_name: true,
      entryPoint_url: true
    });
    if (checkValidEntryPoint()) {
      const entryPoints = [...application.entryPoints, { ...entryPoint, id: v4() }];
      updateApplication({ value: entryPoints, key: "entryPoints" });
      setEntryPoint({ ...emptyEntryPoint });
      setDirtyInputs({
        ...dirtyInputs,
        entryPoint_name: false,
        entryPoint_url: false
      });
    }
  };
}
