import { useState, useEffect, useContext } from "react";
import { getMetadata } from "./get-metadata";
import MetadataUrlForm from "./MetadataUrlForm";
import WidgetDialogForm from "./WidgetDialogForm";
import { checkValidURL } from "../../../../utils/helpers/text";
import { validateMetadata } from "./validate-metadata";
import { AllWidgetsContext } from "../context/context";
import { Module } from "../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const { adminWidget } = Module.PAGE.ADMIN;

const WidgetMetadataUrlDialog = ({ widget, onCancel, onApprove, open, loading, setLoading }) => {
  const [metadataUrl, setMetadataUrl] = useState(widget.id ? widget.metadataURL : "");
  const [error, setError] = useState(null);
  const [touched, setTouched] = useState(Boolean(widget.id));
  const { widgets } = useContext(AllWidgetsContext);

  useEffect(() => {
    if (!touched) {
      return;
    }

    if (checkValidURL(metadataUrl)) {
      setError(null);
    } else {
      setError("Metadata URL must be a valid URL");
    }
  }, [metadataUrl, touched]);

  const handleApprove = async () => {
    setLoading(true);
    const { metadata, error: err } = await getMetadata(metadataUrl);

    if (err) {
      setLoading(false);
      setError("Unable to get valid JSON from this URL");
      return;
    }

    const errorMessage = validateMetadata(metadata, widget, widgets);

    if (errorMessage) {
      setLoading(false);
      setError(errorMessage);
      return;
    }

    setError(null);
    setLoading(false);

    onApprove(metadataUrl, {
      ...metadata,
      metadataURL: metadataUrl
    });
  };

  const dataTestId = widget.id ? "update" : "add";

  return (
    <WidgetDialogForm
      id={generateID.UUID(adminWidget, `widgetMeta`, "widget_form")}
      disabled={Boolean(error) || !touched}
      mainTitle={widget.id ? "Update widget" : "Add widget"}
      countTitle={
        <>
          <strong>1</strong> of 2 steps
        </>
      }
      approveButtonTitle="Continue"
      dataTestId={dataTestId}
      onApprove={handleApprove}
      onCancel={onCancel}
      open={open}
      loading={loading}
    >
      <MetadataUrlForm
        id={generateID.UUID(adminWidget, `widgetMeta`, "meta_data_url")}
        error={error}
        dataTestId={dataTestId}
        metadataUrl={metadataUrl}
        onMetadataUrlChange={(event) => {
          setMetadataUrl(event);
          setTouched(true);
        }}
        onMetadataUrlBlur={() => setTouched(true)}
      />
    </WidgetDialogForm>
  );
};

export default WidgetMetadataUrlDialog;
