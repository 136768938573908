import { useEffect } from "react";
import { compose } from "redux";
import { LIST_APPLICATION_GROUPS } from "../../gql/landingapi/queries";
import { connect } from "react-redux";
import { withData } from "../../utils/hocs/withData";
import { loadUserInfo as loadUserInfoActions } from "../landingPageUser/redux/actions";
import { withApollo } from "react-apollo";
import { getApplicationUpdate } from "./getApplicationUpdate";

export const UpdateData = ({ data, loadUserInfo, client, getUpdate = getApplicationUpdate }) => {
  useEffect(() => {
    loadUserInfo({
      allGroups: data
    });
    getUpdate({ client, allGroups: data })
      .then(({ allApps }) => {
        loadUserInfo({
          allApps
        });
      })
      .catch((err) => {
        console.log("@UpdateData:", err);
      });
  }, [data, loadUserInfo, getUpdate, client]);
  return null;
};

export default compose(
  connect(null, { loadUserInfo: loadUserInfoActions }),
  withData({
    dataPath: "listDigitalLabLandingPageApplicationGroups.items",
    queryGQL: LIST_APPLICATION_GROUPS
  }),
  withApollo
)(UpdateData);
