import * as yup from "yup";
import { checkValidURL } from "../../../utils/helpers/text";

export const topBannerMessageValidationSchemaFactory = () => {
  return yup.object({
    color: yup.string("Enter color").typeError("Enter color").required("Enter color"),
    heading: yup
      .string("Enter title")
      .trim("You must add at least one character that will not be a space")
      .min(1, "You must add at least one character that will not be a space")
      .typeError("Enter title")
      .required("Enter title"),
    displayText: yup
      .string("Enter text to display")
      .typeError("Enter text to display")
      .required("Enter text to display")
      .nullable(),
    infoURl: yup
      .string("Invalid link, should be a valid URL, e.g. https://roche.com")
      .typeError("Invalid link, should be a valid URL, e.g. https://roche.com")
      .test({
        message: "Invalid link, should be a valid URL, e.g. https://roche.com",
        test: (value) => {
          return (value ?? "").length === 0 || checkValidURL(value);
        }
      })
      .nullable(),
    enabled: yup.bool()
  });
};
