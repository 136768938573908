import React, { useEffect, useState } from "react";
import { OwcModalDialog, OwcButton } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../constants";

const ConfirmDialog = ({
  title = "",
  content = "",
  cancelText,
  approveText,
  cancelVariant,
  cancelColor,
  approveColor,
  approveVariant,
  onCancel,
  onApprove,
  close,
  open,
  children,
  renderActions,
  size = "sm"
}) => {
  const [lockApprove, setLockApprove] = useState(false);
  const handleCancel = () => {
    setLockApprove(true);
    close();
    if (onCancel && !lockApprove) {
      onCancel();
    }
  };
  const handleApprove = () => {
    setLockApprove(true);
    close();
    if (onApprove && !lockApprove) {
      onApprove();
    }
  };
  useEffect(() => {
    if (open) {
      setLockApprove(false);
    }
  }, [open, setLockApprove]);

  return (
    <OwcModalDialog
      disableScrollLock
      disableBackdropClick
      visible={open}
      size={size}
      className="owcmodalZIndex"
      id={generateID.UUID(Module.PAGE.ADMIN.confirmDialog, `appAndCategories`, "dialog")}
    >
      {children ? children : content}
      <div slot="header" id={generateID.UUID(Module.PAGE.ADMIN.confirmDialog, `appAndCategories_header`, "dialog")}>
        {title}
      </div>
      <div slot="actions" id={generateID.UUID(Module.PAGE.ADMIN.confirmDialog, `appAndCategories_actions`, "dialog")}>
        {typeof renderActions === "function" ? (
          renderActions({
            cancelColor,
            cancelVariant,
            approveColor,
            approveVariant,
            cancelText,
            approveText,
            close
          })
        ) : (
          <div
            style={{
              justifyContent: "flex-end",
              width: "100%",
              display: "flex"
            }}
          >
            <OwcButton
              style={{ marginRight: 20 }}
              data-testid="confirm-dialog-actions-button-cancel"
              onClick={handleCancel}
              variant="secondary"
              id={generateID.UUID(Module.PAGE.ADMIN.confirmDialog, `appAndCategories_cancel_button`, "dialog")}
            >
              {cancelText || null}
            </OwcButton>
            <OwcButton
              disabled={lockApprove}
              data-testid="confirm-dialog-actions-button-approve"
              onClick={handleApprove}
              color={approveColor && "primary"}
              id={generateID.UUID(Module.PAGE.ADMIN.confirmDialog, `appAndCategories_approve_button`, "dialog")}
              variant="primary"
            >
              {approveText || null}
            </OwcButton>
          </div>
        )}
      </div>
    </OwcModalDialog>
  );
};
export default ConfirmDialog;
