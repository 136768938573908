import React, { useEffect, useContext, useState, useCallback } from "react";
import { withApollo } from "react-apollo";
import { compose } from "redux";
import ConfirmDialog from "../../components/shared/ConfirmDialog";
import { AddOrEditContext } from "./contexts";
import { useMobile } from "../../utils/hooks/useMobile";
import { LIST_APPLICATION_GROUPS } from "../../gql/landingapi/queries";
import { withData } from "../../utils/hocs/withData";
import { shouldBeDisabledAll, handleAction } from "./helpers";
import { uploadIconFlow } from "../uploadIcon/helpers";
import Snackbar from "../snackbar/Snackbar";
import { connect } from "react-redux";
import { updateGroup as updateGroupAction } from "../landingPageUser/redux/actions";
import CategoryNameAndIconInputsForDialog from "./CategoryNameAndIconInputsForDialog";
import BUTTON_MODEL_LABEL from "../../utils/constants/buttonModelLabel";
import { OwcButton } from "@one/react";
import { Module } from "../../constants";
import { generateID } from "@digitallab/grid-common-components";

const getApproverAndTitleText = (item) => {
  if (item) {
    return BUTTON_MODEL_LABEL.save;
  } else {
    return BUTTON_MODEL_LABEL.add;
  }
};
const getLabelTitleText = (item) => {
  if (item) {
    return BUTTON_MODEL_LABEL.editCategory;
  } else {
    return BUTTON_MODEL_LABEL.addCategory;
  }
};
const uploadIconFileToGroup = async ({ updateGroup, item, client, _item, file }) => {
  updateGroup({
    ...item,
    iconPath: item?.iconPath ? "loading" : null
  });
  await uploadIconFlow({
    client,
    file,
    groupId: _item?.id,
    onSuccess: () =>
      Snackbar({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `File was successfully uploaded!`
      }),
    onError: (error) =>
      Snackbar({
        type: "warning",
        icon: "caution",
        appName: "",
        text: error
      })
  });
};

const { appAndCategories } = Module.PAGE.ADMIN;

const renderActionFactory = ({ close, item, file, updateGroup, client, name, errors, isFileEdit }) => {
  return ({ cancelColor, cancelVariant, approveColor, approveVariant, cancelText, approveText }) => (
    <div
      id={generateID.UUID(appAndCategories, `renderActionFactory`, "div")}
      style={{ display: "flex", marginLeft: "auto" }}
    >
      <OwcButton
        data-testid="confirm-dialog-actions-button-cancel"
        onClick={close}
        color={cancelColor && "secondary"}
        variant="outlined"
        style={{ marginRight: 15 }}
        id={generateID.UUID(appAndCategories, `renderActionFactory`, "button")}
      >
        {cancelText}
      </OwcButton>
      <OwcButton
        data-testid="confirm-dialog-actions-button-approve"
        id={generateID.UUID(appAndCategories, `renderActionFactory`, "button_approve")}
        onClick={async () => {
          const _item = item;
          close();
          if (file) {
            await uploadIconFileToGroup({
              updateGroup,
              item,
              client,
              _item,
              file
            });
          }
          await handleAction({
            item: _item,
            client,
            name
          });
          if (_item) {
            updateGroup(_item);
          }
        }}
        color={approveColor && "primary"}
        variant="primary"
        disabled={errors && !isFileEdit}
      >
        {approveText}
      </OwcButton>
    </div>
  );
};

const emptyDirtyInputs = {
  name: false
};
export const Dialog = ({ data, client, updateGroup }) => {
  const { isOpen, item, close } = useContext(AddOrEditContext);
  const isMobile = useMobile();
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const { name: originalName = "" } = item ?? {};
  useEffect(() => {
    if (isOpen) {
      setName(originalName);
    }
  }, [isOpen, originalName]);
  const [dirtyInputs, setDirtyInputs] = useState({ ...emptyDirtyInputs });
  useEffect(() => {
    if (isOpen) {
      setDirtyInputs({
        name: false
      });
    }
  }, [isOpen]);

  const onFailedRequirements = useCallback((error) => {
    Snackbar({
      type: "warning",
      icon: "caution",
      appName: "",
      text: error?.message || error
    });
  }, []);
  const errors = data
    ? shouldBeDisabledAll(data, {
        name,
        id: item?.id ?? ""
      })
    : "";
  const isFileEdit = item && file;
  const onFileSelect = useCallback((fileCallback) => setFile(fileCallback), []);
  const setNameAndSetDirtyInputs = (e) => {
    setName(e.target.value);
    setDirtyInputs({ ...dirtyInputs, name: true });
  };
  useEffect(() => {
    if (isOpen) {
      setFile(null);
    }
  }, [isOpen]);
  return (
    <ConfirmDialog
      open={isOpen}
      size="sm"
      approveText={getApproverAndTitleText(item)}
      testid={"addOrEditDialog"}
      approveColor="primary"
      approveVariant="contained"
      cancelText="Cancel"
      cancelVariant="outlined"
      cancelColor="primary"
      title={getLabelTitleText(item)}
      isDivider={true}
      onCancel={close}
      close={close}
      renderActions={renderActionFactory({
        close,
        item,
        file,
        updateGroup,
        client,
        name,
        errors,
        isFileEdit
      })}
    >
      <CategoryNameAndIconInputsForDialog
        item={item}
        isOpen={isOpen}
        onFileSelect={onFileSelect}
        onFailedRequirements={onFailedRequirements}
        file={file}
        setFile={setFile}
        isMobile={isMobile}
        name={name}
        errors={errors}
        dirtyInputs={isOpen ? dirtyInputs : emptyDirtyInputs}
        setNameAndSetDirtyInputs={setNameAndSetDirtyInputs}
        setName={setName}
        id={generateID.UUID(appAndCategories, `renderActionFactory`, "categoryName")}
      />
    </ConfirmDialog>
  );
};

export default compose(
  withApollo,
  withData({
    dataPath: "listDigitalLabLandingPageApplicationGroups.items",
    queryGQL: LIST_APPLICATION_GROUPS
  }),
  connect(null, { updateGroup: updateGroupAction })
)(Dialog);
