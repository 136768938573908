import { emptyEntryPoint } from "../helpers/emptyEntryPoint";
import { OwcIconButton } from "@one/react";

import { Module } from "../../../../constants";
import { generateID } from "@digitallab/grid-common-components";
const LaunchStepButtons = ({
  entryPoint,
  uniqueNameEntryPoint,
  validationInputs,
  addEntryPoint,
  updateEntryPoint,
  setEntryPoint,
  setDirtyInputs,
  dirtyInputs,
  setUniqueNameEntryPoint
}) => {
  return entryPoint.id === null ? (
    <div
      data-testid="application-edit-form-launch-step-buttons-adding-new"
      id={generateID.UUID(Module.PAGE.ADMIN.launchStepLink, `launchStepButton`, "button")}
      style={{ display: "flex", alignItems: "flex-start" }}
    >
      <OwcIconButton
        id={generateID.UUID(Module.PAGE.ADMIN.launchStepLink, `launchStepButton`, "icon")}
        icon="circle_add"
        type="outlined"
        flat
        data-testid="application-edit-form-launch-step-button-addnew"
        disabled={!uniqueNameEntryPoint || !validationInputs.entryPoint_name || !validationInputs.entryPoint_url}
        onClick={() => addEntryPoint()}
        style={{ marginTop: 5 }}
      />
    </div>
  ) : (
    <div
      data-testid="application-edit-form-launch-step-buttons-edit-and-clear"
      id={generateID.UUID(Module.PAGE.ADMIN.launchStepLink, `launchStepButton`, "button")}
      style={{ display: "flex", alignItems: "flex-start" }}
    >
      <OwcIconButton
        data-testid="application-edit-form-launch-step-button-edit"
        type="outlined"
        icon="confirm"
        flat
        disabled={!uniqueNameEntryPoint || !validationInputs.entryPoint_name || !validationInputs.entryPoint_url}
        id={generateID.UUID(Module.PAGE.ADMIN.launchStepLink, `launchStepButton`, "icon_button")}
        onClick={() => updateEntryPoint()}
      />
      <OwcIconButton
        id={generateID.UUID(Module.PAGE.ADMIN.launchStepLink, `launchStepButton`, "icon_button")}
        data-testid="application-edit-form-launch-step-button-clear"
        type="outlined"
        icon="clear"
        flat
        onClick={() => {
          setEntryPoint({ ...emptyEntryPoint });
          setDirtyInputs({
            ...dirtyInputs,
            entryPoint_name: false,
            entryPoint_url: false
          });
          setUniqueNameEntryPoint(true);
        }}
      />
    </div>
  );
};

export default LaunchStepButtons;
