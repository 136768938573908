import React from "react";
import styled, { css } from "styled-components";
import { SEVERITY_NOTIFICATIONS } from "../../SeverityNotifications";

const severityHigh = css`
  background-color: rgba(204, 0, 51, 0.1);
  color: var(--one-color-cobas-red-400);
`;
const severityMedium = css`
  background-color: rgba(226, 104, 21, 0.1);
  color: var(--one-color-orange-700);
`;
const severityLow = css`
  background-color: var(--one-color-cobas-gray-150);
  color: var(--one-color-gray-600);
`;
const notifySelected = css`
  background-color: var(--one-color-cobas-white);
`;
const SeverityNotifyStyled = styled.div`
  display: inline-block;
  border-radius: 16px;
  padding: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  ${(props) => props.severity === SEVERITY_NOTIFICATIONS.HIGH && severityHigh}
  ${(props) => props.severity === SEVERITY_NOTIFICATIONS.MEDIUM && severityMedium}
  ${(props) => props.severity === SEVERITY_NOTIFICATIONS.LOW && severityLow}

  ${(props) => props.selected && notifySelected}
`;
const SeverityStatusStyled = styled.span`
  text-transform: capitalize;
`;

const SeverityNotify = ({ severity, selected }) => {
  return (
    <SeverityNotifyStyled data-testid="severity-long-box" severity={severity} selected={selected}>
      <SeverityStatusStyled data-testid="severity-long-name">{severity}</SeverityStatusStyled> severity
    </SeverityNotifyStyled>
  );
};

export default SeverityNotify;
