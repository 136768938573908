import React from "react";
import withRoles from "../../utils/hocs/withRoles";
import { LANDING_PAGE_ADMIN } from "../../constants";
import GeneralMainPage from "./general/GeneralMainPage";
import { AdminPanelWrapper } from "../context/AdminPanelWrapper";

const AdminPanel = () => {
  return (
    <AdminPanelWrapper>
      <GeneralMainPage />
    </AdminPanelWrapper>
  );
};

export default withRoles({ requiredRoles: [LANDING_PAGE_ADMIN] })(AdminPanel);
