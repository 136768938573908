import { useContext, useEffect } from "react";
import { withApollo } from "react-apollo";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { filterByQuery } from "../../categories/helpers";
import { AllWidgetsContext } from "./context/context";

const getFromIndex = (nextToken, widgets) => {
  if (widgets.length === 0) {
    return 0;
  }

  if (!nextToken) {
    return 0;
  }

  return nextToken;
};

const paginateWidgets = ({ nextToken, widgets, limit, orderBy, sortDirection }) => {
  const fromIndex = getFromIndex(nextToken, widgets);
  const paginatedWidgets = filterByQuery(
    widgets.map((w) => ({
      ...w,
      releaseDate: w.releaseDate ?? "",
      fromApp: w.fromAppName || w.fromAppSource
    })),
    "",
    undefined,
    [orderBy],
    sortDirection === "ASC"
  )
    .slice(fromIndex, fromIndex + limit)
    .map(({ fromApp: _, ...w }) => ({
      ...w,
      releaseDate: w.releaseDate || null
    }));

  const nextIndex = limit + fromIndex;
  return { nextIndex, paginatedWidgets };
};

const UpdateData = ({ client, fetchingFn }) => {
  const { nextData, nextToken, limit, dispatchAction, refreshData, sortDirection, orderBy } =
    useContext(PaginationContext);
  const { widgets, setWidgets } = useContext(AllWidgetsContext);
  useEffect(() => {
    if (nextData) {
      dispatchAction({
        type: "clearAll"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchAction({
      type: "fetching",
      payload: true
    });

    const fetchData = async () => {
      const { items } = await fetchingFn({
        client,
        limit: 200,
        drillData: true
      });

      setWidgets(items);
      const { nextIndex, paginatedWidgets } = paginateWidgets({
        nextToken: null,
        widgets: items,
        limit,
        orderBy,
        sortDirection
      });

      dispatchAction({
        type: "nextToken",
        payload: {
          token: nextIndex,
          data: paginatedWidgets
        }
      });
      dispatchAction({
        type: "refreshToken",
        payload: {
          nextToken: nextIndex,
          prevTokens: []
        }
      });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, fetchingFn, client, setWidgets, dispatchAction]);

  useEffect(() => {
    if (!widgets) {
      return;
    }
    const { nextIndex, paginatedWidgets } = paginateWidgets({
      nextToken,
      widgets,
      limit,
      orderBy,
      sortDirection
    });

    dispatchAction({
      type: "nextToken",
      payload: {
        token: nextIndex,
        data: paginatedWidgets
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, orderBy, nextData, limit]);

  return null;
};

export default withApollo(UpdateData);
