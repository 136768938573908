import React from "react";
import styled, { css } from "styled-components";
import { useMobile } from "../utils/hooks/useMobile";
import { OwcIcon, OwcTypography } from "@one/react";

const forbiddenAccessMobile = css`
  height: auto;
  transform: none;
`;

const ForbiddenAccessStyled = styled.div`
  font-family: Imago, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(80vh - 96px);
  transform: translateY(-40px);
  ${(props) => props.isMobile && forbiddenAccessMobile}

  color: var(--one-color-blue-900);
  & > .title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ForbiddenAccess = ({ title = "Access permission", text = "You do not have permisson to see this view." }) => {
  const isMobile = useMobile();
  return (
    <ForbiddenAccessStyled isMobile={isMobile} data-testid="forbidden-access">
      <div className="title">
        <OwcIcon className="one-icons-outlined" name="lock" style={{ fontSize: 46 }} />
        <OwcTypography variant="title3">{title}</OwcTypography>
      </div>
      <OwcTypography variant="title5">{text}</OwcTypography>
    </ForbiddenAccessStyled>
  );
};

export default ForbiddenAccess;
