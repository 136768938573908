import * as R from "ramda";
import { at } from "lodash/fp";
import Snackbar from "../snackbar/Snackbar";
import { isEqualWith } from "lodash";
import { editAction, addAction, deleteAction } from "./actions";

export const checkIfTextIsValid = (text) => {
  return /^[ \p{L}0-9_\-:]+$/giu.test(text);
};

export const shouldBeDisabledAll = (data, current) => {
  const errors = {};
  if (!checkIfTextIsValid(current?.name)) {
    errors.name =
      "Category name must have at least 1 character and can only contain alphanumeric characters, space, and _ - :";
  }
  if (
    !current?.id &&
    data.some((item) => isEqualWith(item, current, (obj, oth) => getText(obj.name) === getText(oth.name)))
  ) {
    errors.name = "Category name should be unique";
  }

  if (
    current?.id &&
    data.some((item) =>
      isEqualWith(item, current, (obj, oth) => getText(obj.name) === getText(oth.name) && obj.id !== oth.id)
    )
  ) {
    errors.name = "Category name should be unique";
  }
  if (Object.keys(errors).length > 0) {
    return errors;
  }
  return null;
};

export const handleAction = async ({ item, client, name }) => {
  try {
    if (name === "") {
      return;
    }
    if (item) {
      await editAction({ item, client, name });
    } else {
      await addAction({ client, name });
    }

    Snackbar({
      type: "success",
      icon: "circle_confirm",
      appName: name,
      text: `was successfully ${item ? "updated" : "added"}!`
    });
  } catch (err) {
    Snackbar({
      type: "warning",
      appName: name,
      text: `was not ${item ? "updated" : "added"}, please try again`
    });
  }
};

export const handleDelete = async ({ item, client }) => {
  try {
    await deleteAction({
      id: item?.id,
      client
    });
    Snackbar({
      type: "success",
      icon: "circle_confirm",
      appName: item?.name,
      text: "was successfully removed!"
    });
  } catch (err) {
    Snackbar({
      type: "warning",
      appName: item?.name,
      text: "was not removed, please try again"
    });
  }
};

export const getText = (text) => R.compose(R.toLower, R.trim)(text);

export const filterByQuery = (
  data,
  query,
  propFilter = ["name", "updatedAt"],
  propSort = ["updatedAt"],
  reverse = false
) => {
  if (!query) {
    R.sort(R.descend(R.path(propSort)))(data);
  }
  const _query = getText(query);
  const valuesAt = at(propFilter);
  const result = R.compose(
    R.sort(R.descend(R.compose(getText, R.path(propSort)))),
    R.filter(R.compose(R.contains(_query), getText, R.compose(R.join(""), valuesAt)))
  )(data);

  return reverse ? result.reverse() : result;
};
