import React from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import App from "./App";
import Authentication from "./features/Authentication";
import { Route, Switch } from "react-router-dom";
import NotFoundScreen from "./components/NotFoundScreen";
import { federated } from "./aws-exports";
import ModuleVersion from "./features/system-version/ModuleVersion";
import styled from "styled-components";
import { LicenseManager } from "ag-grid-enterprise";
import { updateEmailURL } from "./constants";
LicenseManager.setLicenseKey(
  "CompanyName=F. Hoffmann-La Roche AG,LicensedGroup=Roche-DigitalLab,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-037360,SupportServicesEnd=15_February_2024_[v2]_MTcwNzk1NTIwMDAwMA==3d1de6a5550abcadfbedb4c5dae4d903"
);
//  import { getEnv } from "./utils/helpers/text";
/**
 * to turn off google login button - remove federated authenticatorProps below
 */
const AuthenticatedApp = withAuthenticator(App, { federated });
// const currentEnv = getEnv();

const AdaptarWrapperStyle = styled.div``;
const Main = () => {
  return (
    <>
      <AdaptarWrapperStyle>
        <Switch>
          <Route
            exact
            path={[
              "/",
              "/notifications",
              "/notificationsApp",
              "/home/",
              "/home/dashboard",
              "/home/quick-access",
              "/home/application",
              "/home/my-lab",
              "/admin/general/application-categories",
              "/admin/general/widgets",
              "/admin/general/banner-msg",
              "/admin/general/send-notification",
              `${updateEmailURL}`
            ]}
          >
            <Authentication>
              <AuthenticatedApp />
            </Authentication>
          </Route>
          <Route exact path="/info">
            <ModuleVersion />
          </Route>
          <Route path="*">
            <NotFoundScreen redirectPath={"/"} />
          </Route>
        </Switch>
      </AdaptarWrapperStyle>
    </>
  );
};
export default Main;
