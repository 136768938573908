import { DLabEquipmentDetailsPopOver } from "@digitallab/grid-common-components";
import React, { useState } from "react";
import { withApollo } from "react-apollo";
import { CLUSTER_EQUIPMENT_POPOVER_DETAILS, CLUSTER_SUB_EQUIPMENT_OBJ } from "../../constants";
import { GET_DIGITAL_LAB_PAGE_EQUIPMENTS } from "../../gql/landingapi/queries";
import { getClusterPopoverInfo } from "../../utils/helpers/text";
import EquipmentPage from "./EquipmentPage";

const EquipmentMainContainer = ({ client }) => {
  const [subEquipmentPopOverLoading, setsubEquipmentPopOverLoading] = useState(false);
  const [equipmentInfo, setEquipmentInfo] = useState({});
  const [subEquipmentPopOverOpen, setsubEquipmentPopOverOpen] = useState(false);
  const [subEquipmentPopOverIndex, setsubEquipmentPopOverIndex] = useState(null);
  const getClusterEquipments = async (id) => {
    try {
      const result = await client.query({
        query: GET_DIGITAL_LAB_PAGE_EQUIPMENTS,
        fetchPolicy: "no-cache",
        variables: {
          inventoryId: id
        }
      });
      if (result) {
        return result?.data?.getDigitalLabLandingPageEquipment;
      }
    } catch (err) {
      console.log("cluster check error", err);
    }
  };
  const subEquipmentInfoAction = async (item) => {
    try {
      setsubEquipmentPopOverLoading(true);
      let result = await getClusterEquipments(item?.cluster_id[0], client);
      if (result) {
        const options = {
          hideFooter: true,
          hideHeader: false,
          hideBookingDetails: false
        };
        const equipmentInfo = getClusterPopoverInfo(
          result,
          CLUSTER_EQUIPMENT_POPOVER_DETAILS,
          CLUSTER_SUB_EQUIPMENT_OBJ,
          options,
          "Cluster details",
          "Cluster Components"
        );
        setsubEquipmentPopOverIndex(item?.id[0]);
        setEquipmentInfo(equipmentInfo);
        setsubEquipmentPopOverLoading(false);
        setsubEquipmentPopOverOpen(true);
      }
    } catch (err) {
      console.log("cluster check error", err);
    }
  };

  return (
    <div>
      {subEquipmentPopOverOpen && (
        <DLabEquipmentDetailsPopOver
          equipmentDetail={equipmentInfo.equipmentDetail}
          showPopup={subEquipmentPopOverOpen}
          loading={subEquipmentPopOverLoading}
          anchor={`popup-anchor-for-cluster-${subEquipmentPopOverIndex}`}
          handleClickOutSide={(event) => {
            setsubEquipmentPopOverOpen(false);
            setsubEquipmentPopOverLoading(true);
            setsubEquipmentPopOverIndex(null);
          }}
          alignment="right"
        />
      )}
      <EquipmentPage subEquipmentInfoAction={subEquipmentInfoAction} />
    </div>
  );
};

export default withApollo(EquipmentMainContainer);
