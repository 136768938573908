import ConfirmDialog from "../../../components/shared/ConfirmDialog";
import ApplicationCategoriesSelect from "./inputs/ApplicationCategoriesSelect";
import AddVisibilityForUserRolesInput from "./inputs/AddVisibilityForUserRolesInput";
import NameAndIconInput from "./inputs/NameAndIconInput";
import RunModeInput from "./inputs/RunModeInput";
import DescriptionInput from "./inputs/DescriptionInput";
import LaunchStepNameInput from "./inputs/LaunchStepNameInput";
import LaunchStepLinkInputWithButtons from "./inputs/LaunchStepLinkInputWithButtons";
import LaunchStepList from "./LaunchStepList";
import styles from "./ApplicationEditFormContent.module.scss";

const ApplicationEditFormContent = ({
  dialogProps,
  deleteEntryPoint,
  entryPointToDelete,
  setEntryPointToDelete,
  application,
  setFile,
  onFailedRequirements,
  file,
  validationInputs,
  dirtyInputs,
  uniqueNameApp,
  updateApplication,
  setDirtyInputs,
  classes,
  categories,
  allowedRoles,
  entryPoint,
  uniqueNameEntryPoint,
  handleEntryPointUpdate,
  addEntryPoint,
  updateEntryPoint,
  setEntryPoint,
  setUniqueNameEntryPoint,
  openDialog
}) => {
  const categoriesList = categories.map((item) => item?.name);
  const sortedCategory = categoriesList?.sort(function (a, b) {
    return a?.toLowerCase().localeCompare(b?.toLowerCase());
  });

  return (
    <>
      <ConfirmDialog
        {...dialogProps}
        onApprove={() => deleteEntryPoint(entryPointToDelete)}
        approveText="Delete Entry Point"
        approveColor="error"
        approveVariant="contained"
        cancelText="Cancel"
        cancelVariant="outlined"
        cancelColor="primary"
        onCancel={() => setEntryPointToDelete(null)}
        title="Delete Entry Point"
        content={`Are you sure you want to delete the entry point?`}
      />

      <div>
        <div className={styles.applicationeditformcontentNameCategory}>
          <NameAndIconInput
            application={application}
            setFile={setFile}
            onFailedRequirements={onFailedRequirements}
            file={file}
            validationInputs={validationInputs}
            dirtyInputs={dirtyInputs}
            uniqueNameApp={uniqueNameApp}
            updateApplication={updateApplication}
            setDirtyInputs={setDirtyInputs}
          />
          <ApplicationCategoriesSelect
            classes={classes}
            application={application}
            updateApplication={updateApplication}
            setDirtyInputs={setDirtyInputs}
            dirtyInputs={dirtyInputs}
            categories={sortedCategory}
            style={{ width: "45%" }}
          />
        </div>
        <div style={{ paddingBottom: "16px" }}>
          <AddVisibilityForUserRolesInput
            classes={classes}
            allowedRoles={allowedRoles}
            application={application}
            updateApplication={updateApplication}
            setDirtyInputs={setDirtyInputs}
            dirtyInputs={dirtyInputs}
          />
        </div>
        <div className={styles.applicationeditformcontentVisibilityRoleRunMode}>
          <RunModeInput
            classes={classes}
            application={application}
            updateApplication={updateApplication}
            setDirtyInputs={setDirtyInputs}
            dirtyInputs={dirtyInputs}
          />
        </div>
        <div style={{ width: "100%", marginBottom: 16 }}>
          <DescriptionInput
            application={application}
            updateApplication={updateApplication}
            setDirtyInputs={setDirtyInputs}
            dirtyInputs={dirtyInputs}
            validationInputs={validationInputs}
          />
        </div>
        <div className={styles.applicationeditformcontentLaunchstep}>
          <LaunchStepNameInput
            entryPoint={entryPoint}
            validationInputs={validationInputs}
            dirtyInputs={dirtyInputs}
            uniqueNameEntryPoint={uniqueNameEntryPoint}
            handleEntryPointUpdate={handleEntryPointUpdate}
            setDirtyInputs={setDirtyInputs}
          />
          <LaunchStepLinkInputWithButtons
            entryPoint={entryPoint}
            validationInputs={validationInputs}
            dirtyInputs={dirtyInputs}
            handleEntryPointUpdate={handleEntryPointUpdate}
            setDirtyInputs={setDirtyInputs}
            uniqueNameEntryPoint={uniqueNameEntryPoint}
            addEntryPoint={addEntryPoint}
            classes={classes}
            updateEntryPoint={updateEntryPoint}
            setEntryPoint={setEntryPoint}
            setUniqueNameEntryPoint={setUniqueNameEntryPoint}
          />
        </div>
        <div>
          <LaunchStepList
            validationInputs={validationInputs}
            application={application}
            setDirtyInputs={setDirtyInputs}
            dirtyInputs={dirtyInputs}
            setEntryPoint={setEntryPoint}
            setEntryPointToDelete={setEntryPointToDelete}
            openDialog={openDialog}
          />
        </div>
      </div>
    </>
  );
};

export default ApplicationEditFormContent;
