export const emptyApplication = {
  id: null,
  name: "",
  description: "",
  icon: "folder",
  type: "external",
  applicationGroupIDs: [],
  entryPoints: [],
  allowedRoles: []
};
