import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";
import { CloudSearchConfig } from "./CloudSearchConfig";
import { entryType } from "../../constants";

export const hasFormikObjectError = (formik, fieldName) => {
  return (
    (formik.touched[fieldName]?.value || formik.touched[fieldName] === true) &&
    Boolean(formik.errors[fieldName]?.value ?? formik.errors[fieldName])
  );
};
export const getFormikObjectError = (formik, fieldName) => {
  return (
    (formik.touched[fieldName]?.value || formik.touched[fieldName] === true) &&
    (formik.errors[fieldName]?.value ?? formik.errors[fieldName])
  );
};

const commonPropsForInputs =
  ({
    fieldKeyFn = (key) => `${key}.value`,
    valueFn = (formik, key) => formik.values?.[key]?.value,
    errorFn = (formik, key) => hasFormikObjectError(formik, key),
    helperTextFn = (formik, key) => getFormikObjectError(formik, key)
  } = {}) =>
  (classes, formik, key, dataTestIdKey, dataTestIdForFormKey, isEdit = false) => ({
    InputProps: valueFn(formik, key)?.length > 0 &&
      !isEdit && {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              classes={{
                root: classes.clearInputButton
              }}
              data-testid={`${dataTestIdForFormKey}-clear-button-${dataTestIdKey}-input`}
              edge="end"
              onClick={() => formik.setFieldValue(fieldKeyFn(key), "", true)}
            >
              <i className="one-icons-outlined">clear</i>
            </IconButton>
          </InputAdornment>
        )
      },
    error: errorFn(formik, key),
    helperText: helperTextFn(formik, key),
    FormHelperTextProps: {
      "data-testid": `${dataTestIdForFormKey}-helper-text-${dataTestIdKey}-input`
    }
  });

export const commonPropsForInputsWithValue = commonPropsForInputs();
export const commonPropsForInputsWithoutValue = commonPropsForInputs({
  fieldKeyFn: (key) => key,
  valueFn: (formik, key) => formik.values?.[key],
  errorFn: (formik, key) => formik.touched[key] && Boolean(formik.errors[key]),
  helperTextFn: (formik, key) => formik.touched[key] && formik.errors[key]
});

export const removeSpaceInString = (string) => {
  return string ? string.replace(/ /g, "") : string;
};

const checkShouldKeepFetching = (items, totalCount) => {
  return items.length < totalCount;
};

const fetchOne = async ({ variables, items }) => {
  const result = await CloudSearchConfig(variables);
  items = [...items, ...result?.data];
  let totalCount = result?.count;
  const shouldKeepFetching = checkShouldKeepFetching(items, totalCount);

  return { items, shouldKeepFetching, totalCount };
};

export const getAllCloudSearchData = async ({ requestObj = {} }) => {
  try {
    let items = [];
    let shouldKeepFetching = true;
    do {
      ({ items, shouldKeepFetching } = await fetchOne({
        variables: {
          negatefield: requestObj?.negatefield || "",
          sort: requestObj?.sort || "asc",
          sortBy: requestObj?.sortBy || "serial_number",
          start: items?.length,
          size: requestObj?.size || 10000,
          searchValue:
            requestObj?.searchValue ||
            `-entry_type:'${entryType?.clusterSubequipment}' AND entry_type:'${entryType?.cluster}' AND cluster_id:*`
        },
        items
      }));
    } while (shouldKeepFetching);
    return {
      items,
      error: null
    };
  } catch (err) {
    return { items: [], error: err };
  }
};

export const getMegaClusterIds = async () => {
  const requestObj = {
    sort: "asc",
    sortBy: "serial_number",
    start: 0,
    size: 10000,
    searchValue: `-entry_type:'${entryType?.clusterSubequipment}' AND entry_type:'${entryType?.cluster}' AND cluster_id:*`
  };
  let result = await getAllCloudSearchData({ requestObj });
  let mclusterIds = [];
  if (result?.items?.length > 0) {
    mclusterIds = result?.items?.map((item) => item?.cluster_id && item?.cluster_id[0]);
  }
  return mclusterIds;
};
