import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import ApplicationGroup from "../features/applications/ApplicationGroup";
import { EMPTY_FAVORITE, NOT_FAVOURITE } from "../constants";
import { selectAllApplicationSets, selectCustomGroup, selectFavourites, selectRecentlyUsed } from "../selectors";
import styled from "styled-components";
import QuickAccessFilter from "./QuickAccessFilter";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import CustomGroupModal, { EmptyCustomGroup } from "../features/applications/CustomGroupModal";
import useDialog from "../utils/hooks/useDialog";
import useModal from "../utils/hooks/useModal";
import {
  loadUserInfo as loadUserInfoActions,
  selectGroupUserInfo as selectGroupUserInfoActions
} from "../features/landingPageUser/redux/actions";
import { UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE } from "../gql/landingapi/mutations";
import { pickKeys } from "../features/applications/ApplicationFavouriteButton";
import { v4 as uuidv4 } from "uuid";
import get from "lodash/get";
import ConfirmDialog from "../components/shared/ConfirmDialog";
import BUTTON_MODEL_LABEL from "../utils/constants/buttonModelLabel";
import { useRef } from "react";
import { OwcButton, OwcTypography } from "@one/react";
import styles from "./Dashboard.module.scss";

const TopBarApplication = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const JumpToFilterStyled = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 20px;
`;

const JumpToButtonStyled = styled.div`
  display: flex;
  justify-content: right;
  align-items: right;
`;

const Dashboard = ({
  favourites,
  recentlyUsed,
  customGroups,
  allApps,
  applicationSets,
  selected,
  name,
  id,
  email,
  client,
  loadUserInfo,
  selectGroupUserInfo
}) => {
  const { trackPageView } = useMatomo();
  const groupRefs = useRef([]);
  useEffect(() => {
    trackPageView({
      documentTitle: "Quick Access",
      href: `${window.location?.origin}/QuickAccess`
    });
    // eslint-disable-next-line
  }, []);
  const [activeFilter, setActiveFilter] = useState("Favorite");
  const [groupMenuArray, setGroupMenuArray] = useState([]);
  const { openDialog, ...dialogProps } = useDialog();
  const [title, setTitle] = useState("Edit group");
  const [open, handleOpen, handleClose] = useModal(false);
  const [customGroupToEdit, setCustomGroupToEdit] = useState({
    ...EmptyCustomGroup
  });
  const [customGroupToDelete, setCustomGroupToDelete] = useState({
    ...EmptyCustomGroup
  });

  const updateApplicationSets = async (newApplicationSets) => {
    loadUserInfo({ applicationSets: newApplicationSets });
    const updatedDigitalLabLandingPageUserProfile = await client.mutate({
      mutation: UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE,
      variables: { id, email, applicationSets: newApplicationSets }
    });

    const _applicationSets = get(
      updatedDigitalLabLandingPageUserProfile,
      "data.updateDigitalLabLandingPageUserProfile.applicationSets"
    );
    loadUserInfo({ applicationSets: _applicationSets });
  };
  const saveGroupFactory = ({ applicationSets, updateApplicationSets, handleClose }) => {
    return async (customGroup) => {
      let newApplicationSets;
      if (customGroup.id) {
        newApplicationSets = applicationSets.map(pickKeys);

        const appSetToEdit = newApplicationSets.find((set) => set.id === customGroup.id);
        if (appSetToEdit) {
          appSetToEdit.name = customGroup.name;
          appSetToEdit.applicationReferences = appsWithEntryPointsToAppRefs(customGroup.appsWithEntryPoints);
        }
      } else {
        newApplicationSets = [
          ...applicationSets.map(pickKeys),
          {
            id: uuidv4(),
            name: customGroup.name,
            applicationReferences: appsWithEntryPointsToAppRefs(customGroup.appsWithEntryPoints)
          }
        ];
      }
      await updateApplicationSets(newApplicationSets);
      handleClose();
    };
  };
  const saveGroup = saveGroupFactory({
    applicationSets,
    updateApplicationSets,
    handleClose
  });
  const deleteGroup = async (customGroup) => {
    const newApplicationSets = applicationSets.map(pickKeys).filter((set) => set.id !== customGroup.id);
    await updateApplicationSets(newApplicationSets);
  };

  const appsWithEntryPointsToAppRefs = (appsWithEntryPoints) =>
    appsWithEntryPoints
      .map((appWithEntryPoints) =>
        appWithEntryPoints.entryPoints.map((entryPointId) => ({
          applicationID: appWithEntryPoints.application.id,
          applicationEntryPointID: entryPointId
        }))
      )
      .flat();

  useEffect(() => {
    const tempArray = customGroups.map((item) => {
      return item.name;
    });
    setGroupMenuArray(tempArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customGroups]);
  const scrollToDiv = (i) => {
    if (groupMenuArray.length === 0) {
      return;
    }
    if (i === 0) {
      //first entry should scroll to top
      window.scrollTo(0, 0);
      return;
    }
    groupRefs?.current[i]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest"
    });
  };
  useEffect(() => {
    let idx = jumpToList().findIndex((val) => val === activeFilter);
    scrollToDiv(idx);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter]);
  //build an array with 2 extra entries
  const jumpToList = () => ["Favorite", ...groupMenuArray, "Recently used"];

  return (
    <div className={styles.backgroundStyleDashboard}>
      <TopBarApplication>
        <OwcTypography variant="button">Quick access</OwcTypography>
        <JumpToButtonStyled>
          <JumpToFilterStyled>
            <OwcTypography variant="body1">Jump to: &nbsp;</OwcTypography>
            <QuickAccessFilter
              groupMenuArray={jumpToList()}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          </JumpToFilterStyled>
          <span style={{ marginBottom: 1 }}>
            <OwcButton
              onClick={() => {
                setCustomGroupToEdit({ ...EmptyCustomGroup });
                handleOpen();
                setTitle("Create new group");
              }}
            >
              {BUTTON_MODEL_LABEL.createGroup}
            </OwcButton>
          </span>
        </JumpToButtonStyled>
      </TopBarApplication>
      <div id={0} ref={(el) => (groupRefs.current[0] = el)}>
        <ApplicationGroup
          name="Favorite"
          applications={favourites}
          type={EMPTY_FAVORITE}
          noRecordMessage="No favorite applications setup.
        Please click on the star icon to add."
        />
      </div>
      {customGroups.map((item, index) => {
        return (
          <div key={item.id} id={"AppGroup_" + (index + 1)} ref={(el) => (groupRefs.current[index + 1] = el)}>
            <ApplicationGroup
              name={item.name}
              isEditable={true}
              applications={selectCustomGroup(allApps, applicationSets, item.id)}
              setTitle={setTitle}
              setCustomGroupToEdit={setCustomGroupToEdit}
              handleOpen={handleOpen}
              currentGroup={item}
              setCustomGroupToDelete={setCustomGroupToDelete}
              openDialog={openDialog}
              type={NOT_FAVOURITE}
              noRecordMessage="No applications in group.
              Please edit the group to add."
            />
          </div>
        );
      })}
      <div id={customGroups.length + 1} ref={(el) => (groupRefs.current[customGroups.length + 1] = el)}>
        <ApplicationGroup
          name="Recently used"
          applications={recentlyUsed}
          type={NOT_FAVOURITE}
          noRecordMessage="No recently used applications."
        />
      </div>

      <CustomGroupModal
        customGroup={customGroupToEdit}
        open={open}
        cancel={handleClose}
        save={saveGroup}
        applications={allApps}
        title={title}
        customGroups={customGroups}
      />
      <ConfirmDialog
        {...dialogProps}
        approveText="Delete group"
        approveColor="error"
        approveVariant="contained"
        cancelText="Cancel"
        cancelVariant="outlined"
        cancelColor="primary"
        onApprove={() => deleteGroup(customGroupToDelete)}
        title="Delete group"
        content={`Are you sure you want to delete the group '${customGroupToDelete?.name}'?`}
      />
    </div>
  );
};

const mapStateToProps = (store) => ({
  id: store.user.id,
  email: store.user.email,
  favourites: selectFavourites(store.user.allApps, store.user.applicationSets).sort((a, b) => {
    if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
      return 1;
    } else {
      if (b.name?.toLowerCase() > a.name?.toLowerCase()) {
        return -1;
      } else {
        return 0;
      }
    }
  }),
  recentlyUsed: selectRecentlyUsed(store.user.allApps, store.user.recentApplicationUsage, store.user.applicationSets),
  customGroups: selectAllApplicationSets(store.user.allApps, store.user.applicationSets).sort((a, b) => {
    if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
      return 1;
    } else {
      if (b.name?.toLowerCase() > a.name?.toLowerCase()) {
        return -1;
      } else {
        return 0;
      }
    }
  }),
  allApps: store.user.allApps,
  applicationSets: store.user.applicationSets,
  selected: store.user.selected,
  name: store.user.selected?.name
});

export default compose(
  connect(mapStateToProps, {
    loadUserInfo: loadUserInfoActions,
    selectGroupUserInfo: selectGroupUserInfoActions
  }),
  withApollo
)(Dashboard);
