import React, { useState, useCallback } from "react";
import { WidgetsBarContext } from "./context";

const WidgetsBarWrapper = ({ children }) => {
  const [widgetsBarOpen, setWidgetsBarOpen] = useState(false);
  const WidgetsBarToggle = useCallback((isWidgetOpen) => {
    setWidgetsBarOpen(isWidgetOpen);
  }, []);
  const widgetsBarClose = useCallback(() => {
    setWidgetsBarOpen(false);
  }, []);
  return (
    <WidgetsBarContext.Provider
      value={{
        widgetsBarOpen,
        WidgetsBarToggle,
        widgetsBarClose
      }}
    >
      {children}
    </WidgetsBarContext.Provider>
  );
};

export default WidgetsBarWrapper;
