import React, { useRef, useState } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import styled from "styled-components";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  FormControl,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { OwcBadge, OwcButton, OwcTypography } from "@one/react";

export const getCurrentDatas = (limit, pageVal, totalDatas) => {
  const startPoint = limit * pageVal;
  const endPoint = limit + limit * pageVal;
  return totalDatas.slice(startPoint, endPoint);
};

export const ButtonNext = ({ onPaginationChange, totalPages, currentPage }) => {
  return (
    <IconButton
      aria-label="Next page"
      data-testid="button-next-pagination"
      size="small"
      title="Next page"
      disabled={currentPage === totalPages}
      onClick={() => {
        onPaginationChange({ type: "NextPage" });
      }}
    >
      <NavigateNextIcon />
    </IconButton>
  );
};

export const ButtonPrev = ({ onPaginationChange, currentPage }) => {
  return (
    <IconButton
      aria-label="Prev page"
      data-testid="button-prev-pagination"
      size="small"
      title="Prev page"
      disabled={currentPage === 1}
      onClick={() => {
        onPaginationChange({ type: "PreviousPage" });
      }}
    >
      <NavigateBeforeIcon />
    </IconButton>
  );
};

export const FirstPage = ({ onPaginationChange, currentPage }) => {
  return (
    <IconButton
      aria-label="first page"
      data-testid="dlab-first-page"
      size="small"
      title="First page"
      disabled={currentPage === 1}
      onClick={() => {
        onPaginationChange({ type: "FirstPage" });
      }}
    >
      <FirstPageIcon />
    </IconButton>
  );
};

export const LastPage = ({ onPaginationChange, totalPages, currentPage }) => {
  return (
    <IconButton
      aria-label="last page"
      data-testid="dlab-last-page"
      size="small"
      title="Last page"
      disabled={currentPage === totalPages}
      onClick={() => {
        onPaginationChange({ type: "LastPage" });
      }}
    >
      <LastPageIcon />
    </IconButton>
  );
};

const PageRowNuberSelectorStyled = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  & > div {
    margin-left: 8px;
  }
`;

export const PageRowNuberSelector = ({ onPaginationChange, limit }) => {
  return (
    <PageRowNuberSelectorStyled>
      <div>Rows per page: </div>
      <FormControl variant="outlined">
        <Select
          size="small"
          margin="dense"
          labelId="paginator-row-number-selector"
          data-testid="paginator-row-number-selector"
          id="paginator-row-number-selector"
          value={limit}
          onChange={(event) => {
            onPaginationChange({ type: "Limit", value: event.target.value });
          }}
          MenuProps={{
            style: {
              zIndex: 1600
            }
          }}
        >
          <MenuItem data-testid="paginator-row-number-selector-item-v10" value={10}>
            10
          </MenuItem>
          <MenuItem data-testid="paginator-row-number-selector-item-v20" value={20}>
            20
          </MenuItem>
          <MenuItem data-testid="paginator-row-number-selector-item-v50" value={50}>
            50
          </MenuItem>
        </Select>
      </FormControl>
    </PageRowNuberSelectorStyled>
  );
};

const PaginationActionsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  background-color: var(--one-color-cobas-white);
  & > .pagination {
    display: flex;
    width: 50%;
    justify-content: center;
    padding: 0 0.5rem;
    align-items: center;
    & button:first-of-type {
      margin-left: 10px;
    }
  }
`;

/**
 *
 * @param {options} param0
 * @sampleStructure {
    actions: [
      {
        key: "add_equipment",
        value: "Add equipment",
        onClick: () => {
          console.log("add equipment");
        }
      },
      {
        key: "add_cluster",
        value: "Add cluster",
        onClick: () => {
          console.log("add cluster");
        }
      }
    ]
  }
 */

export const DLabGridPagination = ({
  onPaginationChange,
  limit,
  totalPages,
  currentPage,
  totalRows,
  optionsDisabled,
  showText = false,
  componentsAdded = 0,
  options = {
    actions: [],
    defaultLabel: "",
    prefixLabel: ""
  }
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClick = (event, item) => {
    item?.onClick(event);
  };

  const handleMenuItemClick = (event, key, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <PaginationActionsStyled>
      <div style={{ width: "auto" }}></div>
      <div className="pagination">
        <PageRowNuberSelector onPaginationChange={onPaginationChange} limit={limit} />
        <div>
          {limit * currentPage - limit + 1} -{" "}
          {limit + limit * currentPage - limit > totalRows ? totalRows : limit + limit * currentPage - limit} of{" "}
          {totalRows}
        </div>

        <FirstPage onPaginationChange={onPaginationChange} currentPage={currentPage} />
        <ButtonPrev onPaginationChange={onPaginationChange} currentPage={currentPage} />
        <ButtonNext onPaginationChange={onPaginationChange} totalPages={totalPages} currentPage={currentPage} />
        <LastPage
          onPaginationChange={onPaginationChange}
          totalPages={totalPages}
          currentPage={currentPage}
          totalRows={totalRows}
        />
      </div>
      {!options?.hideAction && (
        <>
          <div
            style={{
              display: "flex",
              width: "auto",
              alignItems: "center",
              justifyContent: "end",
              marginRight: "10px"
            }}
          >
            {options?.showText && (
              <>
                <OwcTypography variant="body2" style={{ marginRight: "10px" }}>
                  Components added
                </OwcTypography>
                <OwcBadge
                  type="active"
                  style={{
                    marginRight: "15px",
                    width: "24px",
                    height: "24px"
                  }}
                >
                  {options?.componentsAdded}
                </OwcBadge>
              </>
            )}
          </div>
          {options?.actions?.length > 0 && (
            <div
              style={{
                display: "flex",
                width: "auto",
                alignItems: "center",
                justifyContent: "end"
              }}
            >
              {options?.actions?.length === 1 && (
                <>
                  <OwcButton
                    variant={options?.actions[0]?.btnVariant || "primary"}
                    style={{
                      textTransform: "none"
                    }}
                    disabled={options?.actions[0]?.disabled || false}
                    onClick={(event) => {
                      handleClick(event, options?.actions[0]);
                    }}
                  >
                    {options?.actions[0]?.value}
                  </OwcButton>
                </>
              )}
              {options?.actions?.length > 1 && (
                <>
                  <ButtonGroup
                    variant="outlined"
                    size="small"
                    color="primary"
                    ref={anchorRef}
                    aria-label="split button"
                    disabled={optionsDisabled}
                  >
                    <Button
                      style={{
                        textTransform: "none",
                        width: "max-content"
                      }}
                      onClick={(event) => {
                        handleClick(event, options?.actions[selectedIndex]);
                      }}
                      size="small"
                    >
                      {selectedIndex === null
                        ? options?.defaultLabel
                        : options?.prefixLabel + options?.actions[selectedIndex]?.value}
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      aria-controls={open ? "split-button-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={handleToggle}
                    >
                      <ArrowDropDown />
                    </Button>
                  </ButtonGroup>
                  <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu">
                              {options?.actions?.map((option, index) => (
                                <MenuItem
                                  key={option?.key}
                                  selected={index === selectedIndex}
                                  onClick={(event) => handleMenuItemClick(event, option?.key, index)}
                                >
                                  {option?.value}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </div>
          )}
        </>
      )}
    </PaginationActionsStyled>
  );
};

export default DLabGridPagination;
