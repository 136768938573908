import { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboard } from "../../../landingPageUser/redux/actions";
import { WidgetsBarContext } from "../../widgetsBar/context/context";
import { GridContext } from "./context";

const GridWrapper = ({ children }) => {
  const dashboard = useSelector((state) => state.user.dashboard);
  const [isGridEditable, setIsGridEditable] = useState(false);
  const [pendingSaving, setPendingSaving] = useState(false);
  const [dashboardCopy, setDashboardCopy] = useState(null);
  const dispatch = useDispatch();
  const { WidgetsBarToggle } = useContext(WidgetsBarContext);

  const setEditable = (editable, recover = false) => {
    setIsGridEditable(editable);

    if (editable) {
      WidgetsBarToggle(false);
      setDashboardCopy(dashboard);
    }
    if (!editable) {
      if (recover) {
        dispatch(updateDashboard(dashboardCopy));
      }
      setDashboardCopy(null);
    }
  };

  return (
    <GridContext.Provider
      value={{
        isGridEditable,
        setEditable,
        pendingSaving,
        setPendingSaving,
        setIsGridEditable
      }}
    >
      {children}
    </GridContext.Provider>
  );
};

export default GridWrapper;
