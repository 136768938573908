import React, { useEffect, forwardRef, useState, useRef } from "react";
import styled from "styled-components";
import clsx from "clsx";
import { CircularProgress, Collapse } from "@mui/material";
import { OwcIcon } from "@one/react";
import { Module } from "../../constants";
import { generateID } from "@digitallab/grid-common-components";

const { applicationTable } = Module.PAGE.ADMIN;

const TableStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border-radius: 4px 4px 0px 0px;
  border-color: #d3d3d3;
  margin: 10px 0 0;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  & .body {
    background-color: var(--one-color-cobas-white);
  }
  & .head {
    background-color: var(--one-color-cobas-gray-150);
  }
  & .head,
  & .row {
    padding: 10px 18px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  & .row.expanded {
    &,
    &:hover {
      background: var(--one-color-blue-100);
    }
    &:not(.expanded-content) {
      border-bottom-color: transparent;
    }
    &.expanded-content {
      position: relative;
      &::before {
        content: "";
        border-bottom: 1px solid #e0ecf9;
        position: absolute;
        top: -10px;
        left: 18px;
        right: 18px;
      }
    }
  }
  & .row.selected {
    background: #e9f4ff;
  }
  & .row:hover {
    background: var(--one-color-gray-50);

    &.selected {
      background: var(--one-color-light-blue-300);
    }
  }
  & .body .cell,
  & .head .cell {
    margin: 0;
    line-height: 18px;
    font-size: 16px;
  }
  & .head .cell {
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  & .body .cell {
    font-weight: 400;
    line-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    :nth-child(2) {
      padding-left: 8px;
    }
  }
  & .body .loading {
    padding: 1rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  & .cell {
    flex: 1 1 auto;

    &.sortable {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  & .empty {
    text-align: center;
    padding: 10px 16px;
  }
  & .empty,
  & .head,
  & .row {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-radius: 4px;
    border-color: #d3d3d3;
  }
  & .fetchMore {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
  }
`;
const BodyTable = styled.div`
  @media only screen and (max-width: 960px) {
    overflow-y: hidden;
  }
`;
const isObject = (item) => typeof item === "object" && item !== null;

const CustomList = ({
  meta,
  data = [],
  onRequestSort,
  isReverseOrder,
  orderBy,
  onRowClick,
  loading,
  preExpanded,
  fetching,
  className,
  Component = null,
  setFilteredData,
  isSelected = () => false
}) => {
  const bodyRef = useRef();
  if (!isObject(meta)) {
    return null;
  }
  if (!isObject(meta?.fields)) {
    return null;
  }
  if (!Array.isArray(data)) {
    return null;
  }

  let body = null;

  if (loading) {
    body = (
      <div className="loading">
        <CircularProgress size={30} />
      </div>
    );
  } else if (data.length === 0) {
    body = (
      <div
        className="empty"
        id={generateID.UUID(applicationTable, `applicationtable`, "table_empty")}
        data-testid="custom-list-no-data"
      >
        No data
      </div>
    );
  } else {
    body = data.map((item, index) => {
      const id = item?.[meta?.rowId] || item?.id || (typeof meta?.getId === "function" ? meta?.getId(item) : index);
      return (
        <ItemRow
          expandable={meta?.expandable}
          ExpandedComponent={meta?.ExpandedComponent}
          item={item}
          key={id}
          id={id}
          fields={meta?.fields}
          preExpanded={preExpanded}
          bodyRef={bodyRef}
          selected={isSelected(item)}
          lastElement={false}
          onRowClick={onRowClick}
          meta={meta}
        />
      );
    });
  }

  return (
    <TableStyled
      id={generateID.UUID(applicationTable, `applicationtable`, "custom_table")}
      className={className}
      data-testid="custom-list"
    >
      <div
        className="head"
        data-testid="custom-list-head"
        id={generateID.UUID(applicationTable, `applicationtable`, "table_list")}
      >
        <Header meta={meta} onRequestSort={onRequestSort} isReverseOrder={isReverseOrder} orderBy={orderBy} />
      </div>
      <BodyTable
        className="body"
        ref={bodyRef}
        id={generateID.UUID(applicationTable, `applicationtable`, "table_body_style")}
      >
        {body}
        {fetching && (
          <div
            className="fetchMore"
            data-testid="custom-list-fetchmore"
            id={generateID.UUID(applicationTable, `applicationtable`, "table_fetchmore")}
          >
            <CircularProgress size={30} />
          </div>
        )}
      </BodyTable>
      {Component}
    </TableStyled>
  );
};
const ChooseDropDown = ({ isReverseOrder }) => {
  return isReverseOrder ? (
    <OwcIcon
      name="caret_up"
      type="filled"
      data-testid="arrow-drop-down-up-icon"
      id={generateID.UUID(applicationTable, `applicationtable`, "table_caret_up")}
    />
  ) : (
    <OwcIcon
      name="caret_down"
      type="filled"
      data-testid="arrow-drop-down-up-icon"
      id={generateID.UUID(applicationTable, `applicationtable`, "table_drop_up")}
    />
  );
};
const SortingHeader = ({ xkey, meta, orderBy, onRequestSort, isReverseOrder }) => {
  return (
    <div
      className={clsx({
        cell: true,
        sortable: meta.fields[xkey]?.sortable
      })}
      data-testid={`list-head-${xkey}`}
      key={xkey}
      {...(meta?.fields?.[xkey]?.headProps ?? {})}
      onClick={() => {
        if (xkey === orderBy) {
          onRequestSort(xkey, !isReverseOrder);
        } else {
          onRequestSort(xkey, false);
        }
      }}
      id={generateID.UUID(applicationTable, `applicationtable`, "div_list_head")}
    >
      {meta?.fields?.[xkey]?.text}
      {xkey !== orderBy ? (
        <OwcIcon
          name="caret_down"
          type="filled"
          id={generateID.UUID(applicationTable, `applicationtable`, "table_caret_down_1")}
        />
      ) : (
        <ChooseDropDown isReverseOrder={isReverseOrder} />
      )}
    </div>
  );
};

const HeaderName = ({ xkey, meta }) => {
  return (
    <div
      className="cell"
      data-testid={`list-head-${xkey}`}
      key={xkey}
      {...(meta?.fields?.[xkey]?.headProps ?? {})}
      id={generateID.UUID(applicationTable, `applicationtable_${xkey}`, "table_headername")}
    >
      {meta?.fields?.[xkey]?.text}
    </div>
  );
};
export const Header = ({ meta, onRequestSort, isReverseOrder, orderBy }) => {
  return Object.keys(meta.fields).map((key) => {
    const Component = meta?.fields?.[key]?.headerComponent ? meta?.fields?.[key]?.headerComponent : null;
    if (!Component) {
      return meta.fields[key]?.sortable ? (
        <SortingHeader
          xkey={key}
          meta={meta}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          isReverseOrder={isReverseOrder}
          id={generateID.UUID(applicationTable, `applicationtable_${key}`, "table_sortHeader")}
        />
      ) : (
        <HeaderName
          xkey={key}
          meta={meta}
          id={generateID.UUID(applicationTable, `applicationtable_${key}`, "table_headerName")}
        />
      );
    }
    return (
      <div
        className={clsx({
          cell: true,
          sortable: meta.fields[key]?.sortable
        })}
        data-testid={`list-head-${key}`}
        key={key}
        {...(meta?.fields?.[key]?.headProps ?? {})}
        id={generateID.UUID(applicationTable, `applicationtable`, "list_div")}
      >
        <Component {...(meta?.fields?.[key]?.headProps ?? {})} />
      </div>
    );
  });
};
const mapItemsToRows = ({ fields, id, item, toggleExpanded, shouldExpand, selected }) => {
  return Object.keys(fields).map((field) => {
    const Component = fields?.[field]?.component ? fields?.[field].component : null;
    if (!Component) {
      return (
        <div
          key={`item-row-${id}-${field}`}
          data-testid={`item-row-${id}-${field}`}
          className="cell"
          {...(fields?.[field]?.cellProps ?? {})}
          id={generateID.UUID(applicationTable, `applicationtable_${id}_${field}`, "table_item_row")}
        >
          {item[field] && item[field] !== "null" ? item[field] : "-"}
        </div>
      );
    }
    return (
      <div
        className="cell"
        key={`item-row-${id}-${field}`}
        data-testid={`item-row-${id}-${field}`}
        {...(fields?.[field]?.cellProps ?? {})}
        id={generateID.UUID(applicationTable, `applicationtable_${id}_${field}`, "table_item_row_cell")}
      >
        <Component
          item={item}
          {...(fields?.[field]?.cellProps ?? {})}
          toggleExpanded={toggleExpanded}
          expanded={shouldExpand}
          selected={selected}
        />
      </div>
    );
  });
};

export const ItemRow = forwardRef(
  (
    {
      item,
      fields,
      id,
      expandable,
      ExpandedComponent = React.Fragment,
      preExpanded,
      selected,
      lastElement,
      onRowClick,
      meta
    },
    elementRef
  ) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const ref = useRef();

    const toggleExpanded = () => {
      setIsExpanded((val) => !val);
    };

    useEffect(() => {
      if (preExpanded === item && expandable === true) {
        ref.current.scrollIntoView();
        setIsExpanded(true);
      }
    }, [ref, preExpanded, item, expandable]);

    const shouldExpand = isExpanded && expandable === true;

    return (
      <div
        ref={lastElement ? elementRef : null}
        id={generateID.UUID(applicationTable, `applicationtable`, "table_itemrow_div")}
      >
        <div
          onClick={(event) => onRowClick && onRowClick(event, item)}
          className={clsx("row", {
            selected,
            expanded: isExpanded
          })}
          data-testid={`${id}-row`}
          ref={ref}
          id={generateID.UUID(applicationTable, `applicationtable_${id}`, "table_itemrow_div")}
        >
          {mapItemsToRows({
            fields,
            id,
            item,
            toggleExpanded,
            shouldExpand,
            selected
          })}
        </div>
        <Collapse in={shouldExpand} id={generateID.UUID(applicationTable, `applicationtable_${id}`, "table_collapse")}>
          {expandable === true && (
            <div
              className={clsx("row", "expanded-content", {
                selected,
                expanded: isExpanded
              })}
              data-testid={`${id}-row-expanded`}
              id={generateID.UUID(applicationTable, `applicationtable`, "table_collapse_expanded")}
            >
              <ExpandedComponent
                id={generateID.UUID(applicationTable, `applicationtable`, "table_expanded")}
                item={item}
                fields={fields}
                {...(meta.expandedComponentProps ?? {})}
              />
            </div>
          )}
        </Collapse>
      </div>
    );
  }
);

export default CustomList;
