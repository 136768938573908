import React from "react";
import styled from "styled-components";

const CLOUD_FRONT_PATH = process.env.REACT_APP_GRAPHQL_ENDPOINT_CLOUDFRONT;
export const IconPreviewStyled = styled.img`
  box-sizing: border-box;
  object-fit: cover;
  display: block;
`;

const IconPreview = ({ file, icon, iconPath, ...rest }) => {
  const date = new Date();
  if (file) {
    return <IconPreviewStyled data-testid="file-preview" src={URL.createObjectURL(file)} {...rest} />;
  }
  if (iconPath) {
    return (
      <IconPreviewStyled
        data-testid="icon-preview"
        src={`${CLOUD_FRONT_PATH}${iconPath}?dummy=${date.getTime()}`}
        {...rest}
      />
    );
  }
  if (icon) {
    return (
      <i data-testid="default-preview" className="one-icons-legacy" {...rest}>
        {icon}
      </i>
    );
  }
  return (
    <i
      className="one-icons-legacy"
      data-testid="folder-preview"
      style={{
        width: 48,
        height: 48,
        marginRight: 12,
        textAlign: "center",
        verticalAlign: "baseline",
        lineHeight: "48px",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        borderRadius: "4px",
        color: "var(--one-color-gray-600)",
        fontSize: "20px"
      }}
    >
      folder
    </i>
  );
};

export default IconPreview;
