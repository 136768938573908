import { useState, useEffect } from "react";

export function useOnlineEndpointStatus(url = "", timeout_in_ms = 3000) {
  const [onlineEndpointStatus, setOnlineEndpointStatus] = useState(true);
  useEffect(() => {
    let timeout = null;
    const checkEndpoint = async () => {
      try {
        const response = await fetch(url, {
          method: "POST"
        });
        if (![403].includes(response.status)) {
          setOnlineEndpointStatus(true);
        } else {
          setOnlineEndpointStatus(false);
        }
      } catch (err) {
        setOnlineEndpointStatus(false);
      } finally {
        timeout = setTimeout(checkEndpoint, timeout_in_ms);
      }
    };
    checkEndpoint();
    return () => {
      clearTimeout(timeout);
    };
  }, [url, timeout_in_ms]);

  return onlineEndpointStatus;
}
