import styled, { css } from "styled-components";
import { OwcInput } from "@one/react";

export const AppRow = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  @media only screen and (max-width: 960px) {
    flex-wrap: wrap;
  }
  && > * {
    flex: 1 1 50%;
    margin-right: 1rem;
    @media only screen and (max-width: 960px) {
      flex: 1 1 100%;
    }
  }
`;

export const ApplicationIcon = styled.div`
  margin-left: 15px;
  padding-right: 12px;
`;
export const ApplicationTitleLabel = styled.div`
  font-size: 18px;
  text-decoration: none;
  color: black;
  font-weight: 500;
`;

const appItemSelected = css`
  border: 1px solid var(--one-color-blue-900);
`;
export const AppItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--one-color-cobas-white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--one-color-gray-300);
  border-radius: var(--one-border-radius-4);
  opacity: 1;
  width: 312px;
  height: 64px;
  max-width: calc(50% - 1rem);
  @media only screen and (max-width: 960px) {
    max-width: 100%;
    && + * {
      margin-top: 1rem;
    }
  }

  ${(props) => props.isSelected && appItemSelected}
  ${ApplicationIcon} {
    flex-shrink: 0;
    flex-grow: 0;
  }
  ${ApplicationTitleLabel} {
    flex-shrink: 1;
    flex-grow: 1;
    margin-left: 1rem;
  }
  .checkbox {
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

export const InputCustomGroupName = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0px;
  color: var(--one-color-gray-600);
  opacity: 1;

  @media only screen and (max-width: 960px) {
    &:last-child {
      margin-top: 1rem;
    }
  }
`;
const modalInputMobile = css`
   {
    min-width: 100%;
    max-width: initial;
    margin: 0;
  }
`;
export const ModalInput = styled(OwcInput)`
  && {
    ${(props) => props.isMobile && modalInputMobile}
  }
`;

export const ApplicationList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -1rem;
`;
