import React, { useRef } from "react";
import { BANNER_COLORS, Module, NOTIFICATIONS_BANNER } from "../../constants";
import { OwcListItem, OwcSelectDropdown, OwcIcon } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";

const { adminTopBannerMessage } = Module.PAGE.ADMIN;

export const CustomSingleSelect = ({
  formik,
  name,
  options,
  value,
  propLabel,
  propValue,
  onChange,
  testid,
  selectLabel,
  emptyOption = false,
  displayEmpty = false,
  emptyOptionText = "Select all",
  SelectProps,
  InputLabelProps,
  renderValue,
  showColor = false,
  style,
  ...rest
}) => {
  const selectDropdownElement = useRef(null);

  return (
    <OwcSelectDropdown
      style={style}
      ref={selectDropdownElement}
      data-testid={testid}
      id={testid}
      label={selectLabel}
      name={name}
      value={[value]}
      onSelectChange={(e) => {
        const index = e.detail[0];
        onChange(options[index][propLabel] ?? options[index]);
      }}
    >
      {emptyOption && <OwcListItem key="emptyoptionText">emptyOptionText</OwcListItem>}
      {options?.map((item, index) => {
        const option = propValue ? item?.[propValue] : item;
        const findColor = Object.keys(NOTIFICATIONS_BANNER).filter((key) => NOTIFICATIONS_BANNER[key] === item.value);
        const ICON = BANNER_COLORS[findColor]?.Icon;
        const ICONTYPE = BANNER_COLORS[findColor]?.IconType;
        const headingBGColor = BANNER_COLORS[findColor]?.Heading;
        const mainBGColor = BANNER_COLORS[findColor]?.TextToDisplay;
        return (
          <>
            <OwcListItem
              value={option}
              key={option}
              data-testid={`${testid}-${option}`}
              id={generateID.UUID(adminTopBannerMessage, `customSingle_${testid}-${option}`, "item")}
            >
              <div
                style={{ display: "flex", alignItems: "center" }}
                id={generateID.UUID(adminTopBannerMessage, `customSingle_${testid}-${option}`, "div_item")}
              >
                <div id={generateID.UUID(adminTopBannerMessage, `customSingle_${testid}-${option}`, "div_item_1")}>
                  <OwcIcon
                    name={ICON}
                    type={ICONTYPE}
                    style={{
                      marginRight: 10,
                      color: headingBGColor,
                      background: mainBGColor
                    }}
                    id={generateID.UUID(adminTopBannerMessage, `customSingle_${testid}-${option}`, "icon")}
                  />
                </div>
                <div id={generateID.UUID(adminTopBannerMessage, `customSingle_${testid}-${option}`, "div_item_label")}>
                  {propLabel ? item?.[propLabel] : item}
                </div>
              </div>
            </OwcListItem>
          </>
        );
      })}
    </OwcSelectDropdown>
  );
};

export default CustomSingleSelect;
