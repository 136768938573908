import gql from "graphql-tag";
import { NOTIFICATIONS_LIMIT } from "../../constants";

/**
 * {
  "roleFilter": [ {"allowedRoles": {"contains": "StatusUpdateReaders"}} ]
 * }
 * @type {DocumentNode}
 */

export const LIST_APPLICATIONS = gql`
  query listDigitalLabLandingPageApplications($roleFilter: [ModelDigitalLabLandingPageApplicationFilterInput]) {
    listDigitalLabLandingPageApplications(filter: { or: $roleFilter }) {
      items {
        id
        name
        description
        icon
        iconPath
        type
        entryPoints {
          id
          url
          name
        }
        applicationGroupIDs
        updatedAt
        allowedRoles
      }
    }
  }
`;
export const LIST_APPLICATIONS_FOR_ADMIN = gql`
  query listDigitalLabLandingPageApplications {
    listDigitalLabLandingPageApplications {
      items {
        id
        name
        description
        icon
        iconPath
        type
        entryPoints {
          id
          url
          name
        }
        applicationGroupIDs
        updatedAt
        allowedRoles
      }
    }
  }
`;
export const LIST_APPLICATION_GROUPS = gql`
  query listDigitalLabLandingPageApplicationGroups {
    listDigitalLabLandingPageApplicationGroups {
      items {
        id
        parentID
        name
        icon
        iconPath
        updatedAt
      }
    }
  }
`;

export const LIST_WIDGETS = gql`
  query listWidgets($limit: Int, $nextToken: String) {
    listWidgets(limit: $limit, nextToken: $nextToken) {
      items {
        description
        id
        metadataURL
        name
        releaseDate
        version
        widgetURL
        fromAppName
        fromAppSource
        supportedSizes {
          height
          mockupURL
          width
        }
      }
      nextToken
    }
  }
`;

export const LIST_APPLICATION_GROUPS_MENU = gql`
  query {
    listDigitalLabLandingPageApplicationGroups {
      items {
        id
        name
        icon
      }
    }
  }
`;

export const GET_BACKEND_HEALTH_CHECK = gql`
  query getBackendHealthCheck($id: ID!, $moduleName: String!) {
    getBackendHealthCheck(id: $id, moduleName: $moduleName) {
      createdAt
      id
      moduleName
      moduleVersion
      updatedAt
    }
  }
`;

export const LIST_DIGITAL_LAB_LANDING_PAGE_NOTIFICATIONS = gql`
  query ListDigitalLabLandingPageNotifications {
    listDigitalLabLandingPageNotifications(limit: ${NOTIFICATIONS_LIMIT + 1}) {
      items {
        createdAt
        details
        dueDate
        expiresAt
        link
        id
        message
        time
        sender
        payload
        severity
        source
        type
        updatedAt
        user
      }
    }
  }
`;

export const NOTIFICATIONS_BY_USER_SEVERITY_AND_TIME = gql`
  query notificationsByUserSeverityAndTime(
    $user: String
    $sortDirection: ModelSortDirection
    $nextToken: String
    $limit: Int
    $filter: ModelDigitalLabLandingPageNotificationFilterInput
  ) {
    listDigitalLabLandingPageNotifications: notificationsByUserSeverityAndTime(
      limit: $limit
      sortDirection: $sortDirection
      user: $user
      nextToken: $nextToken
      filter: $filter
    ) {
      items {
        createdAt
        details
        dueDate
        expiresAt
        link
        id
        message
        time
        sender
        payload
        severity
        source
        type
        updatedAt
        user
      }
      nextToken
    }
  }
`;

export const NOTIFICATIONS_BY_USER_AND_TIME = gql`
  query notificationsByUserAndTime(
    $user: String
    $sortDirection: ModelSortDirection
    $nextToken: String
    $limit: Int
    $filter: ModelDigitalLabLandingPageNotificationFilterInput
    $time: ModelStringKeyConditionInput
  ) {
    listDigitalLabLandingPageNotifications: notificationsByUserAndTime(
      limit: $limit
      sortDirection: $sortDirection
      user: $user
      nextToken: $nextToken
      filter: $filter
      time: $time
    ) {
      items {
        createdAt
        details
        dueDate
        expiresAt
        link
        id
        message
        time
        sender
        payload
        severity
        source
        type
        updatedAt
        user
      }
      nextToken
    }
  }
`;

export const LIST_BACKEND_HEALTH_CHECKS = gql`
  query listBackendHealthChecks {
    listBackendHealthChecks {
      items {
        ciJobId
        commitHash
        createdAt
        id
        moduleName
        moduleVersion
        enableAnalyticsTracking
        updatedAt
      }
    }
  }
`;

export const LIST_SITES = gql`
  query listSites($limit: Int, $nextToken: String) {
    listSites(limit: $limit, nextToken: $nextToken) {
      items {
        siteName
        timezone
      }
      nextToken
    }
  }
`;

export const LIST_DASHBOARDS = gql`
  query listDashboards($nextToken: String) {
    listDashboards(nextToken: $nextToken) {
      items {
        id
        widgets {
          locationX
          locationY
          widgetId
          size {
            height
            mockupURL
            width
          }
        }
      }
      nextToken
    }
  }
`;

export const LIST_LANDING_PAGE_NOTIFICATIONS_SOURCES = gql`
  query listLandingPageNotificationsSources($nextToken: String, $limit: Int) {
    listLandingPageNotificationsSources(nextToken: $nextToken, limit: $limit) {
      items {
        source
      }
      nextToken
    }
  }
`;

export const GET_DIGITAL_LAB_PAGE_EQUIPMENTS = gql`
  query getDigitalLabLandingPageEquipment($inventoryId: String!) {
    getDigitalLabLandingPageEquipment(inventoryId: $inventoryId) {
      belongingToGroup
      buildingLocation {
        key
        value
      }
      cluster
      clusterId
      comment
      configurationBaseline
      equipmentId
      createdAt
      costCenter
      csv
      dateOfLastMaintanance
      dateOfNextMaintanance
      dateOfNextPeriodicReview
      displayImage
      electronicRecord
      electronicSignatures
      entryType
      equipmentAdministrator
      equipmentCategory
      equipmentId
      equipmentNickName
      equipmentModel
      inventoryId
      floor {
        key
        value
      }
      functionalLocation
      gxpRelevant
      instrumentGTIN
      instrumentRUDI
      inventoryNumber
      isBookable
      isVisualized
      linkedInstance
      location
      maintenancePlan
      manufacturer
      materialNumber
      modelName
      qualificationStatus
      responsiblePerson
      responsibleProxy
      secondaryID
      secondaryIDMapping
      serialNumber
      siteId
      siteName
      siteTimezone
      softwareVersion
      status
      systemOwner
      systemStatus
      shortIdentifier
      variant
      subEquipment {
        items {
          belongingToGroup
          buildingLocation {
            key
            value
          }
          cluster
          clusterId
          comment
          configurationBaseline
          costCenter
          csv
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          displayImage
          electronicRecord
          electronicSignatures
          entryType
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          floor {
            key
            value
          }
          functionalLocation
          gxpRelevant
          installedTests {
            name
            version
          }
          instrumentGTIN
          instrumentRUDI
          inventoryNumber
          inventoryId
          isBookable
          isVisualized
          linkedInstance
          location
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          qualificationStatus
          responsiblePerson
          responsibleProxy
          room {
            key
            value
          }
          secondaryID
          secondaryIDMapping
          updatedAt
          serialNumber
          siteId
          siteName
          siteTimezone
          softwareVersion
          sop {
            key
            value
          }
          status
          systemOwner
          systemStatus
          variant
        }
      }
    }
  }
`;

export const LIST_DIGITAL_LAB_EQUIPMENT = gql`
  query listDigitalLabLandingPageEquipments(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLandingPageEquipmentFilterInput
    $subFilter: ModelDigitalLabLandingPageEquipmentFilterInput
  ) {
    listDigitalLabLandingPageEquipments(limit: $limit, nextToken: $nextToken, filter: $filter) {
      items {
        belongingToGroup
        buildingLocation {
          key
          value
        }
        cluster
        clusterId
        comment
        configurationBaseline
        equipmentId
        createdAt
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentNickName
        equipmentModel
        inventoryId
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        instrumentGTIN
        instrumentRUDI
        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        qualificationStatus
        responsiblePerson
        responsibleProxy
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        status
        systemOwner
        systemStatus
        shortIdentifier
        variant
        subEquipment(sortDirection: $sortDirection, filter: $subFilter) {
          items {
            belongingToGroup
            buildingLocation {
              key
              value
            }
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryNumber
            inventoryId
            isBookable
            isVisualized
            linkedInstance
            location
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            qualificationStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            updatedAt
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            variant
          }
        }
      }
      nextToken
    }
  }
`;
export const GET_LOGGED_USER_DETAILS_FAV_EQUIPMENTS = gql`
  query getLoggedInUserDetails($siteName: String) {
    getLoggedInUserDetails(filter: { site: { eq: $siteName } }, limit: 1) {
      items {
        name
        favoriteEquipments(limit: $limit) {
          items {
            equipment {
              belongingToGroup
              configurationBaseline
              equipmentId
              createdAt
              dateOfLastMaintanance
              displayImage
              equipmentNickName
              equipmentModel
              entryType
              inventoryId
              instrumentGTIN
              instrumentRUDI
              isBookable
              location
              manufacturer
              materialNumber
              responsiblePerson
              serialNumber
              siteName
              status
              siteTimezone
              softwareVersion
              systemStatus
              shortIdentifier
              secondaryID
              secondaryIDMapping
              updatedAt
              subEquipment {
                items {
                  belongingToGroup
                  cluster
                  clusterId
                  comment
                  entryType
                  equipmentCategory
                  equipmentId
                  equipmentModel
                  equipmentNickName
                  inventoryId
                  materialNumber
                  manufacturer
                  modelName
                  qualificationStatus
                  responsiblePerson
                  secondaryID
                  secondaryIDMapping
                  serialNumber
                  shortIdentifier
                  siteName
                  instrumentGTIN
                  instrumentRUDI
                  inventoryNumber
                  isBookable
                  isVisualized
                  linkedInstance
                  location
                  maintenancePlan
                  manufacturer
                  materialNumber
                  modelName
                  status
                }
              }
            }
            id
          }
        }
      }
    }
  }
`;

export const LIST_EQUIPMENT_BY_SITE_NAME_AND_EQUIPMENT_NICK_NAME = gql`
  query listEquipmentBySiteNameAndEquipmentNickName(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLandingPageEquipmentFilterInput
    $subFilter: ModelDigitalLabLandingPageEquipmentFilterInput
    $sortDirection: ModelSortDirection
    $siteName: String
    $equipmentNickName: ModelStringKeyConditionInput
  ) {
    listEquipmentBySiteNameAndEquipmentNickName(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      siteName: $siteName
      equipmentNickName: $equipmentNickName
    ) {
      items {
        belongingToGroup
        buildingLocation {
          key
          value
        }
        cluster
        clusterId
        comment
        configurationBaseline
        equipmentId
        createdAt
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentNickName
        equipmentModel
        inventoryId
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        instrumentGTIN
        instrumentRUDI
        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        qualificationStatus
        responsiblePerson
        responsibleProxy
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        status
        systemOwner
        systemStatus
        shortIdentifier
        variant
        subEquipment(sortDirection: $sortDirection, filter: $subFilter) {
          items {
            belongingToGroup
            buildingLocation {
              key
              value
            }
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryId
            inventoryNumber
            isBookable
            isVisualized
            linkedInstance
            location
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            qualificationStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            updatedAt
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            variant
          }
        }
      }
      nextToken
    }
  }
`;
export const LIST_EQUIPMENT_BY_SITE_NAME_AND_SERIAL_NUMBER = gql`
  query listEquipmentBySiteNameAndSerialNumber(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLandingPageEquipmentFilterInput
    $subFilter: ModelDigitalLabLandingPageEquipmentFilterInput
    $sortDirection: ModelSortDirection
    $siteName: String
    $serialNumber: ModelStringKeyConditionInput
  ) {
    listEquipmentBySiteNameAndSerialNumber(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      siteName: $siteName
      serialNumber: $serialNumber
    ) {
      items {
        belongingToGroup
        buildingLocation {
          key
          value
        }
        cluster
        clusterId
        comment
        configurationBaseline
        equipmentId
        createdAt
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentNickName
        equipmentModel
        inventoryId
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        instrumentGTIN
        instrumentRUDI
        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        qualificationStatus
        responsiblePerson
        responsibleProxy
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        status
        systemOwner
        systemStatus
        shortIdentifier
        variant
        subEquipment(sortDirection: $sortDirection, filter: $subFilter) {
          items {
            belongingToGroup
            buildingLocation {
              key
              value
            }
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryNumber
            inventoryId
            isBookable
            isVisualized
            linkedInstance
            location
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            qualificationStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            updatedAt
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            variant
          }
        }
      }
      nextToken
    }
  }
`;
export const LIST_EQUIPMENT_BY_SITE_NAME_AND_EQUIPMENT_MODEL = gql`
  query listEquipmentBySiteNameAndEquipmentModel(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLandingPageEquipmentFilterInput
    $subFilter: ModelDigitalLabLandingPageEquipmentFilterInput
    $sortDirection: ModelSortDirection
    $siteName: String
    $equipmentModel: ModelStringKeyConditionInput
  ) {
    listEquipmentBySiteNameAndEquipmentModel(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      siteName: $siteName
      equipmentModel: $equipmentModel
    ) {
      items {
        belongingToGroup
        buildingLocation {
          key
          value
        }
        cluster
        clusterId
        comment
        configurationBaseline
        equipmentId
        createdAt
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentNickName
        equipmentModel
        inventoryId
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        instrumentGTIN
        instrumentRUDI
        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        qualificationStatus
        responsiblePerson
        responsibleProxy
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        status
        systemOwner
        systemStatus
        shortIdentifier
        variant
        subEquipment(sortDirection: $sortDirection, filter: $subFilter) {
          items {
            belongingToGroup
            buildingLocation {
              key
              value
            }
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryId
            inventoryNumber
            isBookable
            isVisualized
            linkedInstance
            location
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            qualificationStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            updatedAt
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            variant
          }
        }
      }
      nextToken
    }
  }
`;
export const LIST_EQUIPMENT_BY_SITE_NAME_AND_LOCATION = gql`
  query listEquipmentBySiteNameAndLocation(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLandingPageEquipmentFilterInput
    $subFilter: ModelDigitalLabLandingPageEquipmentFilterInput
    $sortDirection: ModelSortDirection
    $siteName: String
    $location: ModelStringKeyConditionInput
  ) {
    listEquipmentBySiteNameAndLocation(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      siteName: $siteName
      location: $location
    ) {
      items {
        belongingToGroup
        buildingLocation {
          key
          value
        }
        cluster
        clusterId
        comment
        configurationBaseline
        equipmentId
        createdAt
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentNickName
        equipmentModel
        inventoryId
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        instrumentGTIN
        instrumentRUDI
        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        qualificationStatus
        responsiblePerson
        responsibleProxy
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        status
        systemOwner
        systemStatus
        shortIdentifier
        variant
        subEquipment(sortDirection: $sortDirection, filter: $subFilter) {
          items {
            belongingToGroup
            buildingLocation {
              key
              value
            }
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryNumber
            inventoryId
            isBookable
            isVisualized
            linkedInstance
            location
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            qualificationStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            updatedAt
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            variant
          }
        }
      }
      nextToken
    }
  }
`;
export const LIST_EQUIPMENT_BY_SITE_NAME_AND_BELONGING_TO_GROUP = gql`
  query listEquipmentBySiteNameAndBelongingToGroup(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLandingPageEquipmentFilterInput
    $subFilter: ModelDigitalLabLandingPageEquipmentFilterInput
    $sortDirection: ModelSortDirection
    $siteName: String
    $belongingToGroup: ModelStringKeyConditionInput
  ) {
    listEquipmentBySiteNameAndBelongingToGroup(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      siteName: $siteName
      belongingToGroup: $belongingToGroup
    ) {
      items {
        belongingToGroup
        buildingLocation {
          key
          value
        }
        cluster
        clusterId
        comment
        configurationBaseline
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        instrumentGTIN
        instrumentRUDI
        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        qualificationStatus
        responsiblePerson
        responsibleProxy
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        status
        systemOwner
        systemStatus
        variant
        subEquipment(sortDirection: $sortDirection, filter: $subFilter) {
          items {
            belongingToGroup
            buildingLocation {
              key
              value
            }
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryNumber
            isBookable
            isVisualized
            linkedInstance
            location
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            qualificationStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            variant
          }
        }
      }
      nextToken
    }
  }
`;
export const LIST_EQUIPMENT_BY_SITE_NAME_AND_SOFTWARE_VERSION = gql`
  query listEquipmentBySiteNameAndSoftwareVersion(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLandingPageEquipmentFilterInput
    $subFilter: ModelDigitalLabLandingPageEquipmentFilterInput
    $sortDirection: ModelSortDirection
    $siteName: String
  ) {
    listEquipmentBySiteNameAndSoftwareVersion(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      siteName: $siteName
    ) {
      items {
        belongingToGroup
        buildingLocation {
          key
          value
        }
        cluster
        clusterId
        comment
        configurationBaseline
        equipmentId
        createdAt
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentNickName
        equipmentModel
        inventoryId
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        instrumentGTIN
        instrumentRUDI
        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        qualificationStatus
        responsiblePerson
        responsibleProxy
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        status
        systemOwner
        systemStatus
        shortIdentifier
        variant
        subEquipment(sortDirection: $sortDirection, filter: $subFilter) {
          items {
            belongingToGroup
            buildingLocation {
              key
              value
            }
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryNumber
            isBookable
            isVisualized
            linkedInstance
            location
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            qualificationStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            updatedAt
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            variant
          }
        }
      }
      nextToken
    }
  }
`;
export const LIST_EQUIPMENT_BY_SITE_NAME_AND_EQUIPMENT_ID = gql`
  query listEquipmentBySiteNameAndEquipmentId(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLandingPageEquipmentFilterInput
    $subFilter: ModelDigitalLabLandingPageEquipmentFilterInput
    $sortDirection: ModelSortDirection
    $siteName: String
    $equipmentId: ModelStringKeyConditionInput
  ) {
    listEquipmentBySiteNameAndEquipmentId(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      siteName: $siteName
      equipmentId: $equipmentId
    ) {
      items {
        belongingToGroup
        buildingLocation {
          key
          value
        }
        cluster
        clusterId
        comment
        configurationBaseline
        equipmentId
        createdAt
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentNickName
        equipmentModel
        inventoryId
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        instrumentGTIN
        instrumentRUDI
        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        qualificationStatus
        responsiblePerson
        responsibleProxy
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        status
        systemOwner
        systemStatus
        shortIdentifier
        variant
        subEquipment(sortDirection: $sortDirection, filter: $subFilter) {
          items {
            belongingToGroup
            buildingLocation {
              key
              value
            }
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryNumber
            isBookable
            isVisualized
            linkedInstance
            location
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            qualificationStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            updatedAt
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            variant
          }
        }
      }
      nextToken
    }
  }
`;
export const LIST_DIGITAL_LAB_LANDING_PAGE_USER_FILTER = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        id
        userFilters {
          items {
            filterName
            filterDefinition
          }
        }
      }
    }
  }
`;

export const LIST_NOTIFICATION_BANNER = gql`
  query MyQuery {
    listNotificationBanners(limit: 10, nextToken: null) {
      items {
        color
        displayText
        enabled
        heading
        id
        infoURl
      }
    }
  }
`;

export const GET_LOGGED_USER_DETAILS = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        id
        name
        email
        familyName
        givenName
        user
        lastFilter
        phone
        site
        applicationSets {
          id
          name
          icon
          applicationReferences {
            applicationID
            applicationEntryPointID
          }
        }
        recentApplicationUsage {
          id
          lastUsed
          applicationRef {
            applicationID
            applicationEntryPointID
          }
        }
      }
    }
  }
`;

export const LIST_IR_FILTER_LOOKUPS_MASTER = gql`
  query listIRFilterLookups($limit: Int, $nextToken: String, $field: String) {
    listIRFilterLookups(limit: $limit, nextToken: $nextToken, filterAttribute: $field, sortDirection: ASC) {
      nextToken
      items {
        filterValue
      }
    }
  }
`;
