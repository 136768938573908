import { OwcListItem, OwcTooltip } from "@one/react";
import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { Module } from "../../../constants";
import { selectNotificationUserInfo } from "../../landingPageUser/redux/actions";
import ActionOfNotify from "../ActionOfNotify";
import DateOfNotify from "../DateOfNotify";
import ShortDescriptionOfNotify from "../ShortDescriptionOfNotify";
import SeverityCircleNotify from "./SeverityCircleNotify";
import { generateID } from "@digitallab/grid-common-components";

const { notificationName } = Module.PAGE.NOTIFICATION;
export const ItemOfShortViewNotify = forwardRef(({ notification, onClick, mobileView, lastElement }, elementRef) => {
  const listDivItemId = generateID.UUID(notification.id, `itemofshort`, "list_div");
  const listItemId = generateID.UUID(notification.id, `itemofshort`, "list_item") + notification.id;
  return (
    <div ref={lastElement ? elementRef : null} id={generateID.UUID(notificationName, `itemofshort`, "div_flex")}>
      <OwcListItem style={{ display: "flex", padding: 10 }} id={listItemId}>
        <div
          style={{
            // overflow to disable default owc tooltip, custom one required
            overflow: 'hidden',
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
          id={listDivItemId}
        >
          <SeverityCircleNotify severity={notification.severity} />
          <ShortDescriptionOfNotify source={`${notification.source} `} description={`${notification.message} `} />
          <DateOfNotify date={notification.time} />
          <ActionOfNotify link={notification.link} short id={notification.id} />
        </div>
      </OwcListItem>
      <OwcTooltip placement="bottom" style={{ maxWidth: '600px' }} anchor={listItemId}>
        {notification.details.split('\n').map((detail, index) => <div key={index}>
          {detail}
        </div>)}
      </OwcTooltip>
    </div>
  );
});

export default connect(
  null,
  {
    onClick: selectNotificationUserInfo
  },
  null,
  {
    forwardRef: true
  }
)(ItemOfShortViewNotify);
