import { Auth } from "aws-amplify";
import { get } from "lodash";
import React, { useEffect, useReducer } from "react";
import { withApollo } from "react-apollo";
import { SITE_TOKEN_PATH } from "../../constants";
import { LIST_SITES } from "../../gql/landingapi/queries";
import { getAllData } from "../../utils/helpers/fetching";
import { getDigitalLabLandingPageUserProfile } from "../landingPageUser/LandingPageUser";
import { SiteContext } from "./context";
import { changeSiteAttribute } from "./helpers";

export const UNKNOWN = "Unknown";

const updateAttribute = async (profileSite, tokenSite) => {
  if (profileSite && !tokenSite) {
    await changeSiteAttribute({
      site: profileSite
    });
  }
};

export const initialState = {
  loading: true,
  refresh: 0,
  userProfile: null,
  currentSite: null,
  selected: null,
  items: [],
  error: null
};

export function reducer(state = initialState, action = { type: "" }) {
  switch (action?.type) {
    case "loadData":
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case "refresh":
      return {
        ...state,
        loading: true,
        refresh: state.refresh + 1
      };
    case "select":
      let obj = {};
      if (state?.currentSite?.siteName === UNKNOWN) {
        obj = {
          ...state,
          selected: action.payload,
          currentSite: action.payload
        };
      } else {
        obj = {
          ...state,
          selected: action.payload
        };
      }

      return obj;
    default:
      return state;
  }
}

const SiteContextWrapper = ({ children, client, initialValues = {} }) => {
  const [state, dispatchAction] = useReducer(reducer, {
    ...initialState,
    ...initialValues
  });
  useEffect(() => {
    const loadData = async () => {
      const { items, error } = await getAllData({
        client,
        query: LIST_SITES,
        dataPath: ["data", "listSites"]
      });
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
      const userProfile = await getDigitalLabLandingPageUserProfile({
        client,
        email: currentAuthenticatedUser?.signInUserSession?.idToken?.payload?.email
      });
      const tokenSite = get(currentAuthenticatedUser, SITE_TOKEN_PATH);
      const profileSite = userProfile?.digitalLabLandingPageUserProfile?.site;
      const _currentSite = tokenSite || profileSite;
      await updateAttribute(profileSite, tokenSite);
      const selectFound = items.find((x) => x.siteName === _currentSite) || {
        siteName: UNKNOWN,
        timezone: ""
      };
      dispatchAction({
        type: "loadData",
        payload: {
          items,
          error,
          userProfile,
          currentSite: selectFound,
          selected: selectFound
        }
      });
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.refresh]);

  return (
    <SiteContext.Provider
      value={{
        ...state,
        dispatchAction
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

export default withApollo(SiteContextWrapper);
