import React from "react";
import get from "lodash/get";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE } from "../../gql/landingapi/mutations";
import { omit, pick, xorWith, isEqual } from "lodash";
import { loadUserInfo as loadUserInfoAction } from "../landingPageUser/redux/actions";
import Snackbar from "../snackbar/Snackbar";
import { OwcIcon } from "@one/react";

export const pickKeys = (set) => {
  const _set = omit(set, "__typename");
  return {
    ..._set,
    applicationReferences: _set.applicationReferences.map((y) => pick(y, ["applicationID", "applicationEntryPointID"]))
  };
};

const ApplicationFavouriteButton = ({
  application,
  loadUserInfo,
  applicationSets,
  id,
  email,
  client,
  entryPointSelected
}) => {
  const favs =
    get(
      applicationSets.filter((x) => x.name === "favourites"),
      "[0]"
    ) || [];
  const handleFavChange = async () => {
    let newApplicationSets = [];
    try {
      newApplicationSets = applicationSets.map((set) => {
        const _set = pickKeys(set);
        return _set.name === "favourites"
          ? {
              ..._set,
              applicationReferences: xorWith(
                _set.applicationReferences || [],
                [
                  {
                    applicationID: application.id,
                    applicationEntryPointID: entryPointSelected
                  }
                ],
                isEqual
              )
            }
          : _set;
      });
      loadUserInfo({ applicationSets: newApplicationSets });
      const updatedDigitalLabLandingPageUserProfile = await client.mutate({
        mutation: UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE,
        variables: { id, email, applicationSets: newApplicationSets }
      });
      const _applicationSets = get(
        updatedDigitalLabLandingPageUserProfile,
        "data.updateDigitalLabLandingPageUserProfile.applicationSets"
      );
      loadUserInfo({ applicationSets: _applicationSets });
    } catch (err) {
      const newSet = get(newApplicationSets, "[0].applicationReferences.length") || 0;
      const prevSet = get(applicationSets, "[0].applicationReferences.length") || 0;
      const diff = newSet - prevSet > 0;
      loadUserInfo({ applicationSets });
      Snackbar({
        appName: application?.name,
        text: diff
          ? "was not added to favourites, please try again later."
          : "was not removed from favourites, please try again"
      });
      console.warn(err);
    }
  };
  const isMarked = Array.isArray(favs?.applicationReferences)
    ? !!favs.applicationReferences.find(
        (x) => x.applicationID === application.id && x.applicationEntryPointID === entryPointSelected
      )
    : false;

  return (
    <OwcIcon
      type="legacy"
      name="star_filled"
      style={{
        color: isMarked ? "var(--one-color-background-warning-1)" : "var(--one-color-foreground-neutral-1)",
        cursor: "pointer",
        fontSize: 20,
        paddingRight: 4
      }}
      onClick={(e) => {
        e.stopPropagation();
        handleFavChange();
      }}
      data-testid="appFavBtn"
    />
  );
};

const mapStateToProps = (store) => ({
  applicationSets: store.user.applicationSets,
  id: store.user.id,
  email: store.user.email
});

export default compose(
  connect(mapStateToProps, {
    loadUserInfo: loadUserInfoAction
  }),
  withApollo
)(ApplicationFavouriteButton);
