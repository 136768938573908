import React, { useContext, useRef } from "react";
import { EquipmentsContext } from "./equipments-context/context";
import { OwcChip } from "@one/react";
import { DEFAULT_FILTER, DEFAULT_MYLAB_FILTER } from "../../constants";

const MyLabFilterChips = ({ deleteMyLabFilter }) => {
  const { myLabFiltersList, appliedFilter, setAppliedFilter, isEdit } = useContext(EquipmentsContext);
  const chipElement = useRef(null);
  return isEdit
    ? myLabFiltersList.map((x, index) => (
        <OwcChip
          id={`chip-elemet-${index}`}
          ref={chipElement}
          onRemove={() => deleteMyLabFilter(x.filterName)}
          outlined={true}
          removable={!(DEFAULT_MYLAB_FILTER.filterName === x.filterName)}
          disabled={DEFAULT_MYLAB_FILTER.filterName === x.filterName}
          selected={appliedFilter?.filterName === x.filterName}
          style={{ marginRight: 8, marginBottom: 8 }}
          onClick={() => {
            if (x.filterName === appliedFilter?.filterName) {
              setAppliedFilter(DEFAULT_FILTER);
            } else {
              setAppliedFilter(x);
            }
          }}
        >
          {x.filterName}
        </OwcChip>
      ))
    : myLabFiltersList
        .filter((x) => x.filterName !== DEFAULT_MYLAB_FILTER.filterName)
        .map((x, index) => (
          <OwcChip
            id={`chip-elemet-${index}`}
            ref={chipElement}
            onRemove={() => deleteMyLabFilter(x.filterName)}
            outlined={true}
            removable={!(DEFAULT_MYLAB_FILTER.filterName === x.filterName)}
            selected={appliedFilter?.filterName === x.filterName}
            style={{ marginRight: 8, marginBottom: 8 }}
            onClick={() => {
              if (x.filterName === appliedFilter?.filterName) {
                setAppliedFilter(DEFAULT_FILTER);
              } else {
                setAppliedFilter(x);
              }
            }}
          >
            {x.filterName}
          </OwcChip>
        ));
};

export default MyLabFilterChips;
