import { LIST_APPLICATIONS } from "../../gql/landingapi/queries";
import { getAllApplications } from "../landingPageUser/LandingPageUser";

export const getApplicationUpdate = async ({ client, allGroups }) => {
  const { allApps } = await getAllApplications({
    query_apps: LIST_APPLICATIONS,
    client,
    allGroups
  });
  return { allApps };
};
