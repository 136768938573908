import styled from "styled-components";
import moment from "moment";

const MainBoxBase = styled.div`
  display: grid;
  grid-gap: 20px 12px;
  grid-template-columns: auto auto;
  padding-top: 4px;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const Value = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #000000;
`;

const WidgetsMetadata = ({ metadata }) => {
  return (
    <MainBoxBase>
      <Label>Metadata URL</Label>
      <Value>{metadata?.metadataURL || "-"}</Value>
      <Label>From app</Label>
      <Value>{metadata?.fromAppName || metadata?.fromAppSource || "-"}</Value>
      <Label>Version</Label>
      <Value>{metadata?.version || "-"}</Value>
      <Label>Release</Label>
      <Value>{metadata?.releaseDate ? moment(metadata?.releaseDate).format("DD-MMM-YYYY") : "-"}</Value>
    </MainBoxBase>
  );
};

export default WidgetsMetadata;
