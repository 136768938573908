import React from "react";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import styled, { css } from "styled-components";
import {
  loadUserInfo as loadUserInfoActions,
  selectCategoryUserInfo as selectCategoryUserInfoActions,
  selectGroupUserInfo as selectGroupUserInfoActions
} from "../landingPageUser/redux/actions";
import CustomGroupedApps from "./CustomGroupedApps";
import { useMobile } from "../../utils/hooks/useMobile";

const listCardChildrenMobile = css`
  flex: 0 1 150px;
  margin-right: 6px;
`;
const ListCardStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 5px;
  margin-bottom: 20px;
  padding-bottom: 40px;
  & > * {
    margin-bottom: 5px;
    margin-right: 20px;
    flex: 0 1 150px;
    ${(props) => props.isMobile && listCardChildrenMobile}
  }
  @media only screen and (max-width: 415px) {
    justify-content: space-between;
  }
`;
export const FolderIconNew = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 400;
  min-height: 6px;
`;

export const GroupEdit = React.createContext(123);
const CustomCategoryAppsList = ({
  customGroups = [],
  Component,
  applications = [],
  id,
  email,
  applicationSets,
  loadUserInfo,
  selectGroupUserInfo,
  selectCategoryUserInfo,
  client,
  name,
  groupedType,
  selected
}) => {
  const isMobile = useMobile();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuFor, setMenuFor] = React.useState(null);
  const handleClickMenu = (event, customGroup) => {
    setAnchorEl(event.target);
    setMenuFor(customGroup);
  };
  return (
    <>
      <ListCardStyled data-testid="custom-categories-list" isMobile={isMobile}>
        {customGroups.map((customGroup, theId) => (
          <CustomGroupedApps
            customGroup={customGroup}
            theId={theId}
            Component={Component}
            handleClickMenu={handleClickMenu}
            selectGroupUserInfo={selectGroupUserInfo}
            selectCategoryUserInfo={selectCategoryUserInfo}
            anchorEl={anchorEl}
            menuFor={menuFor}
            selected={selected}
            groupedType={groupedType}
          />
        ))}
      </ListCardStyled>
    </>
  );
};

export const mapStateToPropsAllApps = (store) => ({
  applications: store.user.allApps,
  id: store.user.id,
  email: store.user.email,
  applicationSets: store.user.applicationSets,
  selected: store.user.selected
});
export default compose(
  connect(mapStateToPropsAllApps, {
    loadUserInfo: loadUserInfoActions,
    selectCategoryUserInfo: selectCategoryUserInfoActions,
    selectGroupUserInfo: selectGroupUserInfoActions
  }),
  withApollo
)(CustomCategoryAppsList);
