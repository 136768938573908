import { filter } from "../ApplicationEditForm";
// import { GridAutocomplete } from "@digitallab/grid-common-components";
// import { GridAutoCompleteStyles } from "../../../../components/shared/GridAutoCompleteStyles";
import {
  Autocomplete,
  Chip,
  FormControl,
  Input
  // InputLabel
} from "@mui/material";

import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";

const AddVisibilityForUserRolesInput = ({
  allowedRoles,
  application,
  updateApplication,
  setDirtyInputs,
  dirtyInputs
}) => {
  return (
    <>
      {/*  <GridAutocomplete
        data-testid="application-edit-form-autocomplete-roles"
        optionsList={allowedRoles}
        label={"Visibility for user roles"}
        size="small"
        sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
        key={allowedRoles}
        value={application.allowedRoles}
        disableClearable
        onAutoCompleteChange={(selectedValue) => {
          updateApplication({ value: selectedValue, key: "allowedRoles" });
        }}
        id={generateID.UUID(Module.PAGE.ADMIN.addVisibilityForUserRolesInput, `visibility`, "auto_complete")}
        style={{ width: "250px", marginRight: 22 }}
      /> */}
      {
        <div>
          <Autocomplete
            data-testid="application-edit-form-autocomplete-roles"
            fullWidth
            id={generateID.UUID(Module.PAGE.ADMIN.addVisibilityForUserRolesInput, `visibility`, "form_control")}
            ListboxProps={{
              "data-testid": "application-edit-form-autocomplete-roles-list"
            }}
            options={allowedRoles}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <FormControl
                ref={params.InputProps.ref}
                fullWidth
                variant="filled"
                data-testid="admin-panel-modal-formcontrol-roles"
              >
                <Input
                  data-testid="admin-panel-modal-input-roles"
                  inputProps={params.inputProps}
                  label="Visibility for user roles"
                  variant="filled"
                  id={generateID.UUID(Module.PAGE.ADMIN.addVisibilityForUserRolesInput, `visibility`, "input")}
                  {...params.InputProps}
                />
              </FormControl>
            )}
            multiple
            value={application.allowedRoles}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push(params.inputValue);
              }

              return filtered;
            }}
            onChange={(event, newValue) => {
              updateApplication({
                value: newValue,
                key: "allowedRoles"
              });
            }}
            onBlur={() =>
              setDirtyInputs({
                ...dirtyInputs,
                allowedRoles: true
              })
            }
            renderTags={(selectedAllowedRoles, getTagProps) => (
              <div id={generateID.UUID(Module.PAGE.ADMIN.addVisibilityForUserRolesInput, `visibility`, "div")}>
                {selectedAllowedRoles?.map((allowedRole, index) => (
                  <Chip
                    id={generateID.UUID(Module.PAGE.ADMIN.addVisibilityForUserRolesInput, `visibility`, "clip")}
                    data-testid="application-edit-form-select-roles-chip"
                    key={allowedRole}
                    variant="outlined"
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    label={selectedAllowedRoles.find((role) => role === allowedRole)}
                    {...getTagProps({ index })}
                  />
                ))}
              </div>
            )}
          />
        </div>
      }
    </>
  );
};
export default AddVisibilityForUserRolesInput;
