import React, { useEffect, useState } from "react";
import { EquipmentsContext } from "./context";
import { withApollo } from "react-apollo";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { LIST_DIGITAL_LAB_LANDING_PAGE_USER_FILTER } from "../../../gql/landingapi/queries";
import { getAllData } from "../../../utils/helpers/fetching";
import { DEFAULT_FILTER, DEFAULT_MYLAB_FILTER } from "../../../constants";

const EquipmentsWrapper = ({ children, client }) => {
  const [myLabEquipment, setMyLabEquipment] = useState([]);
  const [myLabFiltersList, setMyLabFiltersList] = useState([]);
  const [myLabCountInitial, setMyLabCountInitial] = useState([]);
  const [appliedFilter, setAppliedFilter] = useState(DEFAULT_MYLAB_FILTER);
  const [isEdit, setEdit] = useState(false);

  // Track page view
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: "My Lab",
      href: `${window.location?.origin}/MyLab`
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    inititateMyLabEquipment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inititateMyLabEquipment = async () => {
    let userFilters = await fetchingMyLabEquipment();
    const myLabfilters = userFilters.find((x) => x.filterName === DEFAULT_MYLAB_FILTER.filterName);
    if (myLabfilters !== undefined) {
      if (typeof myLabfilters.filterDefinition === "string") {
        myLabfilters.filterDefinition = JSON.parse(myLabfilters.filterDefinition);
      }
      if (
        myLabfilters?.filterDefinition?.inventoryIds !== undefined &&
        myLabfilters?.filterDefinition?.inventoryIds &&
        myLabfilters?.filterDefinition?.inventoryIds.length > 0
      ) {
        setMyLabEquipment(myLabfilters?.filterDefinition?.inventoryIds);
      }
    }
    setMyLabFiltersList(userFilters);
  };

  const fetchingMyLabEquipment = async () => {
    const userFilterData = await getAllData({
      query: LIST_DIGITAL_LAB_LANDING_PAGE_USER_FILTER,
      client,
      dataPath: ["data", "getLoggedInUserDetails"],
      drillData: true
    });
    let userFilters = userFilterData?.items[0]?.userFilters?.items || [];
    let myLabStoreInitial = [];
    userFilters = userFilters.filter((x) => x.filterName !== DEFAULT_FILTER.filterName);
    if (userFilters.length === 0) {
      let tempDefault = { ...DEFAULT_MYLAB_FILTER };
      tempDefault.isDefaultAdded = true;
      userFilters.push(tempDefault);
    } else {
      let myLabfilters = userFilters.find((x) => x.filterName === DEFAULT_MYLAB_FILTER.filterName);
      if (myLabfilters === undefined) {
        let tempDefault = { ...DEFAULT_MYLAB_FILTER };
        myLabfilters = tempDefault;
        tempDefault.isDefaultAdded = true;
        userFilters.push(tempDefault);
      }
      myLabStoreInitial = myLabfilters?.filterDefinition?.inventoryIds || [];
      if (myLabStoreInitial === undefined && myLabfilters !== undefined) {
        myLabStoreInitial = JSON.parse(myLabfilters?.filterDefinition) || {
          inventoryIds: []
        };
        setMyLabCountInitial(myLabStoreInitial?.inventoryIds);
      } else {
        setMyLabCountInitial(myLabStoreInitial);
      }
    }
    return userFilters;
  };
  return (
    <EquipmentsContext.Provider
      value={{
        myLabEquipment,
        setMyLabEquipment,
        myLabFiltersList,
        setMyLabFiltersList,
        appliedFilter,
        setAppliedFilter,
        fetchingMyLabEquipment,
        isEdit,
        setEdit,
        myLabCountInitial,
        setMyLabCountInitial
      }}
    >
      {children}
    </EquipmentsContext.Provider>
  );
};

export default withApollo(EquipmentsWrapper);
