import { useState } from "react";

const useModal = (isOpen = false) => {
  const [open, setOpen] = useState(isOpen);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return [open, handleOpen, handleClose];
};
export default useModal;
