import styled from "styled-components";
import Actions from "./Actions";
import { changeStringToDate } from "../../../utils/helpers/text";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable.js";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const WidgetTableMeta = {
  fields: {
    name: {
      sortable: true,
      text: DATA_MODEL_TABLE.widgetName.value,
      headProps: {
        style: {
          flex: "0 1 40%"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 40%"
        }
      }
    },
    fromApp: {
      sortable: true,
      text: DATA_MODEL_TABLE.fromApp.value,
      component: ({ item }) => item.fromAppName || item.fromAppSource,
      headProps: {
        style: {
          flex: "0 1 15%"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 15%",
          paddingRight: "10px"
        }
      }
    },
    version: {
      sortable: true,
      text: DATA_MODEL_TABLE.widgetVersion.value,
      headProps: {
        style: {
          flex: "0 1 10%"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 10%"
        }
      }
    },
    releaseDate: {
      sortable: true,
      text: DATA_MODEL_TABLE.releaseDate.value,
      component: ({ item }) => changeStringToDate(item?.releaseDate),
      headProps: {
        style: {
          flex: "0 1 15%"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 15%"
        }
      }
    },
    description: {
      sortable: true,
      text: DATA_MODEL_TABLE.widgetDescription.value,
      component: ({ item }) =>
        item?.description?.split("\n").map((line) => (
          <>
            {line}
            <br />
          </>
        )),
      headProps: {
        style: {
          flex: "0 1 15%"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 15%"
        }
      }
    },
    actions: {
      component: Actions,
      text: "",
      headProps: {
        style: {
          flex: "0 0 10%",
          display: "flex",
          justifyContent: "flex-end"
        }
      },
      cellProps: {
        style: {
          flex: "0 0 10%",
          display: "flex",
          justifyContent: "flex-end"
        }
      }
    }
  }
};

export default WidgetTableMeta;
