const EQUIP_DATA_MODEL_TABLE = {
  equipmentModel: {
    key: "equipmentModel",
    value: "Model"
  },
  serialNumber: {
    key: "serialNumber",
    value: "Serial number"
  },
  equipmentId: {
    key: "equipmentId",
    value: "Equipment ID"
  },
  equipmentNickName: {
    key: "equipmentNickName",
    value: "Nickname"
  },
  location: {
    key: "location",
    value: "Location"
  },
  softwareVersion: {
    key: "softwareVersion",
    value: "SW version"
  },
  belongingToGroup: {
    key: "belongingToGroup",
    value: "Group"
  },
  status: {
    key: "status",
    value: "Equipment status"
  }
};

export default EQUIP_DATA_MODEL_TABLE;
