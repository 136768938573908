import styled from "styled-components";

export const DetailsCellStyled = styled.div`
  & > :first-child {
    font-size: 14px;
    line-height: 20px;
  }
  & > :last-child {
    font-size: 14px;
    line-height: 19px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-all;
  }
`;
