import { useContext, useState } from "react";
import { deleteNotification } from "./deleteNotification";
import { withApollo } from "react-apollo";
import { FiltersContext } from "./fullViewOfNotification/FiltersContext/context";
import { IconButton } from "@mui/material";
import { OwcIcon } from "@one/react";
import { Module } from "../../constants";
import { generateID } from "@digitallab/grid-common-components";

const { notificationName } = Module.PAGE.NOTIFICATION;

const DeleteNotifyButton = ({ id, client }) => {
  const [pendingDelete, setPendingDelete] = useState(false);
  const { dispatchFiltersAction } = useContext(FiltersContext);
  const handleDelete = async () => {
    setPendingDelete(true);

    try {
      await deleteNotification({
        id,
        client
      });
    } catch (e) {}

    setPendingDelete(false);
    dispatchFiltersAction({ type: "nextData" });
  };
  return (
    <IconButton
      id={generateID.UUID(notificationName, `delete_action`, "button")}
      data-testid="delete-notify-button"
      disabled={pendingDelete}
      onClick={(event) => {
        event.stopPropagation();
        handleDelete();
      }}
    >
      <OwcIcon
        name="delete"
        type="outlined"
        data-testid="delete-notify-button-icon"
        id={generateID.UUID(notificationName, `delete_action`, "icon")}
      />
    </IconButton>
  );
};

export default withApollo(DeleteNotifyButton);
