import React from "react";
import DashboardActionButtons from "./DashboardActionButtons";
import GridWrapper from "./grid/context/GridWrapper";
import DashboardLibGrid from "./grid/DashboardLibGrid";
import WidgetsBar from "./widgetsBar/WidgetsBar";
import WidgetsBarMainView from "./widgetsBar/WidgetsBarMainView";
import WidgetsBarWrapper from "./widgetsBar/context/WidgetsBarWrapper";
import GetWidgets from "./GetWidgets";
import { LoadImportMap } from "./qfront/LoadImportMap";
import "./qfront/events/EventsResolver";
import AllWidgetsWrapper from "../adminPanel/widgets/context/AllWidgetsWrapper";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { OwcTypography } from "@one/react";
import styles from "./DashboardPage.module.scss";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../constants";

const DashboardPage = () => {
  // Track page view
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView({
      documentTitle: "Dashboard",
      href: `${window.location?.origin}/Dashboard`
    });
    // eslint-disable-next-line
  }, []);

  return (
    <AllWidgetsWrapper id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard`, "widget")}>
      <div className={styles.dashbStyled} id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard_style`, "div")}>
        <WidgetsBarWrapper id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard`, "wrapper")}>
          <GridWrapper id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard`, "grid")}>
            <GetWidgets />
            <WidgetsBar>
              <WidgetsBarMainView />
            </WidgetsBar>
            <div
              data-testid={"app-group-dashboard"}
              id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard_group`, "div")}
            >
              <div
                className={styles.SectionName}
                id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard_drawer`, "section")}
              >
                <OwcTypography
                  variant="title5"
                  id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard_drawer`, "title")}
                >
                  Dashboard
                </OwcTypography>
                <DashboardActionButtons />
              </div>
              <div className={styles.DashboardBackground}>
                <LoadImportMap>
                  <DashboardLibGrid />
                </LoadImportMap>
              </div>
            </div>
          </GridWrapper>
        </WidgetsBarWrapper>
      </div>
    </AllWidgetsWrapper>
  );
};

export default DashboardPage;
