import React, { useCallback, useState } from "react";
import { debounce } from "lodash";
import { useMobile } from "../../../utils/hooks/useMobile";
import { ModalInput } from "../../applications/customGroupModalDialogContentStyleds";
import { DEBOUNCE_DELAY, Module } from "../../../constants";
import { OwcIcon } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";

const { appAndCategories } = Module.PAGE.ADMIN;

const Search = ({ setQuery, label, testid = "search", placeholder, compact }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const isMobile = useMobile();
  /* eslint-disable react-hooks/exhaustive-deps */
  const delayedQuery = useCallback(
    debounce((q) => setQuery(q), DEBOUNCE_DELAY), // updated to 1 sec
    [setQuery]
  );
  const onChange = (e) => {
    setSearchQuery(e.target.value);
    delayedQuery(e.target.value);
  };
  const onClear = () => {
    setQuery("");
    setSearchQuery("");
  };
  return (
    <ModalInput
      data-testid={testid}
      label={label}
      placeholder={placeholder}
      isMobile={isMobile}
      color="primary"
      variant="filled"
      size="small"
      value={searchQuery}
      onInputChange={onChange}
      compact={compact}
      id={generateID.UUID(appAndCategories, `category`, "search_input_modal")}
    >
      <OwcIcon
        type="outlined"
        slot="prefix"
        name="search"
        id={generateID.UUID(appAndCategories, `category`, "icon_search")}
      />
      {searchQuery && (
        <OwcIcon
          type="filled"
          slot="suffix"
          name="circle_clear"
          onClick={() => onClear()}
          id={generateID.UUID(appAndCategories, `category`, "icon_search_clear")}
          className="pointer"
        />
      )}
    </ModalInput>
  );
};

export default Search;
