import {
  CognitoIdentityProviderClient,
  GetUserCommand,
  UpdateUserAttributesCommand
} from "@aws-sdk/client-cognito-identity-provider";
import { Auth } from "aws-amplify";

export const changeSiteAttribute = async ({ site }) => {
  if (!site) {
    return;
  }
  const client = new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_AWS_APPSYNC_REGION
  });
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = user?.signInUserSession?.accessToken?.jwtToken;
  const commandUpdate = new UpdateUserAttributesCommand({
    AccessToken: jwtToken,
    UserAttributes: [
      {
        Name: "custom:site",
        Value: site
      }
    ]
  });
  await client.send(commandUpdate);
  const command = new GetUserCommand({ AccessToken: jwtToken });
  const response = await client.send(command);
  if (!response?.UserAttributes.some((attr) => attr.Name === "custom:site" && attr.Value === site)) {
    throw new Error("Attribute was not updated!");
  }
};
