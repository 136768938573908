import React, { useCallback, useState } from "react";
import { debounce } from "lodash";
import { connect } from "react-redux";
import { setSearchQuery as setSearchQueryActions } from "./redux/actions";
import { getSearchQueryReset } from "../../selectors";
import { DEBOUNCE_DELAY } from "../../constants";
import { LoadingStyled } from "./SearchView";
import { CircularProgress } from "@mui/material";
import { OwcIcon } from "@one/react";
import { ModalInput } from "../applications/customGroupModalDialogContentStyleds";
import { useContext } from "react";
import { SearchToolContext } from "./context";

const SearchAllTools = ({ setQuery, reset, onClearMobile }) => {
  const { searchAppQuery, setSearchAppQuery } = useContext(SearchToolContext);
  const [isLoading, setIsLoading] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  const delayedQuery = useCallback(
    debounce((q) => {
      setQuery(q);
      setIsLoading(false);
    }, DEBOUNCE_DELAY), // updated to 1 sec
    [setQuery]
  );
  const onChange = (value) => {
    setIsLoading(true);
    setSearchAppQuery(value);
    delayedQuery(value);
  };
  const onClear = () => {
    setQuery("");
    setSearchAppQuery("");
    if (onClearMobile) {
      onClearMobile();
    }
  };

  return (
    <>
      <ModalInput
        data-testid="application-search-input"
        id="standard-secondary"
        label="Search apps"
        variant="filled"
        size="medium"
        value={searchAppQuery}
        style={{ width: "100%" }}
        onInputChange={(e) => {
          console.log(e.target.value);
          if (e.target.value !== "") {
            onChange(e.target.value);
          }
        }}
      >
        <OwcIcon type="outlined" slot="prefix" name="search" />
        {searchAppQuery && (
          <OwcIcon
            type="filled"
            slot="suffix"
            name="circle_clear"
            data-testid="clear-search-button"
            // onClick={() => searchApps("")}
            onClick={onClear}
            className="pointer"
          />
        )}
        {isLoading && (
          <LoadingStyled>
            <CircularProgress size={20} />
          </LoadingStyled>
        )}
      </ModalInput>
    </>
  );
};

export default connect(
  (store) => ({
    reset: getSearchQueryReset(store)
  }),
  {
    setQuery: setSearchQueryActions
  }
)(SearchAllTools);
