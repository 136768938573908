import { useContext, useState, useEffect } from "react";
import WidgetListForBar from "./widgetsBarList/WidgetListForBar";
import { AllWidgetsContext } from "../../adminPanel/widgets/context/context";
import { filterByQuery } from "../../categories/helpers";
import Search from "../../adminPanel/appAndCategories/Search";
import { format } from "date-fns";

const ListOfWidgets = () => {
  const { widgets } = useContext(AllWidgetsContext);
  const [filteredData, setFilteredData] = useState(widgets);
  const [query, setQuery] = useState("");
  const [orderBy, setOrderBy] = useState(["name"]);
  const [isReverseOrder, setIsReverseOrder] = useState(false);

  const filterData = (q) => {
    setFilteredData(
      filterByQuery(
        widgets.map((w) => ({
          ...w,
          fromApp: w.fromAppName || w.fromAppSource,
          releaseDateFormatted: format(new Date(w.releaseDate), "dd-MMM-yyyy")
        })),
        q,
        ["name", "fromApp", "version", "releaseDateFormatted", "description"],
        orderBy,
        isReverseOrder
      )
    );
  };

  useEffect(() => {
    if (widgets !== null) {
      filterData(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgets, orderBy, isReverseOrder]);
  return (
    <>
      <div style={{ marginLeft: 10 }}>
        <Search
          label="Search for widget"
          testid={"widget-search-for-dashboard-bar"}
          setQuery={(q) => {
            setQuery(q);
            filterData(q);
          }}
        />
      </div>
      <WidgetListForBar
        widgets={widgets}
        isReverseOrder={isReverseOrder}
        setIsReverseOrder={setIsReverseOrder}
        filteredData={filteredData}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
      />
    </>
  );
};

export default ListOfWidgets;
