import React, { useContext } from "react";
import { WidgetsBarContext } from "./context/context";
import { OwcDrawer } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";
export const drawerWidth = 380;

const WidgetsBar = ({ children }) => {
  // const { widgetsBarOpen, widgetsBarClose } = useContext(WidgetsBarContext);
  const { widgetsBarOpen } = useContext(WidgetsBarContext);

  return (
    <div>
      <OwcDrawer
        id={generateID.UUID(Module.PAGE.DASHBOARD.name, `dashboard_drawer`, "widget")}
        hideBackdrop
        visible={widgetsBarOpen}
        style={{ position: "static", height: "100%" }}
        placement="right"
      >
        <div slot="panel" style={{ width: "400px" }}>
          {children}
        </div>
      </OwcDrawer>
      {/* <Drawer
          data-testid="widgets-drawer"
          variant={"persistent"}
          open={widgetsBarOpen}
          onClose={widgetsBarClose}
          containerHeight={containerHeight}
          anchor="right"
        > */}
    </div>
  );
};

export default WidgetsBar;
