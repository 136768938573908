export function handleEntryPointUpdateFactory({ setEntryPoint, entryPoint, application, setUniqueNameEntryPoint }) {
  return (dataToChange) => {
    setEntryPoint({
      ...entryPoint,
      [dataToChange.key]: dataToChange.value
    });
    if (dataToChange.key === "name") {
      if (
        application.entryPoints.some(
          (theEntryPoint) => theEntryPoint.name === dataToChange.value && theEntryPoint.id !== entryPoint.id
        )
      ) {
        setUniqueNameEntryPoint(false);
      } else {
        setUniqueNameEntryPoint(true);
      }
    }
  };
}
