import { AuthStorage } from "@digitallab/grid-common-components";

export const federated = {
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
};

const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: `${window.location?.origin}/`,
    redirectSignOut: `https://${process.env.REACT_APP_COGNITO_PROVIDER_ID}/sp/startSLO.ping?TargetResource=${window.location?.origin}/`,
    responseType: "code"
  },
  Auth: {
    storage: AuthStorage
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
};

export default awsmobile;
