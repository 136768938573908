export const updateAppSelectionFactory = (setCustomGroupForm, customGroupForm) => {
  return (checked, application) => {
    if (checked) {
      setCustomGroupForm({
        ...customGroupForm,
        appsWithEntryPoints: [
          ...customGroupForm.appsWithEntryPoints,
          {
            application,
            entryPoints: [application.entryPoints[0].id]
          }
        ]
      });
    } else {
      setCustomGroupForm({
        ...customGroupForm,
        appsWithEntryPoints: customGroupForm.appsWithEntryPoints.filter((x) => x.application !== application)
      });
    }
  };
};
