export function updateCustomGroupNameFactory(updateNameUnique, updateNameValid, setCustomGroupForm, customGroupForm) {
  return (groupName) => {
    updateNameUnique(groupName);
    updateNameValid(groupName);
    setCustomGroupForm({
      ...customGroupForm,
      name: groupName
    });
  };
}
