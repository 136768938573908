import throttle from "lodash/throttle";
import { isNotificationSupported } from "./notificationPermissions";

const TITLE_NOTIFICATION = "Landing page";

export const askForPermissions = async (message) => {
  if (!isNotificationSupported()) {
    return;
  }
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      return new Notification("Landing page notification", { body: message });
    }
  } catch (e) {
    console.error("@@@ Safari permissions: ", e);
    Notification.requestPermission((permission) => {
      if (permission === "granted") {
        return new Notification("Landing page notification", { body: message });
      }
    });
  }
};

export const systemNotify = ({ message, source, type, counter }) => {
  if (!isNotificationSupported()) {
    return;
  }
  if (Notification.permission === "granted" && document.hidden) {
    const newNotification = new Notification(`${TITLE_NOTIFICATION}`, {
      body: `From: ${source}\nCount: ${counter}\nSeverity: ${type}\n${message}`,
      icon: "/logo192.png",
      vibrate: true
    });
    newNotification.addEventListener("click", function () {
      window.focus();
      this.close();
    });
    return newNotification;
  }
};

export const throttledNotify = throttle(systemNotify, 15000, {
  trailing: true
});
