import React, { useEffect } from "react";
import styled from "styled-components";
import ApplicationEditForm from "./ApplicationEditForm";
import { emptyApplication } from "./emptyApplication";
import BUTTON_MODEL_LABEL from "../../../utils/constants/buttonModelLabel";
import { OwcModalDialog, OwcButton } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";

export const ApplicationTitleLabel = styled.div`
  font-size: 18px;
  text-decoration: none;
  color: black;
  font-weight: 500;
`;

const emptyArray = [];

const ApplicationModal = ({
  open,
  cancel,
  save,
  categories = emptyArray,
  title,
  application = emptyApplication,
  allowedRoles,
  applications
}) => {
  const [applicationForm, setApplicationForm] = React.useState({
    ...application
  });
  const [isValid, setIsValid] = React.useState(false);
  const [file, setFile] = React.useState(null);

  useEffect(() => {
    if (application) {
      setApplicationForm({
        ...application
      });
    } else {
      setApplicationForm({
        ...emptyApplication
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);
  return (
    <OwcModalDialog
      visible={open}
      disableBackdropClick
      size="sm"
      id={generateID.UUID(Module.PAGE.ADMIN.applicationModal, `appAndCategories`, "dialog")}
      className="owcmodalZIndex"
    >
      {open && (
        <div
          id={generateID.UUID(Module.PAGE.ADMIN.applicationModal, `appAndCategories`, "div")}
          style={{ height: 350, maxHeight: 350, minHeight: 350 }}
        >
          <ApplicationEditForm
            isApplicationNull={application === null}
            applications={applications}
            categories={categories}
            application={applicationForm}
            allowedRoles={allowedRoles}
            onUpdate={setApplicationForm}
            onValid={() => setIsValid(true)}
            onInvalid={() => setIsValid(false)}
            file={file}
            setFile={setFile}
          />
        </div>
      )}
      <div slot="header" id={generateID.UUID(Module.PAGE.ADMIN.applicationModal, `appAndCategories_header`, "dialog")}>
        {title}
      </div>
      <div
        slot="actions"
        style={{ display: "flex", justifyContent: "right" }}
        id={generateID.UUID(Module.PAGE.ADMIN.applicationModal, `appAndCategories_actions`, "dialog")}
      >
        <OwcButton
          data-testid="admin-panel-modal-application-action-button-cancel"
          variant="secondary"
          onClick={() => cancel()}
          style={{ marginRight: 32 }}
          id={generateID.UUID(Module.PAGE.ADMIN.applicationModal, `appAndCategories_cancel_button`, "dialog")}
        >
          {BUTTON_MODEL_LABEL.cancel}
        </OwcButton>
        <OwcButton
          data-testid="admin-panel-modal-application-action-button-confirm"
          autoFocus
          onClick={() => {
            save(applicationForm, file);
          }}
          variant="primary"
          disabled={!isValid}
          id={generateID.UUID(Module.PAGE.ADMIN.applicationModal, `appAndCategories_confirm_button`, "dialog")}
        >
          {applicationForm.id ? BUTTON_MODEL_LABEL.save : BUTTON_MODEL_LABEL.add}
        </OwcButton>
      </div>
    </OwcModalDialog>
  );
};

export default ApplicationModal;
