import { OwcCheckbox, OwcListItem, OwcSelectDropdown } from "@one/react";
import { connect } from "react-redux";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";

const ApplicationCategoriesSelect = ({
  application,
  updateApplication,
  setDirtyInputs,
  applicationGroups = [],
  dirtyInputs,
  categories
}) => {
  const categoryListSorted = applicationGroups?.sort(function (a, b) {
    return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());
  });
  return (
    <>
      <OwcSelectDropdown
        multiple
        variant="filled"
        label="Application categories"
        onSelectChange={(e) => {
          const tempArray = e.detail.map((v) => applicationGroups[v]?.id);
          updateApplication({
            value: tempArray,
            key: "applicationGroupIDs"
          });
        }}
        id={generateID.UUID(Module.PAGE.ADMIN.applicationModalCategorySelect, `appAndCategories`, "select_dropdown")}
      >
        {Object.values(categoryListSorted)?.map((category, index) => {
          let selectedValues = application?.applicationGroupIDs;
          return (
            <>
              <OwcListItem
                id={generateID.UUID(
                  Module.PAGE.ADMIN.applicationModalCategorySelect,
                  `appAndCategories_${index}`,
                  "item_list"
                )}
                selected={selectedValues.includes(category?.id)}
              >
                <OwcCheckbox
                  id={generateID.UUID(
                    Module.PAGE.ADMIN.applicationModalCategorySelect,
                    `appAndCategories_${index}`,
                    "checkbox"
                  )}
                >
                  {category?.name}
                </OwcCheckbox>
              </OwcListItem>
            </>
          );
        })}
      </OwcSelectDropdown>
    </>
  );
};
const selectGroups = (groups) =>
  groups?.map((group) => ({
    id: group.id,
    name: group.name,
    icon: group.icon,
    iconPath: group.iconPath
  }));
const mapStateToProps = (store) => ({
  applicationGroups: selectGroups(store.user.allGroups)
});
export default connect(mapStateToProps, {})(ApplicationCategoriesSelect);
