import { OwcIconButton } from "@one/react";
import { connect } from "react-redux";
import { selectNotificationUserInfo as selectNotificationUserInfoActions } from "../landingPageUser/redux/actions";

const NotificationBackButton = ({ selectNotificationUserInfo, goTo = "" }) => {
  return (
    <OwcIconButton
      flat
      icon="chevron_left"
      type="filled"
      data-testid="notification-back-button"
      onClick={() => selectNotificationUserInfo(goTo)}
    />
  );
};

export default connect(null, {
  selectNotificationUserInfo: selectNotificationUserInfoActions
})(NotificationBackButton);
