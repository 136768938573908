import React from "react";
import styled, { css } from "styled-components";
import { SEVERITY_NOTIFICATIONS } from "../../SeverityNotifications";
import { Tooltip } from "@mui/material";

const severityHigh = css`
  background-color: var(--one-color-background-negative-1);
`;
const severityMedium = css`
  background-color: var(--one-color-background-warning-1);
`;
const severityLow = css`
  background-color: var(--one-color-gray-600);
`;
const SeverityCircleStyled = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 16px;
  margin-top: 5px;
  ${(props) => props.severity === SEVERITY_NOTIFICATIONS.HIGH && severityHigh}
  ${(props) => props.severity === SEVERITY_NOTIFICATIONS.MEDIUM && severityMedium}
    ${(props) => props.severity === SEVERITY_NOTIFICATIONS.LOW && severityLow};
`;

const SeverityCircleNotify = ({ severity }) => {
  return (
    <Tooltip
      data-testid="short-severity-status-tooltip"
      placement="left"
      title={"Severity: " + severity?.toUpperCase()}
      arrow
      style={{ color: " var(--one-color-cobas-white)" }}
    >
      <SeverityCircleStyled data-testid="short-severity-status" severity={severity}></SeverityCircleStyled>
    </Tooltip>
  );
};

export default SeverityCircleNotify;
