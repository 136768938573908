export const SEVERITY_NOTIFICATIONS = {
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low"
};

export const SEVERITY_NOTIFICATIONS_BACKEND = {
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low"
};

export const SEVERITY_TO_NUMBER = {
  [SEVERITY_NOTIFICATIONS_BACKEND.HIGH]: 300,
  [SEVERITY_NOTIFICATIONS_BACKEND.MEDIUM]: 200,
  [SEVERITY_NOTIFICATIONS_BACKEND.LOW]: 100
};
