import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { selectGroupUserInfo as selectGroupUserInfoAction } from "../../features/landingPageUser/redux/actions";
import ApplicationGroupsView from "../../views/ApplicationGroupsView";
import SearchToolWrapper from "../searchTools/SearchWrapper";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../constants";

const ApplicationMainPage = ({ selectGroupUserInfo, name }) => {
  const selectedApplication =
    name === "quick-access"
      ? {
          id: "",
          name: "Quick-access",
          type: "main"
        }
      : {
          id: "",
          name: "Applications",
          type: "allApplications"
        };
  selectGroupUserInfo({ ...selectedApplication });
  return (
    <SearchToolWrapper>
      <ApplicationGroupsView id={generateID.UUID(Module.PAGE.APPLICATIONS.name, `application`, "div")} />
    </SearchToolWrapper>
  );
};

export default compose(
  connect(null, {
    selectGroupUserInfo: selectGroupUserInfoAction
  }),
  withApollo
)(ApplicationMainPage);
