import { OwcButton, OwcInput } from "@one/react";
import { useState } from "react";
import { withApollo } from "react-apollo";
import { UPDATE_USER_EMAIL } from "../../../gql/landingapi/mutations";
import styled from "styled-components";
import Snackbar from "../../snackbar/Snackbar";
import { FullScreenCentered } from "../../landingPageUser/LandingPageUser";
import { CircularProgress } from "@mui/material";

const FullWidthHeader = styled.div`
  width: 20%;
  border: solid;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FullWidthRow = styled.div`
  width: 20%;
  border: solid;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const UpdateUserEmail = ({ client }) => {
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const headerValues = ["Table name", "Email", "User id", "Status", "Message"];
  const onUpdateEmail = async () => {
    try {
      setLoading(true);
      const updatedProfile = await client.mutate({
        mutation: UPDATE_USER_EMAIL,
        variables: {
          email,
          userId
        }
      });
      setRows(() => JSON.parse(updatedProfile?.data?.updateUserEmail));
    } catch (error) {
      Snackbar({
        appName: "",
        icon: "no",
        type: "warning",
        text: error?.graphQLErrors[0]?.message
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div style={{ paddingBottom: "10px" }}>
        <OwcInput
          color="primary"
          variant="filled"
          label="UserId *"
          placeholder="UserId"
          onInputKeyDown={(e) => {
            setUserId(() => e.detail);
          }}
          required
          value={userId}
        />
      </div>
      <div style={{ paddingBottom: "10px" }}>
        <OwcInput
          color="primary"
          variant="filled"
          label="Email *"
          placeholder="Email"
          onInputKeyDown={(e) => {
            setEmail(() => e.detail);
          }}
          required
          value={email}
        />
      </div>
      <div>
        <OwcButton
          variant="primary"
          onClick={onUpdateEmail}
          disabled={
            !(
              email &&
              userId &&
              String(email)
                .toLowerCase()
                .match(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
            )
          }
        >
          Submit
        </OwcButton>
      </div>
      {loading && (
        <FullScreenCentered>
          <CircularProgress data-testid="app-loading" size={80} />
        </FullScreenCentered>
      )}
      {!loading && rows?.length > 0 && (
        <div style={{ paddingTop: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "start"
            }}
          >
            {headerValues?.map((header) => {
              return (
                <FullWidthHeader
                  style={{
                    width:
                      header === "Table name" ? "25%" : header === "Email" ? "25%" : header === "Status" ? "10%" : "20%"
                  }}
                >
                  <strong>{header}</strong>
                </FullWidthHeader>
              );
            })}
          </div>
          {rows?.map((item) => {
            return (
              <>
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  <FullWidthRow style={{ width: "25%" }}>{item?.tableName}</FullWidthRow>
                  <FullWidthRow style={{ width: "25%" }}>{item?.email}</FullWidthRow>
                  <FullWidthRow>{item?.userId}</FullWidthRow>
                  <FullWidthRow style={{ width: "10%" }}>{item?.status}</FullWidthRow>
                  <FullWidthRow>{item?.message}</FullWidthRow>
                </div>
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default withApollo(UpdateUserEmail);
