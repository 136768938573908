import { updateItem } from "../../../utils/store/crud";
import * as types from "./actionTypes";
import initialState from "./initialState";

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.LOAD_USER_INFO:
      return { ...state, ...payload };
    case types.LOAD_SOURCES:
      return { ...state, sources: [...payload] };
    case types.SELECT_GROUP_USER_INFO:
      return { ...state, selected: { ...payload }, selectedNotificationId: "" };
    case types.SELECT_CATEGORY_USER_INFO:
      return {
        ...state,
        selectedCategory: { ...payload },
        selectedNotificationId: ""
      };
    case types.SELECT_NOTIFICATION_USER_INFO:
      return { ...state, selectedNotificationId: payload };
    case types.UPDATE_GROUP_APP:
      return {
        ...state,
        allGroups: updateItem(state.allGroups, payload)
      };
    case types.ADD_WIDGET_TO_DASHBOARD:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          widgets: [...state.dashboard.widgets, payload]
        }
      };
    case types.UPDATE_DASHBOARD:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          ...payload,
          widgets: [...payload.widgets]
        }
      };
    case types.UPDATE_DASHBOARD_WIDGETS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          widgets: [...payload]
        }
      };
    case type.UPDATE_ACCORDION_STATE:
      return {
        ...state,
        selected: {
          ...state.selected,
          accordionState: [...payload]
        }
      };
    case types.UPDATE_FAVOURITE_EQUIPMENT_STATE:
      return {
        ...state,
        favouriteEquipments: [...payload]
      };
    case types.UPDATE_FAVOURITE_EQUIPMENT_VIEW_TYPE_STATE:
      return {
        ...state,
        viewType: payload
      };
    case types.UPDATE_USER_FILTERS:
      return {
        ...state,
        userFilters: payload
      };
    case types.UPDATE_LAST_FAV_FILTER:
      return {
        ...state,
        lastFavFilter: payload
      };
    case types.UPDATE_NOTIFICATION_BANNER:
      return {
        ...state,
        NotificationBanner: payload
      };
    case types.UPDATE_TOP_BANNER_FLAG:
      return {
        ...state,
        topBannerFlag: payload
      };
    default:
      return state;
  }
};

export default reducer;
